import { forwardRef } from "react"
import { IMaskInput } from "react-imask"

export interface MaskProps {
  onChange: (event: { target: { name: string; value: string } }) => void
  name: string
}

export const MaskCNPJ = forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="00.000.000/0000-00"
        // definitions={{
        //   '#': /[1-9]/,
        // }}
        // inputRef={ref}
        onAccept={(value: any) => onChange({ target: {name: props.name, value} })}
        overwrite
      />
    )
  },
)

export const MaskCPF = forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="000.000.000-00"
        // definitions={{
        //   '#': /[1-9]/,
        // }}
        // inputRef={ref}
        onAccept={(value: any) => onChange({ target: {name: props.name, value} })}
        overwrite
      />
    )
  },
)

export const MaskPhone = forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="(00) # 0000-0000"
        definitions={{
          '#': /[1-9]/,
        }}
        // inputRef={ref}
        onAccept={(value: any) => onChange({ target: {name: props.name, value} })}
        overwrite
      />
    )
  },
)

export const MaskCEP = forwardRef<HTMLElement, MaskProps>(
  function TextMaskCustom(props, ref) {
    const { onChange, ...other } = props
    return (
      <IMaskInput
        {...other}
        mask="00000-000"
        // definitions={{
        //   '#': /[1-9]/,
        // }}
        // inputRef={ref}
        onAccept={(value: any) => onChange({ target: {name: props.name, value} })}
        overwrite
      />
    )
  },
)