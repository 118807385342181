/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable react-hooks/exhaustive-deps */
import { v4 as uuid } from 'uuid';
import { ContainerStyled } from './styles'
import * as api from "../../services/api";
import { useCore } from "../../hooks/useCore";
import { LooseObject } from "../../types/core";
import useWindowSize from "../../hooks/useWindowSize";
import CodeEditor from "@uiw/react-textarea-code-editor";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import { ListParameter, ParameterProps } from "../../types/parameter";
import { useEffect, useLayoutEffect, useReducer, useState } from "react";
import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Autocomplete,
    Button,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import ModalRequisition from "./Testing";
import { toast } from "react-toastify";
import ModalReturn from "./ModalReturn";
import InformationCard from "../../components/InformationCard";
import { dysplayValueType } from '../Api/CreateOrUpdateApi/value_type';

export type IProducts = {
    id: string;
    name: string;
    category: string;
    description: string;
    apis: [string];
    price: string;
    price__display: "3,00";
    allow_processing: boolean;
    created_at: string;
    updated_at: string;
    is_active: true;
};

type initialStateProps = {
    originalParameters: ParameterProps[]
    parameters: ParameterProps[]
    initialValues: LooseObject
}

type tplotOptions = {
    [key: string]: any
}

const jsonStyle = {
    propertyStyle: { color: 'red' },
    stringStyle: { color: 'green' },
    numberStyle: { color: 'darkorange' }
}

type structureT = {
    response: object
    id: string
    name: string
}

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SET_ORIGINAL_PARAMETERS':
            return { ...state, originalParameters: action.payload };
        case 'SET_PARAMETERS':
            return { ...state, parameters: action.payload };
        case 'SET_INITIAL_VALUES':
            return { ...state, initialValues: action.payload };
        default:
            throw new Error('Invalid action type');
    }
}

const requestInformation = [
    {
        step: 1,
        text: {
            "endpoint": "https://service.checkview.com.br/api/token/",
            method: 'POST',
            headers: 'No headers desta requisição, somente terá o content-type informando o tipo do body',
            body: "username e password do usuário a ser requisitado o token"
        }
    }, {
        step: 2,
        text: {
            "endpoint": "https://service.checkview.com.br/call/query/",
            method: 'POST',
            headers: 'No headers desta requisição, terá o content-type informando o tipo do body e o Authorization com o token adquirido no passo anterior',
            body: "informações sobre os produtos a serem requisitados, selecione os produtos desejados para ver  um exemplo de request no quadro abaixo"
        }
    }
]

type DataValueResponseProps = {
    description: string
    type: string
}

type ValueResponseInformationProps = {
    title: string
    data: {[key: string]: DataValueResponseProps}
}
type ResponseInformationProps = {[key: number]: ValueResponseInformationProps[]}

const responseInformation: ResponseInformationProps = {
    1: [
        {
            title: "Um objeto JSON contendo o token",
            data: {
                token: {
                    description: "Token para ter acesso e realizar consultas",
                    type: "string"
                }
            }
        }
    ],
    2: [
        {
            title: "Um objeto JSON contendo todos os dados do usuario que realizou a chamada",
            data: {
                id: {
                    description: "ID do usuário",
                    type: "string"
                },
                email: {
                    description: "Email do usuário",
                    type: "string"
                },
                first_name: {
                    description: "Primeiro nome",
                    type: "string"
                },
                last_name: {
                    description: "Último nome",
                    type: "string"
                },
                profile: {
                    description: "Perfil",
                    type: "string"
                },
                company_id: {
                    description: "ID da empresa",
                    type: "string"
                },
                company_social_reason: {
                    description: "Razão social da empresa",
                    type: "string"
                },
                company_cnpj: {
                    description: "CNPJ da empresa",
                    type: "string"
                }
            }
        },
        {
            title: "Um objeto JSON com todos os dados de request e response da chamada",
            data: {
                id: {
                    description: "ID da consulta",
                    type: "string"
                },
                ip: {
                    description: "IP do usuário no momento da chamada",
                    type: "string"
                },
                hash: {
                    description: "Hash de autenticidade da consulta",
                    type: "string"
                },
                products: {
                    description: "Uma lista contendo o nome de todos os produtos consultados",
                    type: "string"
                },
                status: {
                    description: "Código do status da chamada",
                    type: "string"
                },
                get_status_display: {
                    description: "Status da chamada",
                    type: "string"
                },
                data: {
                    description: "Todos os dados da requisição",
                    type: "object"
                },
                files: {
                    description: "Todos os arquivos enviados na requisição caso haja",
                    type: "object"
                },
                responses: {
                    description: "Um lista contendo todos os retornos dos produtos consultados",
                    type: "object"
                },
                created_at: {
                    description: "Data e hora da realização da chamada",
                    type: "string"
                },
                updated_at: {
                    description: "Data e hora da última atualização da chamada",
                    type: "string"
                }
            }
        }
    ]
}

const jsonReturnStepOne = `{
    "token": "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMDkyOTIyLCJpYXQiOjE2OTEwMDY1MjIsImp0aSI6ImU3ZTdjYmY2ZGI2NzRiOWZiMTg2ODdmNWEwN2U4Y2MxIiwidXNlcl9pZCI6ImRiNzM4MTRmLTE2ZjYtNGIzYy1hM2RkLTQxYTI2YjE3MGJkNyJ9.6PVsCVRfXZ5OQSrlaS9py2qxyy2dqx8GOPh0QEVdo04"
}`

export const steps = [
    {
        name: "Passo 1 - Token",
        text: "Esse passo é necessário sempre que for realizar uma consulta pois trabalhamos com token dinâmico por questões de segurança. Você receberá um token para usar por até 24h.",
        value: 1
    },
    {
        name: "Passo 2 - Realizar consulta",
        text: "Esse é o passo para realizar a consulta dos produtos desejado na plataforma",
        value: 2
    }
];

/**
 * Documentation function provides a user interface for documenting and testing API requests.
 * It allows users to select different steps of the API request process, view the request details, and test the request with different parameters and products.
 * The function also displays the code for each step of the request and provides a modal to view the response of the request.
 *
 * @returns {JSX.Element} The user interface for documenting and testing API requests.
 *
 * @example
 * ```
 * <Documentation />
 * ```
 */
export default function Documentation() {
    const [ids, setIds] = useState<string[]>([])
    const [modalOpened, setModalOpened] = useState(false);
    const [paramns, setParamns] = useState<ParameterProps[]>([]);
    const [products, setProducts] = useState<[IProducts] | []>([]);
    const [modalReturnOpened, setModalReturnOpened] = useState(false);
    const [productSelected, setProductSelected] = useState<IProducts[]>([]);
    const [structureFormated, setStructureFormated] = useState<structureT[]>([]);
    const [returnRequisitionStep2, setReturnRequisitionStep2] = useState<{ step: 2, data: {} } | null>(null);

    const { width } = useWindowSize()

    const [requestExpanded, setRequestExpanded] = useState(true)
    const [responseExpanded, setResponseExpanded] = useState(true)

    const { setTitleBar, setPathTitleBar } = useCore();

    const getProducts = () => {
        api.get("product/list/all/").then((response: any) => {
            setProducts(response?.content);
        });
    };

    const getParamns = async (data: Array<IProducts>) => {
        let dataFormated = {
            products_id: data.map(item => item.id)
        }
        let parametersDescription: string[] = []
        const response = await api.patch('product/list/parameters/', dataFormated) as { [key: string]: any }
        const parametersResponse = response.data as ListParameter
        dispatch({ type: 'SET_ORIGINAL_PARAMETERS', payload: parametersResponse.content })

        let _initialValues: LooseObject = {
            "rescue": false
        }

        setParamns(parametersResponse.content)

        let _parameters: ParameterProps[] = []

        parametersResponse.content.forEach((item) => {
            _initialValues[`${item.name}|${item.api_id}`] = ''
            if (parametersDescription.indexOf(item.description) === -1) {
                parametersDescription.push(item.description)
                _parameters.push(item)
            }
        })

        dispatch({ type: 'SET_PARAMETERS', payload: _parameters })
        dispatch({ type: 'SET_INITIAL_VALUES', payload: _initialValues })
    }

    function getStructure(receivedProducts: IProducts[]) {

        if (receivedProducts.length < productSelected.length) {
            const data = structureFormated.filter((item: { id: string; }) => productSelected?.filter(a => !receivedProducts?.includes(a))[0].id !== item.id)
            setStructureFormated(data)
            return
        }

        let receivedProductsFiltered: IProducts = receivedProducts.filter(item2 => !productSelected.includes(item2))[0]

        receivedProducts?.forEach(item => {
            if (structureFormated?.length > 0) {
                structureFormated?.forEach((item2) => {
                    if (item2 && item2.id !== item.id && receivedProductsFiltered.id === item.id) {
                        api
                            .get(`product/retrieve/structure/${item.id}/`)
                            .then((response: any) => {
                                setStructureFormated([...structureFormated, { response: { ...response?.content[0].structure }, id: item.id, name: item.name }])
                            })
                    }
                })
            } else {
                api
                    .get(`product/retrieve/structure/${item.id}/`)
                    .then((response: any) => {
                        setStructureFormated([...structureFormated, { response: { ...response?.content[0].structure }, id: item.id, name: item.name }])
                    })
            }
        })
    }

    const codes = (step: (typeof steps)[0], products?: IProducts[]) => {
        if (step.value === 1) {
            return `const myHeaders = new Headers({\n   "Content-Type": "application/json"\n})\n\nfetch('https://service.checkview.com.br/api/token/', {\n   method: 'POST',\n   headers: myHeaders,\n   body:{\n       username:"email@teste.com.br",\n       password:"SenhaForte123@"\n   }\n}) `;
        } else {
            return `const myHeaders = new Headers({\n   "Content-Type": "application/json"\n   "Authorization":"Bearer eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNjkxMDkyOTIyLCJpYXQiOjE2OTEwMDY1MjIsImp0aSI6ImU3ZTdjYmY2ZGI2NzRiOWZiMTg2ODdmNWEwN2U4Y2MxIiwidXNlcl9pZCI6ImRiNzM4MTRmLTE2ZjYtNGIzYy1hM2RkLTQxYTI2YjE3MGJkNyJ9.6PVsCVRfXZ5OQSrlaS9py2qxyy2dqx8GOPh0QEVdo04"\n})\n\nfetch('https://service.checkview.com.br/call/query/', {\n   method: 'POST',\n   headers: myHeaders,\n   body: {\n       data: {${paramns
                ? paramns?.map(
                    (item) =>
                        `\n              '${item.name}|${item.api_id}': {\n                    'value': 'Valor a ser informado pelo usuário' // ${item.description} - Tipo de valor: ${dysplayValueType[item.value_type]}\n             }`
                )
                : ""
                }\n       }\n       products:[${products ? products?.map((item) => `'${item.id}'`) : ""
                }]\n   }\n}) `;
        }
    };

    const [step, setStep] = useState<(typeof steps)[0]>(steps[0]);
    const [code, setCode] = useState(codes(step));

    const initialState: initialStateProps = {
        originalParameters: [],
        parameters: [],
        initialValues: {
            rescue: false
        }
    }

    const [state, dispatch] = useReducer(reducer, initialState)

    useEffect(() => {
        setCode(codes(step, productSelected));
    }, [paramns]);

    useLayoutEffect(() => {
        getProducts();
        setPathTitleBar(undefined);
        setTitleBar("Documentação");
    }, []);

    return (
        <ContainerStyled>
            <div
                style={{
                    width: "100%",
                    display: "flex",
                    justifyContent: "space-evenly",
                    gap: width > 950 ? 'none' : '15px',
                    flexDirection: width > 950 ? 'row' : 'column'
                }}
            >
                <FormControl style={{ width: width > 950 ? step.value !== 1 ? "100%" : "100%" : '100%' }}>
                    <InputLabel id="month-label">Selecione o passo</InputLabel>
                    <Select
                        fullWidth
                        label="Selecione o passo"
                        key={uuid()}
                        value={step?.value || ""}
                        onChange={(e) => {
                            setStep(steps.filter((item) => item.value === e.target.value)[0]);
                            setCode(
                                codes(steps.filter((item) => item.value === e.target.value)[0])
                            );
                            setRequestExpanded(true)
                            setResponseExpanded(true)
                        }}
                    >
                        {steps?.map((item) => (
                            <MenuItem
                                key={item?.value}
                                value={item.value}
                            >
                                {item?.name}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
            </div>
            <InformationCard text={step.text} title={step.name} />
            <Accordion expanded={requestExpanded} onClick={() => setRequestExpanded(!requestExpanded)}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                >
                    <Typography>Informações da requisição</Typography>
                </AccordionSummary>
                <AccordionDetails style={{ borderTop: '1px black solid' }}>
                    {requestInformation.map((item: tplotOptions) => item.step === step.value && Object.keys(item.text).map(item2 => {
                        return (
                            <p><strong>{item2}:</strong> {item.text[item2]}</p>
                        )
                    }))}
                </AccordionDetails>
            </Accordion>
            {
                step.value === 2
                    ? <FormControl
                        style={{
                            width: width > 950 ? '100%' : '100%'
                        }}
                    >
                        <Autocomplete
                            multiple
                            fullWidth
                            limitTags={2}
                            id="tags-outlined"
                            options={products}
                            filterSelectedOptions
                            getOptionLabel={(option) => option.name}
                            onChange={(_, value, __) => {
                                getParamns(value);
                                getStructure(value);
                                setProductSelected(value);
                            }}
                            renderInput={(params) => <TextField {...params} label="Selecione os produtos desejados" placeholder="" />}
                        />
                    </FormControl>
                    : <></>
            }
            {
                step.value === 1 || productSelected.length > 0 ?
                    <>
                        <Typography>Exemplo de request usando JavaScript</Typography>
                        <CodeEditor
                            padding={20}
                            value={code}
                            language="js"
                            data-color-mode="light"
                            style={{ borderRadius: "5px" }}
                            onClick={
                                () => navigator.clipboard.writeText(code)
                                    .then(() => {
                                        toast.success('Copiado para a area de transferência')
                                    })
                            }
                        />
                        {
                            returnRequisitionStep2 && returnRequisitionStep2.step === step.value
                                ? <Button
                                    variant="outlined"
                                    onClick={() => setModalReturnOpened(!modalReturnOpened)}
                                >
                                    Ver retorno da última requisição
                                </Button>
                                : <></>
                        }
                        <Button
                            size="large"
                            style={{ display: step.value !== 1 ? 'initial' : 'none' }}
                            variant="contained"
                            onClick={() => {
                                if (state.parameters.length === 0 && step.value === 2) {
                                    toast.error('Selecione ao menos um produto para realizar o teste')
                                    return
                                }
                                setModalOpened(!modalOpened)
                            }}
                        >
                            Testar
                        </Button>
                        <Accordion expanded={responseExpanded} onClick={() => setResponseExpanded(!responseExpanded)}>
                            <AccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                aria-controls="panel1a-content"
                                id="panel1a-header"
                            >
                                <Typography>Informações da resposta</Typography>
                            </AccordionSummary>
                            <AccordionDetails style={{ borderTop: '1px black solid' }}>
                                {
                                    responseInformation[step.value].map((item, index) => {
                                        return (
                                            <>
                                                {item.title}
                                                {Object.keys(item.data).map((key) => (
                                                    <p><strong>{key}:</strong> {item.data[key].description} - <strong>Tipo:</strong> {item.data[key].type}</p>
                                                ))}
                                            </>
                                        )
                                    })
                                }
                            </AccordionDetails>
                        </Accordion>
                    </>
                : <></>
            }
            {
                step.value === 1 ?
                    <>
                        <Typography>Exemplo de response</Typography>
                        <CodeEditor
                            padding={20}
                            language="json"
                            value={jsonReturnStepOne}
                            data-color-mode="light"
                            style={{ borderRadius: "5px" }}
                        />
                    </>
                : <></>
            }
            <ModalRequisition
                ids={ids}
                step={step}
                state={state}
                setIds={setIds}
                modalOpened={modalOpened}
                setModalOpened={setModalOpened}
                currentProducts={productSelected}
                setModalReturnOpened={setModalReturnOpened}
                setReturnRequisitionStep2={setReturnRequisitionStep2}
            />
            <ModalReturn
                jsonStyle={jsonStyle}
                modalReturnOpened={modalReturnOpened}
                setModalReturnOpened={setModalReturnOpened}
                json={returnRequisitionStep2}
            />
        </ContainerStyled>
    );
}
