/* eslint-disable react-hooks/exhaustive-deps */
import * as api from "../../../services/api";
import { useParams } from "react-router-dom";
import { useReactToPrint } from "react-to-print";
import PrintIcon from "@mui/icons-material/Print";
import { LooseObject } from "../../../types/core";
import useWindowSize from "../../../hooks/useWindowSize";
import { LoadingPage } from "../../../components/LoadingPage";
import { QueryProps, RetrieveQueryProps } from "../../../types/query";
import {
  PureComponent,
  useCallback,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from "react";
import {
  Button,
  Chip,
  Divider,
  Grid,
  List,
  ListItem,
  ListItemText,
  Tooltip,
  Typography,
} from "@mui/material";
import { useCore } from "../../../hooks/useCore";
import ProgressMemorized from "../../../components/ProgressBar";
import { checkMatchGet } from "../../../services/checkMatch";

const logo = require("../../../images/logo.png");

export default function QueryDetail(): JSX.Element {
  let { id } = useParams();
  const { width } = useWindowSize();
  const { setTitleBar, setDisplayAlt } = useCore();
  const componentRef = useRef();

  const [filename, setFilename] = useState<string>("Check View");
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
    documentTitle: filename,
  });

  const [query, setQuery] = useState<QueryProps | undefined>(undefined);
  const [checkMatchResponse, setCheckMatchResponse] = useState<any>({});

  const getQuery = useCallback(async () => {
    getMatch()
    const response = (await api.get(
      `call/retrieve/${id}/`
    )) as RetrieveQueryProps;
    setQuery(response.content);
    setFilename(`Check View - ${response.content.products}`);
  }, [id]);

  useEffect(() => {
    getQuery().catch(console.error);
  }, [getQuery]);

  async function getMatch() {
    const response = await checkMatchGet(`match/retrieve/checkview/${id}/`)

    setCheckMatchResponse(response)
  }

  useLayoutEffect(() => {
    setTitleBar("Relatório da consulta realizada");
    setDisplayAlt("block");
  }, [setDisplayAlt, setTitleBar]);

  return (
    <>
      <Grid container marginBottom={1} justifyContent="center">
        <Button
          color="info"
          variant="outlined"
          disableElevation
          onClick={handlePrint}
          startIcon={<PrintIcon />}
        >
          Imprimir
        </Button>

      </Grid>
      {query ? (
        <>
          <div style={{ width: '100%', textAlign: 'center', marginTop: '10px' }}>
            <Chip
              id="chip-status"
              label={query?.get_status_display}
              variant="outlined"
              color={
                query?.status === "1"
                  ? "default"
                  : query?.status === "2"
                    ? "warning"
                    : query?.status === "3"
                      ? "success"
                      : "error"
              }
            />
          </div>
          {/* @ts-ignore */}
          <Details query={query} width={width} ref={componentRef} checkMatch={checkMatchResponse} />
        </>
      ) : (
        <LoadingPage />
      )}
    </>
  );
}

interface DetailsProps {
  query: QueryProps;
  width: number;
  checkMatch?: any;
}

interface DataQueryProps {
  description: string;
  value: string;
  value_type: string;
}

export class Details extends PureComponent<DetailsProps> {
  render() {
    const { width, query, checkMatch } = this.props;
    const getDataQuery = (query: QueryProps) => {
      const dataQuery: DataQueryProps[] = [];

      for (const [field, dataValue] of Object.entries(query.data)) {
        if (
          dataQuery.filter(
            (item) =>
              item.description === dataValue.description?.toString() ||
              item.description === field.toString()
          ).length === 0
        ) {
          dataQuery.push({
            description:
              dataValue.description !== undefined
                ? dataValue.description?.toString()
                : field.toString()[0].toUpperCase() + field.substring(1),
            value:
              dataValue.value_type === "4"
                ? query.files[dataValue.value.file_data]
                : dataValue.value.toString().length > 0
                  ? dataValue.value.toString()
                  : "Não informado",
            value_type: dataValue.value_type,
          });
        }
      }

      return dataQuery;
    };

    interface Props {
      data: LooseObject;
    }

    const ProcessoInfo: React.FC<Props> = ({ data }) => {
      function generate(data: LooseObject, isList: boolean = false) {
        let response = "";

        for (let key in data) {
          if (
            typeof data[key] === "object" &&
            data[key] !== null &&
            data[key] !== undefined
          ) {
            let _key = key.replace(/_/g, " ");

            if (isList) {
              let _keyNumber = parseFloat(_key);
              if (!isNaN(_keyNumber)) {
                response += `<li class="li-report subtitle">
                              <strong>Item ${_keyNumber + 1}</strong>
                            </li>`;
              }
            } else {
              try {
                let _keyNumber = parseFloat(_key);

                if (isNaN(_keyNumber)) {
                  response += `<li class="li-report title">
                            <strong>${_key}</strong>
                          </li>`;
                }
              } catch (e) {
                return
              }
            }

            if (data[key].constructor().toString().length === 0) {
              response += generate(data[key], true);
            } else {
              response += generate(data[key]);
            }
          } else {
            let value = data[key];
            if (
              value === null ||
              value === "null" ||
              value === undefined ||
              value === "undefined"
            ) {
              value = "Nada consta";
            }

            try {
              let _keyNumber = parseFloat(key);

              if (!isNaN(_keyNumber)) {
                key = `Item ${_keyNumber + 1}`;
              }
            } catch (e) {
              return
            }

            response += `<li class="li-report">
                          <strong>${key}:</strong> ${value}
                        </li>`;
          }
        }

        return response;
      }

      const listItem = generate(data);

      return (
        <ul id="ul-report" dangerouslySetInnerHTML={{ __html: listItem! }} />
      );
    };

    return (
      <Grid
        id="report"
        container
        alignItems="left"
        justifyContent="left"
        sx={{ width: "100%" }}
      >
        <img
          id="print-logo"
          src={logo}
          alt="Logo"
          width={150}
          height="auto"
          style={{ paddingTop: 15, paddingBottom: 15 }}
        />
        <List disablePadding sx={{ width: "100%" }}>
          <ListItem
            key={query.id}
            disablePadding
          >
            <ListItemText
              primary={
                <Typography
                  sx={{ mt: 2, color: "#424242" }}
                  variant="h6"
                  // color="primary"
                  align="left"
                  component="span"
                >
                  <strong>
                    {query.products.length > 1 ? (
                      <Tooltip
                        title={`${query.products.map((item) => ` ${item}`)}`}
                        arrow
                        placement="right"
                      >
                        <strong>Consulta multipla</strong>
                      </Tooltip>
                    ) : (
                      query.products.map((item) => item)
                    )}
                  </strong>
                </Typography>
              }
              secondary={
                <>
                  <strong>Realizado em:</strong> {query.created_at} <br />
                  <strong>Realizado por:</strong> {query.created_by.email}{" "}
                  <br />
                  <strong>Pelo endereço de IP:</strong>{" "}
                  {query.ip ?? "Não capturado"} <br />
                  <strong>Hash de autênticidade:</strong>{" "}
                  <span style={{ wordBreak: "break-all" }}>{query.hash}</span>
                  <br />
                  <Typography
                    sx={{ mt: 2, color: "#424242" }}
                    variant="h6"
                    // color="primary"
                    align="left"
                    component="span"
                  >
                    <strong>Dados enviados:</strong>
                  </Typography>
                  {
                    getDataQuery(query).map((item, index) => (
                      <>
                        <Typography
                          key={`data-query-${index}`}
                          variant="body2"
                          component="span"
                        >
                          <br />
                          <strong>{item.description}</strong>:&nbsp;
                          {item.value_type === "4" ? (
                            <>
                              <br />
                              <a
                                href={item.value}
                                target="_blank"
                                rel="noreferrer"
                              >
                                <img
                                  src={item.value}
                                  alt={item.description}
                                  width={160}
                                  height="auto"
                                />
                              </a>
                            </>
                          ) : ["5", "6"].indexOf(item.value_type) > -1 ? (
                            <a href={item.value} target="_blank" rel="noreferrer">
                              Clique para abrir o arquivo em nova aba
                            </a>
                          ) : (
                            item.value
                          )}
                        </Typography>
                      </>
                    ))
                  }
                  <br />
                  {
                    query.checkmatch === true && checkMatch
                      ? <>
                        <Typography
                          variant="h6"
                          align="left"
                          component="span"
                          sx={{ mt: 2, color: "#424242" }}
                        >
                          <strong>CheckMatch:</strong>
                        </Typography>
                        <div style={
                          {
                            width: '100%',
                            display: 'grid',
                            gridTemplateColumns: `repeat(auto-fit, minmax(${width < 400 ? '240px' : '350px'}, 1fr))`
                          }
                        }>
                          {
                            checkMatch?.data?.content?.response.values.map((item: any) => (
                              <div
                                key={item.name}
                                style={
                                  {
                                    height: '100px',
                                    display: 'flex',
                                    borderRadius: '1rem',
                                    marginBottom: '10px',
                                    alignItems: 'center',
                                    flexDirection: 'column',
                                    border: '1px #D2D3D5 solid',
                                    justifyContent: 'space-evenly',
                                    borderLeft: `10px ${item.match === true ? 'green' : 'red'} solid`,
                                    width: checkMatch?.data?.content?.response.values.length === 1 && width > 900 ? '55%' : '95%'
                                  }
                                }
                              >
                                <div
                                  style={
                                    {
                                      width: '90%',
                                      fontWeight: 'bold',
                                      fontSize: '1.1rem',
                                      overflow: 'hidden',
                                      textAlign: 'center',
                                      whiteSpace: 'nowrap',
                                      textOverflow: 'ellipsis',
                                    }
                                  }
                                  title={item.value}
                                >
                                  {
                                    item.value
                                  }
                                </div>
                                <div

                                >
                                  <strong style={{fontSize: '0.9rem'}}>Porcentagem mínima:</strong>&nbsp;
                                  {
                                    checkMatch?.data?.content?.data?.values.filter((item2: any) => item2.value === item.value)[0].accuracy
                                  }%
                                </div>
                                <ProgressMemorized match={item.match} done={item.accuracy.toString()} />
                              </div>
                            ))
                          }
                        </div>
                      </>
                      : <></>
                  }
                </>
              }
            />

          </ListItem>
        </List>

        {query.responses.map(
          (content: { [key: string]: any }, index: number) => (
            <Grid
              key={`call-response-${index}`}
              gap={1}
              justifyContent="center"
              textAlign="center"
              width="100%"
            >

              <Divider sx={{ marginY: 2 }}>
                <Chip label={content.name.toUpperCase()} color="info" />
              </Divider>

              <small style={{ color: "#424242" }}>
                <strong>Hash de autênticidade:</strong>
                <br />
                <span style={{ wordBreak: "break-all" }}>{content.hash}</span>
              </small>

              <ProcessoInfo data={content.data_json} />
            </Grid>
          )
        )}
      </Grid>
    );
  }
}
