/* eslint-disable react-hooks/exhaustive-deps */
import List from "@mui/material/List"
import Input from "@mui/material/Input"
import Avatar from "@mui/material/Avatar"
import { BsUiChecks } from "react-icons/bs"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import { useNavigate } from "react-router-dom"
import { useCore } from "../../../hooks/useCore"
import { useAuth } from "../../../hooks/useAuth"
import InputLabel from "@mui/material/InputLabel"
import SearchIcon from '@mui/icons-material/Search'
import FormControl from "@mui/material/FormControl"
import { CompanyProps } from "../../../types/company"
import ListItemText from "@mui/material/ListItemText"
import { StatusColor } from "../../../styles/lightMode"
import useWindowSize from "../../../hooks/useWindowSize"
import ListItemButton from "@mui/material/ListItemButton"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import { Paginator } from "../../../components/Paginator"
import { LoadingPage } from "../../../components/LoadingPage"
import { ListQueryProps, QueryProps } from "../../../types/query"
import InputDatePicker from "../../../components/InputDatePicker"
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import React, { useCallback, useEffect, useLayoutEffect, useState } from "react"
import { Alert, Button, Chip, colors, Grid, MenuItem, Select, SelectChangeEvent, Tooltip } from "@mui/material"
import { toast } from "react-toastify"

export default function Queries(): JSX.Element {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const {
    queries,
    setQueries,
    setTitleBar,
    countQueries,
    queriesSearch,
    setCountQueries,
    setPathTitleBar,
    finalDateQueries,
    setQueriesSearch,
    companyIdQueries,
    pageNumberQueries,
    initialDateQueries,
    setFinalDateQueries,
    setCompanyIdQueries,
    setPageNumberQueries,
    setInitialDateQueries,
  } = useCore()

  const [companies, setCompanies] = useState<CompanyProps[] | undefined>([])
  // const [xlsData, setXlsData] = useState<object>({})
  const [loading, setLoading] = useState<boolean>(true)
  const [showAlert, setShowAlert] = useState<boolean>(false);
  
  const [resultsVisualizations, setResultsVisualizations] = useState(0)

  const qtdVisualizations = [20, 50 , 100]

  const getQueries = async (page?: number) => {
    setLoading(true)
    let url = `call/list/?initial_date=${initialDateQueries?.toISOString().split('T')[0]}&final_date=${finalDateQueries?.toISOString().split('T')[0]}`

    if (companyIdQueries !== "0") {
      url = `${url}&id=${companyIdQueries}`
    }

    if (page) {
      url = `${url}&page=${page}`
    }

    if (resultsVisualizations) {
      url = `${url}&page_size=${resultsVisualizations}`
    }

    const response = await api.get(url) as ListQueryProps

    setQueries(response.content.results)
    setQueriesSearch(response.content.results)
    setCountQueries(response.content.count)
    setLoading(false)
  }

  const getCompanies = useCallback(async () => {
    await api.get('company/list/all/').then((response: any) => {
      setCompanies(response.content)
    })
  }, [])

  const handleChangePage = async (_event: React.ChangeEvent<unknown>, value: number) => {
    setLoading(true)
    setPageNumberQueries(value)
    await getQueries(value)
  }

  const handleCompanyChange = (event: SelectChangeEvent) => {
    setCompanyIdQueries(event.target.value)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    search(value)
  }

  const handleResultsChange = (event: SelectChangeEvent<Number>) => {
    setResultsVisualizations(Number(event.target.value))
  }

  function search(value: string) {
    setQueriesSearch(
      queries?.filter(
        (item) => item.id.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.created_at.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.get_status_display.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.products.find((str) => str.toLowerCase().indexOf(value.toLowerCase()) > -1)
      )
    )
  }

  function downloadFile(file:any): void {
    let nameOfCompany = companies?.filter(company => company.id === companyIdQueries)

    const link = document.createElement('a');
    link.href = 'data:text/csv;base64,' + encodeURIComponent(file);
    link.download = `${user?.profile === "3" && nameOfCompany ? nameOfCompany[0]?.corporate_name : user?.corporate_name}_${initialDateQueries?.toISOString().split('T')[0]}_${finalDateQueries?.toISOString().split('T')[0]}`;
  
    link.style.display = 'none';
    document.body.appendChild(link);
    
    link.click();
    document.body.removeChild(link);
    setShowAlert(false)
    
  }

  function blobToBase64(blob: Blob): Promise<string> {
    return new Promise<string>((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => {
        const base64String = reader.result?.toString()?.split(",")[1];
        if (base64String) {
          resolve(base64String);
        } else {
          reject(new Error("Failed to convert Blob to Base64."));
        }
      };
      reader.onerror = () => {
        reject(new Error("Error reading Blob."));
      };
      reader.readAsDataURL(blob);
    });
  }

  async function getXls() {
    setShowAlert(true)
    const initialDateQueriesXls = initialDateQueries ? `?initial_date=${initialDateQueries.toISOString().split('T')[0]}` : '';
    const finalDateQueriesXls = finalDateQueries ? `&final_date=${finalDateQueries.toISOString().split('T')[0]}` : '';
    const requestUrl = `company/download/invoice/${user?.profile === "3" ? companyIdQueries : user?.corporate_id}/${initialDateQueriesXls}${finalDateQueriesXls}`;
    const company = companies ? companies.find((value) => value.id === companyIdQueries) : ''
    const nameCorporate = company ? company.corporate_name : companyIdQueries
    
    try {
      const response = await api.get(requestUrl , 'blob')

      const downloadUrl = window.URL.createObjectURL(new Blob([response as Blob]));
      const link = document.createElement('a');
      link.href = downloadUrl;
      const fileName = `Relatorio_${nameCorporate}_${initialDateQueriesXls.slice(13)}_${finalDateQueriesXls.slice(13)}.xlsx`;
      link.setAttribute('download', fileName); 
      document.body.appendChild(link);
      link.click();

      if (link.parentNode) {
        link.parentNode.removeChild(link);
      }
      window.URL.revokeObjectURL(downloadUrl);
      setShowAlert(false)
    } catch (error) {
      setShowAlert(false)
      toast.error('Erro ao baixar a planilha', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  };

  useLayoutEffect(() => {
    setPathTitleBar("/")
    setTitleBar("Minhas consultas")
  }, [])

  useEffect(() => {
    if(queriesSearch!.length > 0) {
      setLoading(false)
    } else {
      getQueries().catch(console.error)
    }
    getCompanies()
  }, [])

  return (
    <>
      {
        !loading
          ? <>
            <Grid
              container
              gap={2}
              alignItems="spaceAround"
              textAlign="center"
            >
              {
                user?.profile === "3"
                  ? <FormControl sx={{ marginTop: '9px' }}>
                      <Select
                        required={true}
                        labelId="company-label"
                        id="company"
                        name="company"
                        value={companyIdQueries}
                        onChange={handleCompanyChange}
                      >
                        <MenuItem key={`company-none`} value="0">
                          Selecione a empresa
                        </MenuItem>
                        
                        <MenuItem key={`company-all`} value="all">
                          Todas as empresas
                        </MenuItem>

                        {companies?.map((item) => (
                          <MenuItem key={`company-${item.id}`} value={item.id}>
                            {item.corporate_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  : <></>
              }
              {/* {
                companyIdQueries && companyIdQueries !== "0"
              } */}
              <InputDatePicker value={initialDateQueries} setValue={setInitialDateQueries} />
              <InputDatePicker value={finalDateQueries} setValue={setFinalDateQueries} />
              
              <Select
                sx={{ marginTop: '9px' }}
                required={true}
                labelId="results-label"
                id="results"
                name="results"
                value={resultsVisualizations}
                onChange={handleResultsChange}
              >
                <MenuItem key={`results-none`} value="0">
                  Qtd. Resultados
                </MenuItem>

                {qtdVisualizations?.map((value) => (
                  <MenuItem key={`results-${value}`} value={value}>
                    {value}
                  </MenuItem>
                ))}
              </Select>

              <Button
                variant="contained"
                size="large"
                sx={{ marginTop: '9px'}}
                onClick={() => getQueries()}
              >
                Buscar
              </Button>
              {
                queriesSearch!.length > 0 && user?.profile !== "1"
                  && (
                    <Button
                      variant="outlined"
                      size="large"
                      sx={{ marginTop: '9px' }}
                      onClick={() => getXls()}
                    >
                      Extrair XLSX
                    </Button>
                  )
              }

              {showAlert && (
                <Alert severity="info" variant="filled" sx={{marginTop: '9px'}}>
                  Baixando arquivo, por favor aguarde...
                </Alert>
              )}

            </Grid>
            <br />
            <List dense disablePadding sx={{ width: '100%' }}>
              <ListItem
                key='search'
                disablePadding
              >
                <FormControl
                  fullWidth
                  size="medium"
                  sx={{ m: 1 }}
                  variant="standard"
                >
                  <InputLabel htmlFor="input-search">Pesquisar</InputLabel>
                  <Input
                    id="input-search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={handleChangeSearch}
                  />
                </FormControl>
              </ListItem>
              {
                queriesSearch !== undefined && queriesSearch.length > 0
                  ? queriesSearch?.map((item: QueryProps) => (
                    <ListItem
                      key={item.id}
                      disablePadding
                      secondaryAction={
                        width >= 1080 ?
                          <Chip
                            label={item.get_status_display}
                            sx={{ backgroundColor: StatusColor[item.status], color: colors.common.white }}
                          />
                          : <></>
                      }
                    >
                      <ListItemButton onClick={() => navigate(`/query/${item.id}`)}>
                        <ListItemAvatar>
                          <Avatar sx={{ backgroundColor: StatusColor[item.status] }}>
                            {
                              item.checkmatch === true
                                ? <BsUiChecks />
                                : <ContentPasteSearchIcon />
                            }
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText
                          primary={
                            item.products.length > 1
                              ? <Tooltip title={`${item.products.map((item) => ` ${item}`)}`} arrow placement="right">
                                <span>Consulta multipla</span>
                              </Tooltip>
                              : `${item.products.map((item) => ` ${item}`)}`
                          }
                          secondary={
                            <>
                              {item.created_at}
                              {
                                width < 1080 ?
                                  <>
                                    <br />
                                    <span style={{ color: StatusColor[item.status] }}>{item.get_status_display}</span>
                                  </>
                                  : <></>
                              }
                            </>
                          }
                        />
                      </ListItemButton>
                    </ListItem>
                  ))
                  : <Grid
                    container
                    spacing={2}
                    alignItems="center"
                    textAlign="center"
                  >
                    <Grid item md={12} xs={12}>
                      <Alert severity="info" variant="filled">
                        Nada encontrado
                      </Alert>
                    </Grid>
                  </Grid>
              }
            </List>
            <Paginator count={countQueries} pageNumber={pageNumberQueries} qtdResults={resultsVisualizations} onChange={handleChangePage} />
          </>
          : <LoadingPage />
      }
    </>
  )
}
