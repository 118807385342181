import React, { useState } from 'react';
import { TextField, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Box from '@mui/material/Box';
import FormGroup from '@mui/material/FormGroup';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import { FormControl, FormLabel, RadioGroup, FormControlLabel, Radio, Tooltip, InputAdornment } from '@mui/material';
import InfoIcon from "@mui/icons-material/Info";
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import ImageIcon from '@mui/icons-material/Image';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DateField } from '@mui/x-date-pickers/DateField';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import dayjs from 'dayjs';

type Field = {
    label: string;
    required: boolean;
    value: string;
    options: string[];
    error?: string
    length?: string | number;
};

type Fields = {
    fields: Field;
};

type SelectChangeEvent3 = {
    target: {
        name: string;
        checked: boolean;
    };
}

export const CreateFieldQuestion = ({ fields }: Fields) => {

    return (
        <>
            <TextField
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: 45,
                        '& input': {
                            height: '100%',
                            boxSizing: 'border-box',
                            padding: '0 14px',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        '&.Mui-error': {
                            '& fieldset': {
                                borderColor: 'red',
                            },
                        },
                    },
                    '& .MuiInputLabel-root': {
                        top: '-6px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        maxWidth: 'calc(100% - 24px)',

                    },
                }}
                label={
                    <div style={{ display: 'flex', alignItems: 'center' }} >

                        <Tooltip title={fields.label} placement="top" arrow>
                            <span style={{
                                display: 'block',
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                maxWidth: '100%'
                            }}>
                                {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                                {fields.label || fields.error}
                            </span>
                        </Tooltip>
                    </div>
                }
                value={fields.value || ''}
                error={!!fields.error}
                disabled
                variant="outlined"
                margin="none"
                InputProps={{
                    endAdornment: (
                        fields.value && fields.value.length >= 50 &&
                        <InputAdornment position="end">
                            <Tooltip title={fields.value} placement="right">
                                <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                            </Tooltip>
                        </InputAdornment>
                    )
                }}
            />
        </>
    )
}

export const CreateFieldAttachments = ({ fields }: Fields) => {
    return (
        <TextField
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: 45,
                    '& input': {
                        height: '100%',
                        boxSizing: 'border-box',
                        padding: '0 14px',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '&.Mui-error': {
                        '& fieldset': {
                            borderColor: 'red',
                        },
                    },
                },
                '& .MuiInputLabel-root': {
                    top: '-6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: 'calc(100% - 24px)',

                },
            }}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }} >

                    <Tooltip title={fields.label} placement="top" arrow>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                            <span style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                                marginRight: '8px',
                            }}>
                                {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                                {fields.label}
                            </span>
                            <PictureAsPdfIcon style={{ flexShrink: 0 }} />
                        </div>
                    </Tooltip>
                </div>
            }
            error={!!fields.error}
            disabled
            variant="outlined"
            margin="none"
        />
    )
}

export const CreateFieldImages = ({ fields }: Fields) => {
    return (
        <TextField
            fullWidth
            sx={{
                '& .MuiOutlinedInput-root': {
                    height: 45,
                    '& input': {
                        height: '100%',
                        boxSizing: 'border-box',
                        padding: '0 14px',
                        alignItems: 'center',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                    },
                    '&.Mui-error': {
                        '& fieldset': {
                            borderColor: 'red',
                        },
                    },
                },
                '& .MuiInputLabel-root': {
                    top: '-6px',
                    whiteSpace: 'nowrap',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    display: 'block',
                    maxWidth: 'calc(100% - 24px)',

                },
            }}
            label={
                <div style={{ display: 'flex', alignItems: 'center' }} >

                    <Tooltip title={fields.label} placement="top" arrow>
                        <div style={{
                            display: 'flex',
                            alignItems: 'center',
                            width: '100%',
                            justifyContent: 'space-between',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        }}>
                            <span style={{
                                whiteSpace: 'nowrap',
                                overflow: 'hidden',
                                textOverflow: 'ellipsis',
                                flexGrow: 1,
                                marginRight: '8px',
                            }}>
                                {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                                {fields.label}
                            </span>
                            <ImageIcon style={{ flexShrink: 0 }} />
                        </div>
                    </Tooltip>
                </div>
            }
            error={!!fields.error}
            disabled
            variant="outlined"
            margin="none"
        />
    )
}

export const CreateFieldDate = ({ fields }: Fields) => {
    return (
        <LocalizationProvider dateAdapter={AdapterDayjs}>
            <FormControl fullWidth error={!!fields.error} sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: !!fields.value ? '-3px' : 0, padding: 0, overflow: 0 }}>
                <DemoContainer
                    components={['DateField', 'DateField', 'DatePicker']}
                    sx={{ display: 'flex', alignItems: 'center', width: '100%', marginTop: !!fields.value ? '-3px' : 0, padding: 0, overflow: 0 }}>

                    <DateField
                        sx={{
                            flex: 1,
                            width: '100%',
                            '& .MuiOutlinedInput-root': {
                                height: '45px',
                                '& fieldset': {
                                    borderColor: !!fields.error ? 'red' : 'default',
                                },
                                '&:hover fieldset': {
                                    borderColor: !!fields.error ? 'red' : 'default',
                                },
                                '&.Mui-focused fieldset': {
                                    borderColor: !!fields.error ? 'red' : 'default',
                                },
                                '&.Mui-error .MuiOutlinedInput-notchedOutline': {
                                    borderColor: !!fields.error ? 'red' : 'default',
                                },
                            },
                            '& .MuiInputBase-input': {
                                height: '100%',
                            },
                            '& .MuiFormLabel-root': {
                                color: !!fields.error ? 'red' : '#BDBDBD',
                            },
                            '& .MuiFormLabel-root.Mui-error': {
                                color: !!fields.error ? 'red' : '#BDBDBD',
                            },
                        }}

                        label={
                            <span>
                                {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}> *</span>}
                                {fields.label || fields.error}
                            </span>
                        }
                        defaultValue={dayjs(fields.value)}
                        format="DD-MM-YYYY"
                        disabled
                        readOnly={true}
                    />
                </DemoContainer>
            </FormControl>
        </LocalizationProvider>
    );
}

export const CreateFieldRadioButton = ({ fields }: Fields) => {

    return (
        <Box sx={{
            width: '100%',
            height: '100%'
        }}>
            <FormControl
                error={!!fields.error}
                component="fieldset"
                sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
            >
                <FormLabel component="legend">
                    {<span style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
                        {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                        {fields.label}{fields.error ? ` - ${fields.error}` : ''}
                    </span>}
                </FormLabel>
                <RadioGroup
                    sx={{ display: 'flex', alignItems: 'center', width: '100%' }}
                    aria-label={fields.label}
                    name="radio-buttons-group"
                    value={fields.value}
                >
                    {fields.options && fields.options.map((option: string, index: number) => (
                        <FormControlLabel
                            sx={{ display: 'flex', alignItems: 'center', width: '100%', wordBreak: 'break-word' }}
                            key={`radio-option-${index}`}
                            value={option}
                            disabled
                            control={<Radio />}
                            label={option.length >= 90 ? (`${option.slice(0, 90)}...`) : option}
                        />
                    ))}

                </RadioGroup>
            </FormControl>
        </Box>
    )
}

export const CreateFieldSelect = ({ fields }: Fields) => {

    const [options, setOptions] = useState('1');

    const handleChange = (event: SelectChangeEvent) => {
        setOptions(event.target.value);
    };

    return (
        <>
            <Box sx={{ width: '100%', height: '100%' }}>
                <FormControl fullWidth error={!!fields.error}>
                    <InputLabel id="demo-controlled-open-select-label" sx={{ marginTop: '-4px' }}>
                        <span style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word', whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}>
                            {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                            {fields.label}{fields.error ? ` - ${fields.error}` : ''}
                        </span>
                    </InputLabel>

                    <Select
                        labelId="demo-simple-select-label"
                        id="demo-simple-select"
                        value={fields.value || ''}
                        label={fields.label}
                        onChange={handleChange}
                        disabled
                        sx={{
                            height: '45px',
                            wordBreak: 'break-word',
                            width: '100%',
                        }}
                        MenuProps={{
                            PaperProps: {
                                sx: {
                                    maxWidth: '20%',
                                    '& li': {
                                        whiteSpace: 'nowrap',
                                        overflow: 'hidden',
                                        textOverflow: 'ellipsis',
                                        maxWidth: '100%',
                                        display: 'block',
                                    },
                                },
                            },
                            disableScrollLock: true
                        }}
                    >
                        {fields.options && fields.options.map((value: string, index: number) => (
                            <MenuItem
                                key={index}
                                value={value}
                                sx={{
                                    wordBreak: 'break-all',
                                    display: 'flex',
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',

                                }}
                            >
                                {value}
                            </MenuItem>
                        ))}

                    </Select>

                </FormControl>
            </Box>

        </>
    );
}

export const CreateFieldCheckBox = ({ fields }: Fields) => {
    const [options, setOptions] = useState<{ [key: string]: boolean }>({});

    const handleChange = (event: SelectChangeEvent3) => {
        setOptions({
            ...options,
            [event.target.name]: event.target.checked,
        });
    };

    return (
        <Box sx={{
            width: '100%',
            height: '100%',
        }}>
            <FormControl fullWidth error={!!fields.error} component="fieldset">
                <FormLabel component="legend">
                    <span style={{ display: 'flex', alignItems: 'center', wordBreak: 'break-word' }}>
                        {fields.required && <span style={{ color: 'red', fontSize: '1.2em' }}>*</span>}
                        {fields.label}{fields.error ? ` - ${fields.error}` : ''}
                    </span>
                </FormLabel>
                <FormGroup>

                    {fields.options && fields.options.map((value: string, index: number) => (

                        <FormControlLabel
                            key={index}
                            sx={{
                                wordBreak: 'break-word'
                            }}
                            control={
                                <Checkbox
                                    name={value}
                                    checked={options[value] && options[value] ? options[value] : fields.value?.includes(value) ?? false}
                                    onChange={handleChange}
                                    disabled
                                />
                            }
                            label={value.length >= 90 ? (`${value.slice(0, 90)}...`) : value}
                        //
                        />
                    ))}
                </FormGroup>
            </FormControl>
        </Box>
    )
}

export default function CreateFieldSection({ fields }: Fields) {
    return (

        <>
            <TextField
                fullWidth
                sx={{
                    '& .MuiOutlinedInput-root': {
                        height: 45,
                        '& input': {
                            height: '100%',
                            border: '1px dashed grey',
                            borderRadius: '8px',
                            padding: '0 14px',
                            alignItems: 'center',
                            whiteSpace: 'nowrap',
                            overflow: 'hidden',
                            textOverflow: 'ellipsis',
                        },
                        '& fieldset': {
                            border: 'none',
                          },
                        '&.Mui-error': {
                            '& fieldset': {
                                borderColor: 'red',
                            },
                        },
                    },
                    '& .MuiInputLabel-root': {
                        top: '-6px',
                        whiteSpace: 'nowrap',
                        overflow: 'hidden',
                        textOverflow: 'ellipsis',
                        display: 'block',
                        maxWidth: 'calc(100% - 24px)',

                    },
                }}
                label={fields.label}
                error={!!fields.error}
                disabled

            />
        </>
    );
}

export const choicesFields = [
    { "id": '0', 'value': (fields: Field) => <CreateFieldQuestion fields={fields} /> },
    { "id": '1', 'value': (fields: Field) => <CreateFieldAttachments fields={fields} /> },
    { "id": '2', 'value': (fields: Field) => <CreateFieldImages fields={fields} /> },
    { "id": '3', 'value': (fields: Field) => <CreateFieldDate fields={fields} /> },
    { "id": '4', 'value': (fields: Field) => <CreateFieldRadioButton fields={fields} /> },
    { "id": '5', 'value': (fields: Field) => <CreateFieldSelect fields={fields} /> },
    { "id": '6', 'value': (fields: Field) => <CreateFieldCheckBox fields={fields} /> },
    { "id": '7', 'value': (fields: Field) => <CreateFieldSection fields={fields} /> }
]

export const optionsFields = [
    { id: '0', id_choice: "0", label: "Texto", type: "1", length_label: 200, length_value: 200 },
    { id: '1', id_choice: "0", label: "Nome", type: "1", length_label: 200, length_value: 80 },
    { id: '2', id_choice: "0", label: "Logradouro", type: "1", length_label: 200, length_value: 200 },
    { id: '3', id_choice: "0", label: "Bairro", type: "1", length_label: 200, length_value: 80 },
    { id: '4', id_choice: "0", label: "Município", type: "1", length_label: 200, length_value: 80 },
    { id: '5', id_choice: "0", label: "UF", type: "1", length_label: 200, length_value: 80 },
    { id: '6', id_choice: "0", label: "Cep", type: "7", length_label: 200 },
    { id: '7', id_choice: "0", label: "Numérico", type: "2", length_label: 200, length_value: 80 },
    { id: '8', id_choice: "0", label: "Email", type: "3", length_label: 200, length_value: 80 },
    { id: '9', id_choice: "0", label: "CPF", type: "4", length_label: 200 },
    { id: '10', id_choice: "0", label: "CNPJ", type: "6", length_label: 200 },
    { id: '11', id_choice: "3", label: "Data", type: "5", length_label: 100 },
    { id: '12', id_choice: "3", label: "Data de Nasc.", type: "5", length_label: 100 },
    { id: '13', id_choice: "0", label: "Celular", type: "8", length_label: 200 },
    { id: '14', id_choice: "0", label: "Telefone", type: "9", length_label: 200 },
    { id: '15', id_choice: "4", label: "Radio", type: "16", length_label: 150, length_value: 100 },
    { id: '16', id_choice: "5", label: "Select", type: "13", length_label: 150, length_value: 100 },
    { id: '17', id_choice: "6", label: "Checkbox", type: "15", length_label: 150, length_value: 100 }
];

export const optionsAttachments = [
    { id: '18', id_choice: "1", label: "Arquivos", type: "10", length_label: 100 },
    { id: '19', id_choice: "2", label: "Imagens", type: "11", length_label: 100 },
    { id: '20', id_choice: "2", label: "Somente Câmera (mobile)", type: "12", length_label: 100 }
];

export const optionSection = { id: '21', id_choice: "7", label: "Seção", type: "18", length_label: 150 }
