type ResponseTypeProps = {
  id: string
  name: string
}

export const ResponseTypes = [
  {
    "id": "1",
    "name": "JSON"
  },
  {
    "id": "2",
    "name": "XML"
  },
  {
    "id": "3",
    "name": "TEXT"
  },
] as ResponseTypeProps[]