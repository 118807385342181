/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from "formik"
import { toast } from 'react-toastify'
import { MonthTypes } from "./month_types"
import * as api from '../../../services/api'
import { ToastContainer } from "react-toastify"
import { useReactToPrint } from 'react-to-print'
import { useCore } from "../../../hooks/useCore"
import PrintIcon from '@mui/icons-material/Print'
import { LightMode } from "../../../styles/lightMode"
import BusinessIcon from '@mui/icons-material/Business'
import { LoadingPage } from "../../../components/LoadingPage"
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth'
import { CompanyProps } from "../../../types/company"
import { InvoiceProps, RetrieveInvoiceProps } from "../../../types/invoice"
import { PureComponent, useCallback, useEffect, useLayoutEffect, useRef, useState } from "react"
import { Alert, Avatar, Button, CircularProgress, FormControl, Grid, InputLabel, List, ListItem, ListItemAvatar, ListItemText, MenuItem, Select, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, TextField } from "@mui/material"

const logo = require('../../../images/logo.png')

export default function GenerateInvoice(): JSX.Element {
  const componentRef = useRef()
  const handlePrint = useReactToPrint({
    // @ts-ignore
    content: () => componentRef.current,
  })
  const { setTitleBar } = useCore()

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } =  useFormik({
    initialValues: {
      month: '01',
      company: '',
      year: new Date().getFullYear()
    },
    onSubmit: generateInvoice,
  })
  const [loading, setLoading] = useState<boolean>(true)
  const [getting, setGetting] = useState<boolean>(false)
  const [companies, setCompanies] = useState<CompanyProps[] | undefined>([])
  const [invoice, setInvoice] = useState<InvoiceProps | undefined>(undefined)
  const [errorMessage, setErrorMessage] = useState<string | undefined>(undefined)

  const getCompanies = useCallback(async () => {
    const response: any = await api.get('company/list/all/')
    setCompanies(response.content)
    setFieldValue('company', response.content[0].id)
    setLoading(false)
  }, [setFieldValue])

  async function generateInvoice() {
    setErrorMessage(undefined)
    setGetting(true)
    const response = await api.get(`company/list/invoice/${values.company}/?month=${values.month}&year=${values.year}`) as RetrieveInvoiceProps

    try {
      toast.success("Faturamento gerado com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
      setInvoice(response.content)
    } catch (e) {
      // @ts-ignore
      setErrorMessage(response.content.message)
    }

    setGetting(false)
  }

  useEffect(() => {
    getCompanies().catch(console.error)
	}, [getCompanies])

  useLayoutEffect(() => {
    setTitleBar("Gerar fatura")
  }, [])

	return (
		<>
      <ToastContainer />
      {
        !loading
          ? <>
              <form style={{ width: '100%' }} onSubmit={handleSubmit}>
                <Grid container spacing={2}>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="month-label">Selecione o mês</InputLabel>
                      <Select
                        required={true}
                        labelId="month-label"
                        id="month"
                        name="month"
                        value={values.month}
                        label="Selecione o mês"
                        onChange={handleChange}
                      >
                        {MonthTypes?.map((item) => (
                          <MenuItem key={`month-${item.id}`} value={item.id}>
                            {item.name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid item md={2} xs={12}>
                    <TextField
                      fullWidth
                      required
                      id="year"
                      name="year"
                      label="Informe o ano"
                      type="number"
                      value={values.year}
                      onChange={handleChange}
                      error={touched.year && Boolean(errors.year)}
                      helperText={touched.year && errors.year}
                    />
                  </Grid>
                  <Grid item md={4} xs={12}>
                    <FormControl fullWidth>
                      <InputLabel id="company-label">Selecione a empresa</InputLabel>
                      <Select
                        required={true}
                        labelId="company-label"
                        id="company"
                        name="company"
                        value={values.company}
                        label="Selecione a empresa"
                        onChange={handleChange}
                      >
                        {companies?.map((item) => (
                          <MenuItem key={`company-${item.id}`} value={item.id}>
                            {item.corporate_name}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <Grid 
                    item 
                    md={2} xs={12}
                    marginTop={.7}
                    textAlign="center"
                  >
                    <Button
                        variant="contained"
                        color="success"
                        size="large"
                        type="submit"
                        disabled={getting}
                      >
                        Gerar
                    </Button>
                  </Grid>
                </Grid>
                <Grid 
                  container 
                  spacing={2} 
                  marginTop={1}
                  textAlign="center"
                >
                  <Grid item sm={12} md={12} lg={12}>
                    <Button
                        variant="outlined"
                        color="success"
                        type="button"
                        disabled={getting}
                        startIcon={<PrintIcon />}
                        onClick={handlePrint}
                      >
                        Imprimir
                    </Button>
                  </Grid>
                  {
                    getting
                      ? <Grid item sm={12} md={12} lg={12}>
                          <CircularProgress color="primary" />
                        </Grid>
                      : <></>
                  }
                </Grid>
              </form>
              {/* @ts-ignore */}
              <Invoice errorMessage={errorMessage} invoice={invoice} ref={componentRef} />
            </>
          : <LoadingPage />
      }
		</>
	)
}

interface InvoiceDetailProps {
  errorMessage: string | undefined
  invoice: InvoiceProps | undefined
}

class Invoice extends PureComponent<InvoiceDetailProps> {
  render() {
    const { errorMessage, invoice } = this.props

    return (
      <>
        {
        errorMessage ?
          <Grid
            container
            marginTop={3}
          >
            <Grid
              item
              xs={12}
              md={12}
            >
              <Alert severity="error">
                {errorMessage}
              </Alert>
            </Grid>
          </Grid>
        : invoice
          ? invoice.queries_prices.length === 0 && invoice.processes_prices.length === 0
            ? <Grid
                container
                marginTop={3}
              >
                <Grid
                  item
                  xs={12}
                  md={12}
                >
                  <Alert severity="warning">
                    Nada encontrado no período informado!
                  </Alert>
                </Grid>
              </Grid>
            : <Grid
                container
                marginTop={3}
                sx={{ border: 1, borderColor: LightMode.primary.main, width: "100%" }}
                alignItems="center"
                justifyContent="center"
              >
                <img 
                  id="print-logo" 
                  src={logo} 
                  alt="Logo" 
                  width={330} height="auto"
                  style={{ paddingTop: 50, paddingBottom: 50 }}
                />
                <Grid
                  container
                  padding={2}
                >
                  <Grid
                    item
                    md={6}
                    sm={12}
                  >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar variant="rounded" sx={{ bgcolor: LightMode.primary.main }}>
                            <BusinessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                          primary={
                            invoice.queries_prices.length > 0 
                              ? invoice.queries_prices[0].company__corporate_name
                              : invoice.processes_prices.length > 0 
                                ? invoice.processes_prices[0].company__corporate_name
                                : ''
                          } 
                          secondary={
                            invoice.queries_prices.length > 0 
                              ? invoice.queries_prices[0].company__cnpj
                              : invoice.processes_prices.length > 0 
                                ? invoice.processes_prices[0].company__cnpj
                                : ''
                          }
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <Grid
                    item
                    md={6}
                    sm={12}
                  >
                    <List sx={{ width: '100%', maxWidth: 360, bgcolor: 'background.paper' }}>
                      <ListItem>
                        <ListItemAvatar>
                          <Avatar variant="rounded" sx={{ bgcolor: LightMode.primary.main }}>
                            <CalendarMonthIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText 
                          primary="Mês de referência" 
                          secondary={`${invoice.reference_month} de ${invoice.reference_year}`}
                        />
                      </ListItem>
                    </List>
                  </Grid>
                  <TableContainer>
                    <Table>
                      <TableHead>
                        <TableRow>
                          <TableCell>
                            <strong>Descrição</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Tipo</strong>
                          </TableCell>
                          <TableCell>
                            <strong>Quantidade</strong>
                          </TableCell>
                          <TableCell align="right">
                            <strong>Total</strong>
                          </TableCell>
                        </TableRow>
                      </TableHead>

                      <TableBody>
                        <>
                          {
                            invoice.queries_prices.length > 0
                              ? invoice.queries_prices.map((row, index) => {
                                  const productName = invoice.matches_prices?.filter(item => item.product__name === row.product__name)[0] 
                                  if(productName) {
                                    return (
                                      <>
                                        <TableRow key={`queries-${index}`}>
                                          <TableCell>{row.product__name}</TableCell>
                                          <TableCell>Consulta</TableCell>
                                          <TableCell>{row.count}</TableCell>
                                          <TableCell align="right">R$ {row.price__sum}</TableCell>
                                        </TableRow>
                                        <TableRow key={`queries-match-${index}`}>
                                          <TableCell>{productName.product__name}</TableCell>
                                          <TableCell>Match</TableCell>
                                          <TableCell>{productName.count}</TableCell>
                                          <TableCell align="right">R$ {productName.price__sum}</TableCell>
                                        </TableRow>
                                      </>
                                    )
                                  } else {
                                    return (
                                      <TableRow key={`queries-${index}`}>
                                        <TableCell>{row.product__name}</TableCell>
                                        <TableCell>Consulta</TableCell>
                                        <TableCell>{row.count}</TableCell>
                                        <TableCell align="right">R$ {row.price__sum}</TableCell>
                                      </TableRow>
                                    )
                                  }
                                }
                              )
                              : <></>
                          }
                          {
                            invoice.processes_prices.length > 0
                              ? invoice.processes_prices.map((row, index) =>
                                  <TableRow key={`process-${index}`}>
                                    <TableCell>{row.product__name}</TableCell>
                                    <TableCell>Processamento</TableCell>
                                    <TableCell>{row.count}</TableCell>
                                    <TableCell align="right">R$ {row.price__sum}</TableCell>
                                  </TableRow>
                                )
                              : <></>
                          }
                          <TableRow>
                            <TableCell colSpan={3}>
                              <h3>
                                <strong>Total</strong>
                              </h3>
                            </TableCell>
                            <TableCell align="right">
                              <h3>
                                <strong>
                                  R$ {invoice.total}
                                </strong>
                              </h3>
                            </TableCell>
                          </TableRow>
                        </>
                      </TableBody>
                    </Table>
                  </TableContainer>
                </Grid>
              </Grid>
          : <></>
      }
      </>
    )
  }
}