import React from 'react';
import * as field from './components'
import { useItemsStore } from "../../../contexts/zustand/onboarding"
import { TypeEditField } from '../../../types/onboarding'

import { Modal, Box } from '@mui/material';


const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '80%',
    transform: 'translate(-50%, -50%)',
    width: '40%',
    height: '100%',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
};

export function EditFieldQuestion({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': [], 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFieldAttachments({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': [], 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFielSelectOptions({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, optionsValues, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': optionsValues, 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...modalStyle,
                overflowY: optionsValues[refKey] && optionsValues[refKey].length > 3 ? 'auto' : 'hidden'
            }}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.SelectOptions refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFielRadioOptions({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, optionsValues, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': optionsValues, 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...modalStyle,
                overflowY: optionsValues[refKey] && optionsValues[refKey].length > 3 ? 'auto' : 'hidden'
            }}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.RadioOptions refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFielCheckBoxOptions({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, optionsValues, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': optionsValues, 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                ...modalStyle,
                overflowY: optionsValues[refKey] && optionsValues[refKey].length > 3 ? 'auto' : 'hidden'
            }}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.CheckBoxOptions refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFielDate({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': [], 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.OptionRequired refKey={refKey}/>
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};

export function EditFieldSection({ open, setOpen, refKey, onSubmit }: TypeEditField) {

    const { label, valueRequired } = useItemsStore()

    const handleClose = () => {
        setOpen(false);
       // clearFormValues();
    }

    const onSubmitHandle = () => {
        onSubmit(
            { 'label': label, 'options': [], 'valueRequired': valueRequired, 'key': refKey })
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={modalStyle}>

                <field.TextComponentField />
                <field.TextInputField refKey={refKey} />
                <field.ButtonSavedValues open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmitHandle} />

            </Box>
        </Modal >
    );
};


