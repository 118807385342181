import { createTheme } from '@mui/material'
import { 
  amber, green, grey, pink, lightGreen, blue
 } from '@mui/material/colors'

export const DarkMode = {
  primary: {
    main: green[300]
  },
  secondary: {
    main: lightGreen[400]
  },
  info: {
    main: blue[600]
  },
  success: {
    main: green["A400"]
  },
  warning: {
    main: amber["A400"]
  },
  error: {
    main: pink["A400"]
  },
  background: {
    default: grey[800],
    paper: grey[900],
  },
  text: {
    primary: grey[400],
    secondary: grey[400],
    disabled: grey[600],
  },
  divider: grey[800],
  action: {
    active: grey[50],
    hover: grey[700],
    disabledBackground: grey[50],
  }
}

export const DarkTheme = createTheme({
  palette: {
    primary: {
      main: green[300]
    },
    secondary: {
      main: lightGreen[400]
    },
    info: {
      main: blue[600]
    },
    success: {
      main: green["A400"]
    },
    warning: {
      main: amber["A400"]
    },
    error: {
      main: pink["A400"]
    },
    background: {
      default: grey[800],
      paper: grey[900],
    },
    text: {
      primary: grey[50],
      secondary: grey[400],
      disabled: grey[600],
    },
    divider: grey[800],
    action: {
      active: grey[50],
      hover: grey[700],
      disabledBackground: grey[50],
    }
  }
})