import { cookies } from '../hooks/useCookies'
import axios, { AxiosRequestConfig, ResponseType } from "axios"

const API_URL = 'https://service.checkview.com.br'
const API_URL_ONBOARDING = 'https://on.checkview.com.br'

const api = axios.create({
	baseURL: API_URL,
})

export const APIOnboarding = axios.create({
	baseURL: API_URL_ONBOARDING,
	headers: {
		'Content-Type': 'application/json',
		'Authorization': 'Token 7ee089d7599ec10c4e93252fe96673f493cda070'
	}
})

async function refreshToken() {
	defaults.responseType = 'json'
	interface Response {
		access: string
		refresh: string
	}
	return new Promise(async (resolve) => {
		await api.post(
			'token/refresh/', 
			{"refresh": cookies.get('@CheckView:refresh')}
		).then((response) => {
			const responseData = response.data as Response
			cookies.set('@CheckView:access', responseData.access)
			cookies.set('@CheckView:refresh', responseData.refresh)
			defaults.headers!.Authorization = `Bearer ${responseData.access}`
			resolve(true)
		}).catch((error) => {
			cookies.remove('@CheckView:id')
			cookies.remove('@CheckView:name')
			cookies.remove('@CheckView:email')
			cookies.remove('@CheckView:first_login')
			cookies.remove('@CheckView:access')
			cookies.remove('@CheckView:refresh')
			defaults.headers!.Authorization = ''
			window.location.replace("/")
		})
	})
}

export function destroy(path: string, responseType: ResponseType = 'json') {
	defaults.responseType = responseType
	return new Promise(async (resolve) => {
		await api.delete(path, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.delete(path, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error)
					})
				}
			} else {
				resolve(error)
			}
		})
	})
}

export async function get(path: string, responseType: ResponseType = 'json') {
	defaults.responseType = responseType
	return new Promise(async (resolve) => {
		await api.get(path, defaults).then((response) => {
			resolve(response.data)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.get(path, defaults).then((response) => {
						resolve(response.data)
					}).catch((error) => {
						resolve(error.response.data)
					})
				}
			} else {
				resolve(error.response.data)
			}
		})
	})
}

export function put(path: string, data: object, responseType: ResponseType = 'json') {
	defaults.responseType = responseType
	return new Promise(async (resolve) => {
		await api.put(path, data, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.put(path, data, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error)
					})
				}
			} else {
				resolve(error)
			}
		})
	})
}

export function post(path: string, data: object, content_type?: string, responseType: ResponseType = 'json') {
	let _defaults = {
		'headers': {
			'Authorization': defaults.headers!.Authorization,
			'Content-Type': defaults.headers!["Content-Type"]
		},
		'responseType': responseType
	}
	if (content_type) {
		_defaults.headers['Content-Type'] = content_type
	}
	return new Promise(async (resolve) => {
		await api.post(path, data, _defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401 && error.response.data.detail !== "Usuário e/ou senha incorreto(s)") {
				var res = await refreshToken()
				if (res) {
					await api.post(path, data, _defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error.response)
					})
				}
			} else {
				resolve(error.response)
			}
		})
	})
}

export function patch(path: string, data: object, responseType: ResponseType = 'json') {
	defaults.responseType = responseType
	return new Promise(async (resolve) => {
		await api.patch(path, data, defaults).then((response) => {
			resolve(response)
		}).catch(async (error) => {
			if (error.response.status === 401) {
				var res = await refreshToken()
				if (res) {
					await api.patch(path, data, defaults).then((response) => {
						resolve(response)
					}).catch((error) => {
						resolve(error)
					})
				}
			} else {
				resolve(error)
			}
		})
	})
}

export const defaults: AxiosRequestConfig = {
	'headers': {
		'Authorization': '',
		'Content-Type': 'application/json'
	},
	'responseType': 'json'
}