import List from "@mui/material/List"
import Input from "@mui/material/Input"
import Avatar from "@mui/material/Avatar"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import { useNavigate } from "react-router-dom"
import { useCore } from "../../../hooks/useCore"
import IconButton from "@mui/material/IconButton"
import InputLabel from "@mui/material/InputLabel"
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import { toast, ToastContainer } from 'react-toastify'
import ListItemButton from "@mui/material/ListItemButton"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import { Paginator } from "../../../components/Paginator"
import ModalDelete from "../../../components/ModalDelete"
import { LoadingPage } from "../../../components/LoadingPage"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import React, { useEffect, useLayoutEffect, useState } from "react"
import { ListProductProps, ProductProps } from "../../../types/product"

export default function Products(): JSX.Element {
  const navigate = useNavigate()
  const { setPathTitleBar, setTitleBar } = useCore()

  const [products, setProducts] = useState<ProductProps[]>([])
  const [productsSearch, setProductsSearch] = useState<ProductProps[]>([])
  const [open, setOpen] = useState<boolean>(false)
  const [idDelete, setIdDelete] = useState<string | undefined>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(false)
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const getProducts = async (url: string) => {
    setLoading(true)
    const response = await api.get(url) as ListProductProps
    setProducts(response.content.results)
    setProductsSearch(response.content.results)
    setCount(response.content.count)
    setLoading(false)
  }

  const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
    setLoading(true)
    setPageNumber(value)
    await getProducts(`product/list/?page=${value}`)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value
    search(value)
    setSearchValue(value)
	}

  function search(value: string) {
    setProductsSearch(
      products?.filter((item) => item.name.toLowerCase().indexOf(value.toLowerCase()) > -1)
    )
  }

  function handleModalOpen(id: string) {
    setIdDelete(id)
    setOpen(true)
  }

  const handleModalClose = () => setOpen(false)

  const handleDelete = async () => {
    setLoading(true)
    const response = await api.destroy(`product/destroy/${idDelete}/`) as {[key:string]: any}
    if (response.status === 204) {
      let indexProducts = products?.findIndex((item, i) => item.id === idDelete)
      products?.splice(indexProducts!, 1)
      setProductsSearch(products)
      search(searchValue)
      toast.success("Produto excluído com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Falha ao tentar excluir produto!", {
			  position: toast.POSITION.TOP_RIGHT
		  })
    }
    handleModalClose()
    setLoading(false)
  }

  useEffect(() => {
    getProducts('product/list/').catch(console.error)
	}, [])

  useLayoutEffect(() => {
    setTitleBar("Produtos")
    setPathTitleBar("/new-product")
  }, [])

	return (
		<>
      <ToastContainer />
      <ModalDelete open={open} textBody="Tem certeza que deseja excluir esse produto?" handleModalClose={handleModalClose} handleDelete={handleDelete} loading={loading} />
			{
        !loading
          ? <>
              <List dense disablePadding sx={{ width: '100%' }}>
                <ListItem 
                  key='search' 
                  disablePadding
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                    <Input
                      id="input-search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      onChange={handleChangeSearch}
                    />
                  </FormControl>
                </ListItem>
                {productsSearch?.map((item) => (
                  <ListItem 
                    key={item.id} 
                    disablePadding 
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => {handleModalOpen(item.id)}}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => navigate(`/product/${item.id}`)}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={item.name} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Paginator count={count} pageNumber={pageNumber} onChange={handleChangePage} />
            </>
          : <LoadingPage />
      }    
		</>
	)
}
