type CategoryProps = {
  id: string
  name: string
}

export const Category = [
  {
    "id": "1",
    "name": "Veícular"
  },
  {
    "id": "2",
    "name": "Cadastral PF"
  },
  {
    "id": "4",
    "name": "Cadastral PJ"
  },
  {
    "id": "3",
    "name": "Validação"
  },
] as CategoryProps[]

export const dysplayCategory = {
  '1': 'Veícular',
  '2': 'Cadastral PF',
  '3': 'Validação',
  '4': 'Cadastral PJ'
}