/* eslint-disable react-hooks/exhaustive-deps */
// import "./style.css"
import { AsideStyled } from "./styles"
import { useEffect, useState } from "react"

interface propsI {
    text: string
    title: string
}

export default function InformationCard({ text, title }: propsI) {

    const [ firstBool, setFirstBool ] = useState(true)

    const canvas = document.getElementsByTagName('aside')


    function getRandomXPosition(index: number) {
        const width = canvas[index].scrollWidth
        const x = Math.floor(Math.random() * width)
        return x
    }

    function getRandomYPosition(index: number) {
        const height = canvas[index].offsetHeight
        const y = Math.floor(Math.random() * height)
        return y
    }

    function getRandomRadius(min: number, max: number) {
        min = Math.ceil(min)
        max = Math.floor(max)
        return Math.floor(Math.random() * (max - min) + min)
    }

    function createCircles() {
            setFirstBool(false)
            for (let index = 0; index < canvas.length; index++) {
                const svg = document.createElementNS('http://www.w3.org/2000/svg', 'svg')
                const svgNS = svg.namespaceURI

                const circle = document.createElementNS(svgNS, 'circle')
                const line = document.createElementNS(svgNS, 'line')
                const fill = "rgba(255,255,255,0.5)"
                const stroke = "rgba(255,255,255,0.5)"
                const circlePositionx = getRandomXPosition(index)
                const circlePositiony = getRandomYPosition(index)
                const linePositionx = getRandomXPosition(index)
                const linePositiony = getRandomYPosition(index)
                const radius = getRandomRadius(50, 150)

                circle.setAttributeNS(null, 'id', 'gen-circle')
                circle.setAttributeNS(null, 'cx', circlePositionx.toString())
                circle.setAttributeNS(null, 'cy', circlePositiony.toString())
                circle.setAttributeNS(null, 'r', radius.toString())

                if (0 % 2 !== 0) {
                    circle.setAttributeNS(null, 'fill', 'none')
                    circle.setAttributeNS(null, 'stroke', stroke)
                } else {
                    circle.setAttributeNS(null, 'fill', fill)
                    circle.setAttributeNS(null, 'stroke', 'transparent')
                }
                circle.setAttributeNS(null, 'stroke-width', '2')

                line.setAttributeNS(null, 'id', 'gen-line')
                line.setAttribute('x1', linePositionx.toString())
                line.setAttribute('y1', linePositiony.toString())
                line.setAttribute('x2', (linePositionx + 200).toString())
                line.setAttribute('y2', (linePositiony + 200).toString())
                line.setAttribute('stroke', fill)
                line.setAttribute('stroke-width', '2')

                svg.appendChild(circle)
                canvas[index].appendChild(svg)
                svg.appendChild(line)
                canvas[index].appendChild(svg)
            }
        
    }

    useEffect(() => {
        if(firstBool) {
            createCircles()
        }
    }, [])

    return (
        <AsideStyled id="container-aside" >
            <svg className="aside__icon" width="40" height="40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M20 1.895a18.107 18.107 0 1 0 18.107 18.107A18.128 18.128 0 0 0 20 1.895Zm0 32.639a14.532 14.532 0 1 1 14.532-14.532A14.548 14.548 0 0 1 20 34.534Z" fill="#222" />
                <path d="M19.215 14.693a2.42 2.42 0 1 0 0-4.842 2.42 2.42 0 0 0 0 4.842ZM17.63 16.86a1.44 1.44 0 0 0 .252 2.856h.142v7.971h-.77a1.231 1.231 0 0 0-1.23 1.231 1.231 1.231 0 0 0 1.23 1.232h5.492a1.232 1.232 0 0 0 1.232-1.232 1.231 1.231 0 0 0-1.232-1.23h-.761V17.511a1.243 1.243 0 0 0-1.484-1.219c-1.242.246-2.821.559-2.87.567Z" fill="#222" />
            </svg>
            <div className="aside__content">
                <strong>{title}</strong>
                <div>
                    {text}
                </div>
            </div>
        </AsideStyled>
    )
}