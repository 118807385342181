import Core from '../pages/Core'
import Apis from '../pages/Api/Apis'
import Users from '../pages/User/Users'
import Dashboard from '../pages/Dashboard'
import CheckMatch from '../pages/CheckMatch'
import Queries from '../pages/Query/Queries'
import Products from '../pages/Product/Products'
import Documentation from '../pages/Documentation'

import Onboarding from '../pages/Onboarding/HomeOnboarding'
import OnboardingCreate from '../pages/Onboarding/CreateOnboarding'
import OnboardingSaveds from '../pages/Onboarding/SavedOnboarding'
import EditOnboardingSaved from '../pages/Onboarding/SavedOnboarding/EditSavedOnboarding'
import EditSignatoryContact from '../pages/Onboarding/EditContact'
import ViewResponseSignatory from '../pages/Onboarding/ViewResultsOnboarding'

import Companies from '../pages/Company/Companies'
import Providers from '../pages/Provider/Providers'
import QueryDetail from '../pages/Query/QueryDetail'
import NewProcessing from '../pages/Processing/NewProcessing'
import GenerateInvoice from '../pages/Invoice/GenerateInvoice'
import { useAuth } from '../hooks/useAuth'
import CreateOrUpdateApi from '../pages/Api/CreateOrUpdateApi'
import { BrowserRouter, Routes, Route } from 'react-router-dom'
import ListProcessing from '../pages/Processing/ListProcessing'
import CreateOrUpdateUser from '../pages/User/CreateOrUpdateUser'
import CreateOrUpdateCompany from '../pages/Company/CreateOrUpdateCompany'
import CreateOrUpdateProduct from '../pages/Product/CreateOrUpdateProduct'
import CreateOrUpdateProvider from '../pages/Provider/CreateOrUpdateProvider'

/**
 * Defines the routes for the application using the 'react-router-dom' library.
 * Sets up routes for different pages such as Companies, Users, Providers, APIs, Products, Queries, Invoice, Processing, and Documentation.
 * Each route is associated with a specific page component that will be rendered when the route is accessed.
 * @returns The JSX element that defines the routes for the application.
 */

export default function OtherRoutes(): JSX.Element {

  const { user } = useAuth()

  return (
    <BrowserRouter>
      <Routes>
        <Route path='/' element={<Core />}>
          <Route index element={<Dashboard/>}/>
          <Route path='*' element={<Dashboard/>}/>
          {/* Companies */}
          <Route path='/companies' element={<Companies/>}/>
          <Route path='/company/:id' element={<CreateOrUpdateCompany/>}/>
          <Route path='/new-company' element={<CreateOrUpdateCompany/>}/>
          {/* Users */}
          {/* <Route path='/users' element={<AdmUsers/>}/> */}
          <Route path='/users/:id' element={<Users/>}/>
          {/* <Route path='/new-user' element={<CreateOrUpdateUser/>}/> */}
          <Route path='/new-user/:company_id' element={<CreateOrUpdateUser/>}/>
          <Route path='/user/:id' element={<CreateOrUpdateUser/>}/>
          {/* Providers */}
          <Route path='/providers' element={<Providers/>}/>
          <Route path='/provider/:id' element={<CreateOrUpdateProvider/>}/>
          <Route path='/new-provider' element={<CreateOrUpdateProvider/>}/>
          {/* APIs */}
          <Route path='/apis/:id' element={<Apis/>}/>
          <Route path='/new-api/:provider_id' element={<CreateOrUpdateApi/>}/>
          <Route path='/api/:id' element={<CreateOrUpdateApi/>}/>
          {/* Products */}
          <Route path='/products' element={<Products/>}/>
          <Route path='/new-product' element={<CreateOrUpdateProduct/>}/>
          <Route path='/product/:id' element={<CreateOrUpdateProduct/>}/>
          {/* Queries */}
          <Route path='/queries' element={<Queries/>}/>
          <Route path='/query/:id' element={<QueryDetail/>}/>
          {/* Invoice */}
          <Route path='/generate/invoice' element={<GenerateInvoice/>}/>
          {/* Processing */}
          <Route path='/list-processing' element={<ListProcessing/>}/>
          <Route path='/new-processing' element={<NewProcessing/>}/>
          {/* Documentation */}
          <Route path='/documentation' element={<Documentation />} />
          {/* Onboarding */}
          <Route path='/onboarding' element={<Onboarding />} />
          <Route path='/onboarding/saved' element={<OnboardingSaveds />} />
          <Route path='/onboarding/saved/edit/:id_onboarding' element={<EditOnboardingSaved />} />
          <Route path='/onboarding/create-onboarding' element={<OnboardingCreate />} />
          <Route path='/onboarding/edit-contact/:id_signatory' element={<EditSignatoryContact />} />
          <Route path='/onboarding/view-response/:id_signatory' element={<ViewResponseSignatory />} />
          {/* CheckMatch */}
          {
            user?.checkmatch === true
            ? <Route path='/check-match' element={<CheckMatch />} />
            : <></>
          }
        </Route>
      </Routes>
    </BrowserRouter>
  )
}