import List from "@mui/material/List"
import { toast } from 'react-toastify'
import Input from "@mui/material/Input"
import Avatar from "@mui/material/Avatar"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import { ToastContainer } from "react-toastify"
import { useCore } from "../../../hooks/useCore"
import InputLabel from "@mui/material/InputLabel"
import IconButton from "@mui/material/IconButton"
import SearchIcon from '@mui/icons-material/Search'
import FormControl from "@mui/material/FormControl"
import DeleteIcon from '@mui/icons-material/Delete'
import { Link, useNavigate } from "react-router-dom"
import ListItemText from "@mui/material/ListItemText"
import BusinessIcon from '@mui/icons-material/Business'
import { ListItemSecondaryAction } from "@mui/material"
import ListItemButton from "@mui/material/ListItemButton"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import InputAdornment from "@mui/material/InputAdornment"
import ModalDelete from "../../../components/ModalDelete"
import { Paginator } from "../../../components/Paginator"
import { useEffect, useLayoutEffect, useState } from "react"
import { LoadingPage } from "../../../components/LoadingPage"
import { ProviderProps, ListProviderProps } from "../../../types/provider"
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'

export default function Providers(): JSX.Element {
  const navigate = useNavigate()
  const { setTitleBar, setPathTitleBar } = useCore()

  const [providers, setProviders] = useState<ProviderProps[] | undefined>([])
  const [providersSearch, setProvidersSearch] = useState<ProviderProps[] | undefined>([])
  const [open, setOpen] = useState<boolean>(false)
  const [idDelete, setIdDelete] = useState<string | undefined>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
    setLoading(true)
    setPageNumber(value)
    await getProviders(`provider/list/?page=${value}`)
  }

  const getProviders = async (url: string) => {
    const response = await api.get(url) as ListProviderProps
    setCount(response.content.count)
    setProviders(response.content.results)
    setProvidersSearch(response.content.results)
    setLoading(false)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value
    search(value)
    setSearchValue(value)
	}

  function search(value: string) {
    setProvidersSearch(
      providers?.filter((item) => item.corporate_name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.cnpj.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.fantasy_name.toLowerCase().indexOf(value.toLowerCase()) > -1)
    )
  }

  function handleModalOpen(id: string) {
    setIdDelete(id)
    setOpen(true)
  }

  const handleModalClose = () => setOpen(false);

  const handleDelete = async () => {
    setLoading(true)
    const response = await api.destroy(`provider/destroy/${idDelete}/`) as {[key:string]: any}
    if (response.status === 204) {
      let indexProviders = providers?.findIndex((item, i) => item.id === idDelete)
      providers?.splice(indexProviders!, 1)
      search(searchValue)
      toast.success("Fornecedor excluido com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Falha ao tentar excluir fornecedor!", {
			  position: toast.POSITION.TOP_RIGHT
		  })
    }
    handleModalClose()
    setLoading(false)
  }

  useEffect(() => {
    getProviders('provider/list/').catch(console.error)
	}, [])

  useLayoutEffect(() => {
    setTitleBar("Fornecedores")
    setPathTitleBar("/new-provider")
  }, [])

	return (
		<>
      <ToastContainer />
      
      <ModalDelete open={open} textBody="Tem certeza que deseja excluir esse fornecedor? Todas as APIs relacionados a ele também serão excluídas e pode impactar produtos que há utilizem." handleModalClose={handleModalClose} handleDelete={handleDelete} loading={loading} />
			{
        !loading
          ? <>
              <List dense disablePadding sx={{ width: '100%' }}>
                <ListItem 
                  key='search' 
                  disablePadding
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                    <Input
                      id="input-search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      onChange={handleChangeSearch}
                    />
                  </FormControl>
                </ListItem>
                {providersSearch?.map((item) => (
                  <ListItem 
                    key={item.id} 
                    disablePadding 
                  >
                    <Link to={`/provider/${item.id}`} className="w-100">
                      <ListItemButton>
                        <ListItemAvatar>
                          <Avatar>
                            <BusinessIcon />
                          </Avatar>
                        </ListItemAvatar>
                        <ListItemText primary={item.corporate_name} secondary={item.fantasy_name} />
                      </ListItemButton>
                    </Link>
                    <ListItemSecondaryAction>
                      <IconButton 
                        sx={{ mr: 1 }} 
                        edge="end" 
                        aria-label="users" 
                        onClick={
                          () => {
                            navigate(
                              `/apis/${item.id}`, 
                              {state: {fantasy_name: item.fantasy_name}}
                            )
                          }
                        }
                      >
                        <ElectricalServicesIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="delete" onClick={() => {handleModalOpen(item.id)}}>
                        <DeleteIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
              <Paginator count={count} pageNumber={pageNumber} onChange={handleChangePage} />
            </>
          : <LoadingPage />
      }
		</>
	)
}