import React, { useState, useEffect, ReactNode } from 'react'
import * as api from '../../services/api'
import { useAuth } from "../../hooks/useAuth"

import Blocked from "./Blocked"
import { LoadingPage } from "../../components/LoadingPage"

import { toast } from "react-toastify"

type OnboardingsssProps = {
    children: ReactNode;
}

export default function ProtectRoutesOnboarding({children}: OnboardingsssProps) {

    const { user } = useAuth()

    const [loadingPage, setLoadingPage] = useState<boolean>(true)
    const [tokenOnboarding, setTokenOnboarding] = useState<string>('')

    useEffect(() => {
        const companyId = user?.corporate_id!;
        const fetchData = async () => {
            try {
                const getToken = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any };
                const tokenOnboardingApi = getToken.content.token_onboarding;
    
                if (!tokenOnboardingApi) {
                    setTokenOnboarding(tokenOnboardingApi);
                    setLoadingPage(false);
                    return;
                }
    
                setTokenOnboarding(tokenOnboardingApi);
                setLoadingPage(false);
    
            } catch (error) {
                toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
                    position: toast.POSITION.TOP_RIGHT
                });
                setLoadingPage(false);
            }
        };
    
        fetchData();
    }, [user?.corporate_id]);
    

    if (loadingPage) {
        return <LoadingPage />;
    }

    if (!tokenOnboarding) {
        return <Blocked />;
    }

    return (
        <>
            {children}
        </>
    )

}


