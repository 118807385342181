type RequestTypeProps = {
  id: string
  name: string
}

export const RequestTypes = [
  {
    "id": "1",
    "name": "JSON"
  },
  {
    "id": "2",
    "name": "XML"
  },
  {
    "id": "3",
    "name": "FORM-DATA"
  },
  {
    "id": "4",
    "name": "URL-ENCODED"
  },
  {
    "id": "5",
    "name": "PARAMS"
  },
  {
    "id": "6",
    "name": "DATA"
  },
] as RequestTypeProps[]