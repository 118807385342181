import * as api from '../services/api'
import { toast } from 'react-toastify'
import { LoginProps } from '../types/user'
import { cookies } from '../hooks/useCookies'
import { defaults } from '../services/checkMatch'
import { createContext, useEffect, useState } from 'react'

interface AuthContextData {
  signed: boolean
  user: LoginProps | null
  Login(user: object): Promise<void>
  Logout(): void
  Forgot(data: object): Promise<boolean>
  ChangePassword(data: object): Promise<void>
}

const AuthContext = createContext<AuthContextData>({} as AuthContextData)

export function AuthProvider({ children }: any) {
  const [user, setUser] = useState<LoginProps | null>(null)

  function defaultCookiesSession() {
    setUser(null)
    cookies.remove('@CheckView:user')
    cookies.remove('@CheckView:access')
    cookies.remove('@CheckView:refresh')
  }

  async function Login(userData: object) {
    toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

    await api.post('token/', userData).then((response: any) => {
      toast.dismiss()
      if (response.status === 200) {
        const data = response.data as LoginProps
        setUser(data)
        api.defaults.headers!.Authorization = `Bearer ${data.access}`
        defaults.headers.Authorization = `Token ${data.checkmatch_token}`
        cookies.set('@CheckView:user', JSON.stringify(data))
        cookies.set('@CheckView:access', data.access)
        cookies.set('@CheckView:refresh', data.refresh)
        window.location.replace("/")
      } else {
        toast.error(response.data.detail, {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }).catch((error) => {
      toast.error(error.data.detail, {
			  position: toast.POSITION.TOP_RIGHT
		  })
    })
  }

  async function Logout() {
    defaultCookiesSession()
    window.location.replace("/")
  }

  useEffect(() => {
    const cookieUser = cookies.get('@CheckView:user')
    const cookieTokenAccess = cookies.get('@CheckView:access')
    const cookieTokenRefresh = cookies.get('@CheckView:refresh')

    if (cookieTokenAccess && cookieTokenRefresh && cookieUser) {
      setUser(cookieUser)
      api.defaults.headers!.Authorization = `Bearer ${cookieTokenAccess}`
      defaults.headers.Authorization = `Token ${cookieUser.checkmatch_token}`
    }
  }, [])

  async function Forgot(data: object) {
    toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

    let ok = false

    await api.post('user/forgot-password/', data).then((response: any) => {
      toast.dismiss()
      if (response.status === 200) {
        toast.success(response.data.message, {
          position: toast.POSITION.TOP_RIGHT
        })
        ok = true
      }
    })

    return ok
  }

  type dataChangePasswordProps = {
    current_password: string,
    new_password: string,
    new_password_again: string
  }

  async function ChangePassword(data: dataChangePasswordProps) {
    if (data.new_password !== data.new_password_again) {
      toast.error("Campos de 'Nova senha' e 'Repita a nova senha' devem ser idênticos!", {
			  position: toast.POSITION.TOP_RIGHT
		  })
      return
    }

    toast.loading("Aguarde...", {
      position: toast.POSITION.TOP_RIGHT,
    })

    const response = await api.post('user/change-password/', data) as {[key:string]: any}
    toast.dismiss()

    if (response.status === 200) {
      toast.success("Senha atualizada com sucesso! Redirecionando, aguarde...", {
        position: toast.POSITION.TOP_RIGHT
      })
      setTimeout(() => {
        defaultCookiesSession()
        window.location.reload()
      }, 5000)
    } else if (response.status === 400) {
      toast.error("A nova senha informada não atende os critérios de senha forte, ela deverá conter no mínimo: 8 caracteres, uma letra maiuscula, uma letra minuscula, um número e um símbolo.", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Ocorreu um erro ao tentar alterar a senha do usuário, tente novamente mais tarde", {
        position: toast.POSITION.TOP_RIGHT
      })
      setTimeout(() => {
        defaultCookiesSession()
        window.location.reload()
      }, 5000)
    }
  }

  return (
   <AuthContext.Provider value={{ signed: Boolean(user), user, Login, Logout, Forgot, ChangePassword }}>
     {children}
   </AuthContext.Provider>
 )
}

export default AuthContext