import styled from "styled-components"
import { common } from '@mui/material/colors'
import { LightMode } from "../../styles/lightMode"

 const bg_sign = require("../../images/sign-bg.jpg")
 
export const BackgroundSignStyle = styled.div`
  width: 100%;
  height: 100vh;
  background-image: url(${bg_sign});
  background-repeat: no-repeat;
  background-size: cover;

  .bg-black {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-around;
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100vh;
  }

  .div-card {
    margin: 0 20px;
    padding: 3%;
    border-radius: 8px;
    border: ${LightMode.primary.main} 2px solid;
  }

  p {
    color: ${common.black};
    text-transform: uppercase;
    text-align: center;
    font-size: 1.5rem;
    font-weight: bold;
  }

  .backgroundSign {
    background-image: url(${bg_sign});
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    width: 100%;
    height: 100vh;
  }
`