import { createTheme } from '@mui/material'
import { common, grey } from '@mui/material/colors'

export const LightMode = {
  primary: {
    main: "#ffa890"
  },
  secondary: {
    main: "#d2d3d5"
  },
  info: {
    main: "#9454a4"
  },
  success: {
    main: "#fca6f2"
  },
  warning: {
    main: "#d4c268"
  },
  error: {
    main: "#ff5975"
  },
  background: {
    default: grey[200],
    paper: grey[100],
  },
  text: {
    primary: grey[900],
    secondary: grey[600],
    disabled: grey[400],
  },
  divider: grey[200],
  action: {
    active: grey[600],
    hover: "#fae6e6",
    disabledBackground: grey[300],
  }
}

export const StatusColor = {
  "1": grey[800],
  "2": LightMode.warning.main,
  "3": LightMode.success.main,
  "4": LightMode.error.main,
}

export const LightTheme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        input: {
          "&::placeholder": {
            color: {
              main: LightMode.primary.main
            }
          }
        }
      },
    },
  },
  palette: {
    primary: {
      50: '#ffa890',
      100: '#ffa890',
      200: '#ffa890',
      300: '#ffa890',
      400: '#ffa890',
      500: '#ffa890',
      600: '#ffa890',
      700: '#ffa890',
      800: '#ffa890',
      900: '#ffa890',
      A100: '#ffa890',
      A200: '#ffa890',
      A400: '#ffa890',
      A700: '#ffa890'
    },
    secondary: {
      50: '#d2d3d5',
      100: '#d2d3d5',
      200: '#d2d3d5',
      300: '#d2d3d5',
      400: '#d2d3d5',
      500: '#d2d3d5',
      600: '#d2d3d5',
      700: '#d2d3d5',
      800: '#d2d3d5',
      900: '#d2d3d5',
      A100: '#d2d3d5',
      A200: '#d2d3d5',
      A400: '#d2d3d5',
      A700: '#d2d3d5'
    },
    info: {
      50: '#9454a4',
      100: '#9454a4',
      200: '#9454a4',
      300: '#9454a4',
      400: '#9454a4',
      500: '#9454a4',
      600: '#9454a4',
      700: '#9454a4',
      800: '#9454a4',
      900: '#9454a4',
      A100: '#9454a4',
      A200: '#9454a4',
      A400: '#9454a4',
      A700: '#9454a4'
    },
    success: {
      50: '#fca6f2',
      100: '#fca6f2',
      200: '#fca6f2',
      300: '#fca6f2',
      400: '#fca6f2',
      500: '#fca6f2',
      600: '#fca6f2',
      700: '#fca6f2',
      800: '#fca6f2',
      900: '#fca6f2',
      A100: '#fca6f2',
      A200: '#fca6f2',
      A400: '#fca6f2',
      A700: '#fca6f2'
    },
    warning: {
      50: '#d4c268',
      100: '#d4c268',
      200: '#d4c268',
      300: '#d4c268',
      400: '#d4c268',
      500: '#d4c268',
      600: '#d4c268',
      700: '#d4c268',
      800: '#d4c268',
      900: '#d4c268',
      A100: '#d4c268',
      A200: '#d4c268',
      A400: '#d4c268',
      A700: '#d4c268'
    },
    error: {
      50: '#ff5975',
      100: '#ff5975',
      200: '#ff5975',
      300: '#ff5975',
      400: '#ff5975',
      500: '#ff5975',
      600: '#ff5975',
      700: '#ff5975',
      800: '#ff5975',
      900: '#ff5975',
      A100: '#ff5975',
      A200: '#ff5975',
      A400: '#ff5975',
      A700: '#ff5975'
    },
    background: {
      default: common.white,
      paper: common.white,
    },
    text: {
      primary: grey[900],
      secondary: LightMode.primary.main,
    },
    action: {
      active: LightMode.primary.main,
    },
  }
})