type MethodProps = {
  id: string
  name: string
}

export const Methods = [
  {
    "id": "1",
    "name": "GET"
  },
  {
    "id": "2",
    "name": "POST"
  },
  {
    "id": "3",
    "name": "PUT"
  },
  {
    "id": "4",
    "name": "PATH"
  },
  {
    "id": "5",
    "name": "DELETE"
  },
  {
    "id": "6",
    "name": "OPTIONS"
  },
  {
    "id": "7",
    "name": "HEAD"
  },
] as MethodProps[]