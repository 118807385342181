import SignRoutes from "./SignRoutes"
import OtherRoutes from "./OtherRoutes"
import { useAuth } from "../hooks/useAuth"
import { CoreProvider } from "../contexts/coreContext"

export default function AppRoutes(): JSX.Element {
	const { signed, user } = useAuth()

	return signed && user?.first_login === '0'
			? <CoreProvider>
					<OtherRoutes />
				</CoreProvider>
			: <SignRoutes/>
}