import List from "@mui/material/List"
import Input from "@mui/material/Input"
import Avatar from "@mui/material/Avatar"
import * as api from '../../../services/api'
import ListItem from "@mui/material/ListItem"
import { useCore } from "../../../hooks/useCore"
import { LooseObject } from "../../../types/core"
import IconButton from "@mui/material/IconButton"
import InputLabel from "@mui/material/InputLabel"
import DeleteIcon from '@mui/icons-material/Delete'
import SearchIcon from '@mui/icons-material/Search'
import FormControl from "@mui/material/FormControl"
import ListItemText from "@mui/material/ListItemText"
import { toast, ToastContainer } from 'react-toastify'
import ListItemButton from "@mui/material/ListItemButton"
import InputAdornment from "@mui/material/InputAdornment"
import ListItemAvatar from "@mui/material/ListItemAvatar"
import ModalDelete from "../../../components/ModalDelete"
import { Paginator } from "../../../components/Paginator"
import { useEffect, useLayoutEffect, useState } from "react"
import { LoadingPage } from "../../../components/LoadingPage"
import { ListUserProps, UserProps } from "../../../types/user"
import AccountCircleIcon from '@mui/icons-material/AccountCircle'
import { useParams, useLocation, useNavigate } from "react-router-dom"

export default function Users(): JSX.Element {
  const navigate = useNavigate()
  const location: LooseObject = useLocation()
  let { id } = useParams()
  const { setTitleBar, setPathTitleBar, setPathData } = useCore()

  const [users, setUsers] = useState<UserProps[] | undefined>([])
  const [usersSearch, setUsersSearch] = useState<UserProps[] | undefined>([])
  const [open, setOpen] = useState<boolean>(false)
  const [idDelete, setIdDelete] = useState<string | undefined>()
  const [searchValue, setSearchValue] = useState<string>('')
  const [loading, setLoading] = useState<boolean>(true)
  const [count, setCount] = useState<number>(0)
  const [pageNumber, setPageNumber] = useState<number>(1)

  const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
    setLoading(true)
    setPageNumber(value)
    await getUsers(`user/list/${id}/?page=${value}`)
  }

  const getUsers = async (url: string) => {
    const response = await api.get(url) as ListUserProps
    setCount(response.content.count)
    setUsers(response.content.results)
    setUsersSearch(response.content.results)
    setLoading(false)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
		let value = e.target.value
    search(value)
    setSearchValue(value)
	}

  function search(value: string) {
    setUsersSearch(
      users?.filter((item) => item.first_name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.last_name.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.email.toLowerCase().indexOf(value.toLowerCase()) > -1)
    )
  }

  function handleModalOpen(id: string) {
    setIdDelete(id)
    setOpen(true)
  }

  const handleModalClose = () => setOpen(false);

  const handleDelete = async () => {
    setLoading(true)
    const response = await api.destroy(`user/destroy/${idDelete}/`) as {[key:string]: any}
    if (response.status === 204) {
      let indexCompanies = users?.findIndex((item, i) => item.id === idDelete)
      users?.splice(indexCompanies!, 1)
      search(searchValue)
      toast.success("Usuário excluido com sucesso!", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      toast.error("Falha ao tentar excluir usuário!", {
			  position: toast.POSITION.TOP_RIGHT
		  })
    }
    handleModalClose()
    setLoading(false)
  }

  useEffect(() => {
    getUsers(`user/list/${id}/`).catch(console.error)
	}, [id, setUsers, setUsersSearch])

  useLayoutEffect(() => {
    setTitleBar(`Usuários da empresa ${location.state.fantasy_name}`)
    setPathTitleBar(`/new-user/${id}`)
    setPathData({fantasy_name: location.state.fantasy_name})
  }, [id, location.state.fantasy_name, setPathData, setPathTitleBar, setTitleBar])

	return (
		<>
      <ToastContainer />
      <ModalDelete open={open} textBody="Tem certeza que deseja excluir esse usuário?" handleModalClose={handleModalClose} handleDelete={handleDelete} loading={loading} />
			{
        !loading
          ? <>
              <List dense disablePadding sx={{ width: '100%' }}>
                <ListItem 
                  key='search' 
                  disablePadding
                >
                  <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                    <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                    <Input
                      id="input-search"
                      startAdornment={
                        <InputAdornment position="start">
                          <SearchIcon />
                        </InputAdornment>
                      }
                      onChange={handleChangeSearch}
                    />
                  </FormControl>
                </ListItem>
                {usersSearch?.map((item) => (
                  <ListItem 
                    key={item.id} 
                    disablePadding 
                    secondaryAction={
                      <IconButton edge="end" aria-label="delete" onClick={() => {handleModalOpen(item.id)}}>
                        <DeleteIcon />
                      </IconButton>
                    }
                  >
                    <ListItemButton onClick={() => navigate(`/user/${item.id}`)}>
                      <ListItemAvatar>
                        <Avatar>
                          <AccountCircleIcon />
                        </Avatar>
                      </ListItemAvatar>
                      <ListItemText primary={`${item.first_name} ${item.last_name}`} secondary={item.email} />
                    </ListItemButton>
                  </ListItem>
                ))}
              </List>
              <Paginator count={count} pageNumber={pageNumber} onChange={handleChangePage} />
            </>
        : <LoadingPage />
      }
		</>
	)
}