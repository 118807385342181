import { DarkMode } from "./darkMode"
import { LightMode } from "./lightMode"
import { cookies } from "../hooks/useCookies"
import { createGlobalStyle } from "styled-components"

function getTextColor() {
	if (cookies.get('@CheckView:themeMode') === 'dark') {
		return DarkMode.text.primary
	}
	return LightMode.text.primary
}

const GlobalStyles = createGlobalStyle`
	@page {
		size: A4;
		margin: .2cm;
		-webkit-print-color-adjust: exact;
	}
	@media print {
		html, body {
			font-size: .8rem;
			overflow: hidden;
			margin: 0;
			padding: 0;
		}
		#report {
			position: absolute;
      height: auto;
      overflow-y: visible;
      box-shadow: none;
			margin-bottom: 20px;
		}
		#chip-status {
			display: none;
		}
		#print-logo {
			display: flex!important;
		}
		small {
			font-size: .5rem;
		}
		.li-report {
			font-size: .8rem;
		}
		.title {
			font-size: 1rem;
		}
	
		.subtitle {
			font-size: .9rem;
		}
	}

	html, body {
		margin: 0;
		padding: 0;
		padding-bottom: 0;
		height: 100%;
		overflow-x: hidden;
	}
	body, textarea, button {
		font-family: 'Heebo', sans-serif;
		font-weight: 400;
	}
	input, label {
		font-family: 'Heebo', sans-serif;
		font-weight: 400;
	}
	input {
		font-size: 16px;
		width: 100%;
		height: 30px;
		padding-left: 10px;
	}
	input:focus {
		outline: none;
	}
	a {
		text-decoration: none;
	}
	ul {
		list-style: none;
	}
	Button {
		text-transform: uppercase;
	}
	a {
		text-decoration: none!important;
		color: ${getTextColor};
	}
	.w-100 {
		width: 100%;
	}
	& .MuiOutlinedInput-root::placeholder {
		color: red!important;
	}
	/*---------- TABLES ----------*/
	table {
		width: 100%;
		margin: 0 auto;
		table-layout:fixed;
		border: none;
		border-collapse: separate;
		border-spacing: 0;
	}

	thead th, thead td, tbody th, tbody td {
		border: none;
		padding: .5em;
		font-size: 0.8rem;
	}

	thead th {
		background-color: #a091b5;
		color: #ffffff;
		font-weight: 700;
		text-align: left;
		position: sticky;
		top: 0;
	}

	.report thead th {
		text-transform: uppercase;
	}

	tbody tr {
		color: #424242;
		background-color: #e6e6e6;
		word-wrap: break-word;
		padding: 20px 10px;
	}

	tbody tr:nth-child(odd) {
		background-color: #ffffff;
	}

	.input-container {
		position: relative;
		background-color: #eeeeee;
	}
	.input-custom {
    letter-spacing: inherit;
    border: 0;
    box-sizing: content-box;
    background: none;
    height: 1.4375em;
    margin: 0;
    -webkit-tap-highlight-color: transparent;
    display: block;
    min-width: 0;
    width: 100%;
    -webkit-animation-duration: 10ms;
    animation-duration: 10ms;
    padding: 16.5px 14px;
	}
	.input-custom {
		border: 1px solid #9e9e9e;
		border-radius: 4px;
	}
	.input-custom:hover {
		border: 1px solid #424242;
	}
	.label {
		position: absolute;
		color: #9e9e9e;
		top: 0;
		bottom: 0;
		left: 12px;
		display: flex;
		align-items: center;
		pointer-events: none;
	}
	.input-custom:focus {
		outline: none;
		border: 2px solid #ffa890;
	}
	.input-custom + .label {
		left: 12px;
	}
	.input-custom:focus + .label {
		left: 9px;
	}
	.input-custom:focus + .label .text, :not(.input-custom[value=""]) + .label .text {
		font-size: 12px;
		transform: translate(0, -160%);
		-webkit-transform: translate(0, -160%);
		background-color: #eeeeee;
		padding-left: 4px;
		padding-right: 4px;
	}
	.input-custom:focus + .label .text {
		color: #ffa890;
	}
	.label .text {
  	transition: all 0.15s ease-out;
		-webkit-transition: all 0.15s ease-out;
	}
	#print-logo {
		display: none;
	}

	#ul-report {
		padding: 0;
		margin: 0;
		background-color: #eeeeee;
		border-radius: 20px;
	}

	.li-report {
		padding: 10px 20px;
		display: flex;
		justify-content: space-between;
		border: #ece4f7 solid 2px;
		font-size: .8rem;

		strong {
			padding-right: 70px;
		}

		:nth-child(even) {
			background-color: #ece4f7;
		}

		:nth-child(odd) {
			background-color: #fafafa;
		}
	}

	.title {
		background-color: #9454a4!important;
		color: #ffffff;
		justify-content: center;
		font-size: 1rem;

		strong {
			padding-right: 0;
		}
	}
	
	.subtitle {
		background-color: #a091b5!important;
		color: #ffffff;
		justify-content: center;
		font-size: .9rem;
		
		strong {
			padding-right: 0;
		}
	}
	.label-input-file {
		padding: 15px 25px;
		cursor: pointer;
		display: flex;
		gap: 6px;
		justify-items: center;
		transition: all 1s;
		border-radius: 8px;
	}
	.label-input-file-default {
		color: #424242;
		border: #ffa890 dashed 2px;
	}
	.label-input-file-success {
		background-color: #f2ded8;
		color: #424242;
		border: #ffa890 dashed 2px;
	}

	/* ===== Scrollbar CSS ===== */
	/* Firefox */
	* {
	scrollbar-width: thin;
	scrollbar-color: #dedede #ffffff;
	}

	/* Chrome, Edge, and Safari */
	*::-webkit-scrollbar {
	width: 10px;
	}

	*::-webkit-scrollbar-track {
	background: #ffffff;
	}

	*::-webkit-scrollbar-thumb {
	background-color: #dedede;
	border-radius: 10px;
	border: 3px solid #ffffff;
	}
	/* ================================================== */
	`;

export default GlobalStyles;