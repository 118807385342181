import { useFormik } from 'formik';
import { v4 as uuidv4 } from 'uuid';
import { StyledForm } from './styles';
import { toast } from 'react-toastify';
import * as api from '../../services/api';
import { IProducts } from "../Documentation";
import { useCore } from "../../hooks/useCore";
import { LooseObject } from '../../types/core';
import { useNavigate } from 'react-router-dom';
import ImageIcon from '@mui/icons-material/Image';
import { AiFillCloseCircle } from 'react-icons/ai';
import { useEffect, useReducer, useState } from "react";
import { handleFileRead, returnT } from '../../utils/get-base64';
import { inputsNameObject } from '../../types/checkMatch';
import { checkMatchPost } from '../../services/checkMatch';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { ListParameter, ParameterProps } from '../../types/parameter';
import { inputValueType, notTextOrNumber } from '../Api/CreateOrUpdateApi/value_type';
import { Select, FormControl, TextField, MenuItem, InputLabel, Button, Grid, Alert, FormGroup, FormControlLabel, Checkbox, ListSubheader } from '@mui/material';

function reducer(state: any, action: any) {
    switch (action.type) {
        case 'SET_ORIGINAL_PARAMETERS':
            return { ...state, originalParameters: action.payload };
        case 'SET_PARAMETERS':
            return { ...state, parameters: action.payload };
        case 'SET_INITIAL_VALUES':
            return { ...state, initialValues: action.payload };
        default:
            throw new Error('Invalid action type');
    }
}

type initialStateProps = {
    initialValues: LooseObject
    parameters: ParameterProps[]
    originalParameters: ParameterProps[]
}

const returnSwitch = (category: string) => {
    switch (category) {
        case '1':
            return 'Veiculares'
        case '2':
            return 'Cadastrais PF'
        case '3':
            return 'Cadastrais PJ'
        case '4':
            return 'Validações'
    }
}

export default function CheckMatch() {

    const initialState: initialStateProps = {
        originalParameters: [],
        parameters: [],
        initialValues: {
            rescue: false
        }
    }

    const navigate = useNavigate()

    const { setTitleBar, setPathTitleBar } = useCore();
    const [valuesToSearch, setValuesToSearch] = useState({});
    const [buttonDisable, setButtonDisable] = useState(false);
    const [state, dispatch] = useReducer(reducer, initialState);
    const [optionsValue, setOptionsValue] = useState<number[]>([]);
    const [valuesTwo, setValuesTwo] = useState([{ id: uuidv4() }]);
    const [products, setProducts] = useState<IProducts[] | []>([]);
    const [productSelected, setProductSelected] = useState<string>();
    const [inputsName, setInputsName] = useState<inputsNameObject[]>([]);

    const getProducts = () => {
        api.get("product/list/all/").then((response: any) => {
            setProducts(response?.content);
        });
    };

    const getParamns = async (data: Array<IProducts>) => {
        let dataFormated = {
            products_id: data.map(item => item.id)
        }
        let parametersDescription: string[] = []
        const response = await api.patch('product/list/parameters/', dataFormated) as { [key: string]: any }
        const parametersResponse = response.data as ListParameter
        dispatch({ type: 'SET_ORIGINAL_PARAMETERS', payload: parametersResponse.content })

        let _initialValues: LooseObject = {
            "rescue": false
        }

        let _parameters: ParameterProps[] = []

        parametersResponse.content.forEach((item) => {
            _initialValues[`${item.name}|${item.api_id}`] = ''
            if (parametersDescription.indexOf(item.description) === -1) {
                parametersDescription.push(item.description)
                _parameters.push(item)
            }
        })

        dispatch({ type: 'SET_PARAMETERS', payload: _parameters })
        dispatch({ type: 'SET_INITIAL_VALUES', payload: _initialValues })
    }

    const { handleChange, handleSubmit, values, setFieldValue, resetForm } = useFormik({
        initialValues: state.initialValues,
        onSubmit: submit
    })

    function submit(e: any) {
        let dataParam = { ...e }
        let dataFormated: any = { ...valuesToSearch }
        delete dataParam.rescue

        Object.keys(dataParam).forEach(item => {
            delete dataFormated[item]
        })

        let apartValues = Object.keys(dataFormated).filter(item => item.includes('value'))

        let valuesData: { value: string; accuracy: number; }[] = []

        apartValues.forEach(item => {
            let currentId = item.split('|')[1]
            valuesData.push({ value: dataFormated[item], accuracy: dataFormated[`accuracy|${currentId}`] })
        })

        let paramnsData: { param: string; value: string; }[] = []

        Object.keys(dataParam).forEach(item => {
            const name = item.split('|')[0]
            paramnsData.push({ param: name, value: dataParam[item] })
        })

        const toSend = {
                    "values": valuesData,
                    "product": {
                        "id": productSelected,
                        "params": paramnsData
                    }
                }

        checkMatchPost('match/create/', toSend).then((response:any) => {
            if(response.status === 200) {
                toast.dismiss()
                toast.success('Consulta realizada com sucesso!')
                navigate(`/query/${response.data.content.response?.checkview_id}`)
            } else {
                toast.dismiss()
                toast.error('Ocorreu um erro ao realizar o match, tente novamente mais tarde!')
                setButtonDisable(false)
            }
        })

        return
    };

    useEffect(() => {
        getProducts();
        setPathTitleBar(undefined);
        setTitleBar("Check Match");
        (() => {

            let i = 1
            let temporaryOptions: number[] = []

            for (i; i <= 10; i++) {
                temporaryOptions = [...temporaryOptions, i * 10]
            }

            setOptionsValue(temporaryOptions)
        })()
    }, [setPathTitleBar, setTitleBar])

    return (
        <StyledForm
            onSubmit={(e) => {
                setButtonDisable(true)
                toast.loading('Aguardando retorno do match')
                //@ts-ignore
                const formData = new FormData(e.target);
                let formProps = Object.fromEntries(formData);
                setValuesToSearch(formProps)
                handleSubmit(e)
            }}
        >
            <FormControl style={{ width: '100%' }}>
                <InputLabel id="month-label">Escolha um produto</InputLabel>
                <Select
                    fullWidth
                    id="tags-outlined"
                    onChange={(e) => {
                        resetForm()
                        setProductSelected(products.filter(item => item.id === e.target.value)[0].id)
                        getParamns(products.filter(item => item.id === e.target.value));
                    }}
                    label="Escolha um produto"
                    placeholder='Escolha um produto'
                >
                    <ListSubheader >{returnSwitch('1')}</ListSubheader>
                    {
                        products.map(item2 => (
                            item2.category === '1'
                            && <MenuItem
                                key={item2?.id}
                                value={item2.id}
                            >
                                {item2?.name}
                            </MenuItem>
                        ))
                    }
                    <ListSubheader >{returnSwitch('2')}</ListSubheader>
                    {
                        products.map(item2 => (
                            item2.category === '2'
                            && <MenuItem
                                key={item2?.id}
                                value={item2.id}
                            >
                                {item2?.name}
                            </MenuItem>
                        ))
                    }
                    <ListSubheader >{returnSwitch('3')}</ListSubheader>
                    {
                        products.map(item2 => (
                            item2.category === '3'
                            && <MenuItem
                                key={item2?.id}
                                value={item2.id}
                            >
                                {item2?.name}
                            </MenuItem>
                        ))
                    }
                    <ListSubheader >{returnSwitch('4')}</ListSubheader>
                    {
                        products.map(item2 => (
                            item2.category === '4'
                            && <MenuItem
                                key={item2?.id}
                                value={item2.id}
                            >
                                {item2?.name}
                            </MenuItem>
                        ))
                    }
                </Select>
            </FormControl>
            {
                state.parameters.length > 0
                    ? <Grid item xs={12}>
                        <Alert severity="info" sx={{alignItems: 'center'}}>
                            <strong>
                                Valores à serem consultados:
                            </strong>
                        </Alert>
                    </Grid>
                    : <></>
            }
            {
                state.parameters.map((parameter: ParameterProps) => {
                    return (
                        <Grid
                            width={'100%'}
                            key={`parameter-${parameter.name}-${parameter.api_id}`}
                        >
                            {
                                notTextOrNumber.indexOf(parameter.value_type) === -1
                                    ? <TextField
                                        fullWidth
                                        label={parameter.description}
                                        type={inputValueType[parameter.value_type]}
                                        required={parameter.required}
                                        name={`${parameter.name}|${parameter.api_id}`}
                                        onChange={handleChange}
                                    />
                                    : parameter.value_type === '3'
                                        ? <FormGroup>
                                            <FormControlLabel
                                                control={
                                                    <Checkbox
                                                        name={`${parameter.name}|${parameter.api_id}`}
                                                        onChange={(e) => {
                                                            if (e.target.checked) {
                                                                setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_value)
                                                            } else {
                                                                setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_default)
                                                            }
                                                        }}
                                                    />
                                                }
                                                label={parameter.description}
                                            />
                                        </FormGroup>
                                        : <FormGroup>
                                            {
                                                parameter.value_type === '4'
                                                    ? <label
                                                        className={
                                                            `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                        }
                                                        htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                    >
                                                        <ImageIcon /> {
                                                            values[`${parameter.name}|${parameter.api_id}`]
                                                                ? inputsName.filter((item) => item.id === `${parameter.name}|${parameter.api_id}`)[0].fileName
                                                                : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                        }
                                                        <input
                                                            style={{ display: "none" }}
                                                            id={`${parameter.name}|${parameter.api_id}`}
                                                            type="file"
                                                            name={parameter.name}
                                                            onChange={async (e) => {
                                                                if (e.target.files) {
                                                                    let base64: returnT = await handleFileRead(e) as returnT
                                                                    var novaArr = inputsName.filter((este, i) => inputsName.indexOf(este) === i)
                                                                    if(base64?.filename) {
                                                                        setInputsName([...novaArr, {id: `${parameter?.name}|${parameter?.api_id}`, fileName: base64?.filename}])
                                                                        setFieldValue(`${parameter?.name}|${parameter?.api_id}`, base64?.file_data)
                                                                    }
                                                                } else {
                                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                }
                                                            }}
                                                            accept='image/*'
                                                        />
                                                    </label>
                                                    : parameter.value_type === '5'
                                                        ? <label
                                                            className={
                                                                `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                            }
                                                            htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                        >
                                                            <PictureAsPdfIcon /> {
                                                                values[`${parameter.name}|${parameter.api_id}`]
                                                                    ? inputsName.filter((item) => item.id === `${parameter.name}|${parameter.api_id}`)[0].fileName
                                                                    : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                            }
                                                            <input
                                                                style={{ display: "none" }}
                                                                id={`${parameter.name}|${parameter.api_id}`}
                                                                type="file"
                                                                name={parameter.name}
                                                                onChange={async (e) => {
                                                                    if (e.target.files) {
                                                                        let base64: returnT = await handleFileRead(e) as returnT
                                                                        var novaArr = inputsName.filter((este, i) => inputsName.indexOf(este) === i)
                                                                        if(base64?.filename) {
                                                                            setInputsName([...novaArr, {id: `${parameter?.name}|${parameter?.api_id}`, fileName: base64?.filename}])
                                                                            setFieldValue(`${parameter?.name}|${parameter?.api_id}`, base64?.file_data)
                                                                        }
                                                                    } else {
                                                                        setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                    }
                                                                }}
                                                                accept='application/pdf'
                                                            />
                                                        </label>
                                                        : <label
                                                            className={
                                                                `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                            }
                                                            htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                        >
                                                            <UploadFileIcon /> {
                                                                values[`${parameter.name}|${parameter.api_id}`]
                                                                    ? inputsName.filter((item) => item.id === `${parameter.name}|${parameter.api_id}`)[0].fileName
                                                                    : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                            }
                                                            <input
                                                                style={{ display: "none" }}
                                                                id={`${parameter.name}|${parameter.api_id}`}
                                                                type="file"
                                                                name={parameter.name}
                                                                onChange={async (e) => {
                                                                    if (e.target.files) {
                                                                        let base64: returnT = await handleFileRead(e) as returnT
                                                                        var novaArr = inputsName.filter((este, i) => inputsName.indexOf(este) === i)
                                                                        if(base64?.filename) {
                                                                            setInputsName([...novaArr, {id: `${parameter?.name}|${parameter?.api_id}`, fileName: base64?.filename}])
                                                                            setFieldValue(`${parameter?.name}|${parameter?.api_id}`, base64?.file_data)
                                                                        }
                                                                    } else {
                                                                        setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                    }
                                                                }}
                                                            />
                                                        </label>
                                            }
                                        </FormGroup>
                            }
                        </Grid>
                    )
                })
            }
            <Grid item md={12} xs={12}>
                <Alert severity="info">
                    <strong>
                        Cadastrar valores à serem buscados no resultado:
                    </strong>
                </Alert>
            </Grid>
            {
                valuesTwo.map(item => (
                    <div
                        style={
                            {
                                width: '100%',
                                display: 'flex',
                                gap: '10px'
                            }
                        }
                        id={item.id}
                        key={item.id}
                    >
                        <TextField
                            type={'text'}
                            required={true}
                            style={{ width: '80%' }}
                            name={`value|${item.id}`}
                            label={'Valor a ser procurado'}
                        // onChange={handleChange}
                        />
                        <FormControl style={{ width: '20%' }}>
                            <InputLabel id="month-label">Acuracia</InputLabel>
                            <Select
                                required={true}
                                label={'Acuracia'}
                                defaultValue={70}
                                name={`accuracy|${item.id}`}
                            >
                                {
                                    optionsValue.map((item, index) => (
                                        <MenuItem
                                            key={index}
                                            value={item}
                                        >
                                            {item}%
                                        </MenuItem>
                                    ))
                                }
                            </Select>
                        </FormControl>
                        <Button
                            size="large"
                            variant="contained"
                            style={{ display: valuesTwo.length > 1 ? 'flex' : 'none' }}
                            onClick={() => {
                                setValuesTwo(valuesTwo.filter(item2 => item.id !== item2.id))
                            }}
                        >
                            <AiFillCloseCircle size={25} />
                        </Button>
                    </div>
                ))
            }
            <Button
                fullWidth
                size="large"
                variant="outlined"
                onClick={() => {
                    setValuesTwo([...valuesTwo, { id: uuidv4() }])
                }}
            >
                Adicionar novo valor
            </Button>
            <Button
                fullWidth
                size="large"
                type='submit'
                variant="contained"
                disabled={state.parameters.length === 0 || buttonDisable}
            >
                Fazer consulta com filtro
            </Button>
        </StyledForm>
    )
}