import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify'
import Button from "@mui/material/Button"
import * as api from '../../../services/api'
import { useParams } from "react-router-dom"
import { MaskCNPJ } from "../../../utils/mask"
import TextField from '@mui/material/TextField'
import { ToastContainer } from "react-toastify"
import { useCore } from "../../../hooks/useCore"
import { LoadingPage } from "../../../components/LoadingPage"
import { RetrieveProviderProps } from '../../../types/provider'
import { useCallback, useEffect, useLayoutEffect, useState } from 'react'

export default function CreateOrUpdateProvider(): JSX.Element {
  let { id } = useParams()
  const { setTitleBar, setPathTitleBar } = useCore()

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } =  useFormik({
    initialValues: {
      corporate_name: '',
      state_registration: '',
      cnpj: '',
      fantasy_name: ''
    },
    onSubmit: createOrUpdateProvider,
  })

  const [loading, setLoading] = useState<boolean>(false)

  const getProvider = useCallback(async () => {
    setLoading(true)
    const response = await api.get(`provider/retrieve/${id}/`) as RetrieveProviderProps
    setFieldValue('corporate_name', response.content.corporate_name)
    setFieldValue('state_registration', response.content.state_registration ?? '')
    setFieldValue('cnpj', response.content.cnpj)
    setFieldValue('fantasy_name', response.content.fantasy_name)
    setLoading(false)
  }, [id, setFieldValue])

  async function createOrUpdateProvider() {
    if (id) {
      toast.loading("Atualizando...", {
        position: toast.POSITION.TOP_RIGHT,
      })
  
      var resUpdated = await api.put(`provider/update/${id}/`, values) as {[key:string]: any}
      
      toast.dismiss()
      
      if (resUpdated.status === 200) {
        toast.success("Dados atualizados com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.loading("Criando...", {
        position: toast.POSITION.TOP_RIGHT,
      })
  
      var resCreated = await api.post('provider/create/', values) as {[key:string]: any}
  
      toast.dismiss()
  
      if (resCreated.status === 201) {
        toast.success("Fornecedor cadastrado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }
  
  useEffect(() => {
    if (id) getProvider().catch(console.error)
	}, [getProvider, id])

  useEffect(() => {
    if (id) {
      setTitleBar(`Fornecedor - ${values.fantasy_name}`)
    } else {
      setTitleBar('Novo Fornecedor')
    }
  }, [setTitleBar, values.fantasy_name, id])

  useLayoutEffect(() => {
    setPathTitleBar(undefined)
  }, [setPathTitleBar])

	return (
		<>
      <ToastContainer />
      {
        !loading
          ? <form onSubmit={handleSubmit}>
              <Grid 
                container 
                spacing={2} 
                alignItems="center"
                justifyContent="center">
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="corporate_name"
                    name="corporate_name"
                    label="Razão social"
                    value={values.corporate_name}
                    onChange={handleChange}
                    error={touched.corporate_name && Boolean(errors.corporate_name)}
                    helperText={touched.corporate_name && errors.corporate_name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    id="state_registration"
                    name="state_registration"
                    label="Registro estadual"
                    value={values.state_registration}
                    onChange={handleChange}
                    error={touched.state_registration && Boolean(errors.state_registration)}
                    helperText={touched.state_registration && errors.state_registration}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="cnpj"
                    name="cnpj"
                    label="CNPJ"
                    value={values.cnpj}
                    onChange={handleChange}
                    InputProps={{ inputComponent: MaskCNPJ as any }}
                    error={touched.cnpj && Boolean(errors.cnpj)}
                    helperText={touched.cnpj && errors.cnpj}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="fantasy_name"
                    name="fantasy_name"
                    label="Nome fantasia"
                    value={values.fantasy_name}
                    onChange={handleChange}
                    error={touched.fantasy_name && Boolean(errors.fantasy_name)}
                    helperText={touched.fantasy_name && errors.fantasy_name}
                  />
                </Grid>
                <Grid 
                  item 
                  alignItems="center" 
                  alignContent="center">
                  <Button variant="contained" size="large" color="success" type="submit">
                    { id ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          : <LoadingPage />
      }
		</>
	)
}