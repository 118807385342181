type LocalParameterProps = {
  id: string
  name: string
}

export const LocalParameter = [
  {
    "id": "1",
    "name": "HEADER"
  },
  {
    "id": "2",
    "name": "PAYLOAD"
  },
  {
    "id": "3",
    "name": "URL"
  },
] as LocalParameterProps[]

export const dysplayLocal = {
  '1': 'HEADER',
  '2': 'PAYLOAD',
  '3': 'URL'
}