import React from 'react';
import { TypeEditField } from '../../../types/onboarding'
import * as Field from './editField'

export default function EditFieldOptions({ open, setOpen, refKey, type, onSubmit }: TypeEditField) {

    const EditField = () => {
        switch (type) {
            case 0:
                return <Field.EditFieldQuestion open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 1:
                return <Field.EditFieldAttachments open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 2:
                return <Field.EditFieldAttachments open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 3:
                return <Field.EditFielDate open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 4:
                return <Field.EditFielRadioOptions open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 5:
                return <Field.EditFielSelectOptions open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 6:
                return <Field.EditFielCheckBoxOptions open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            case 7:
                return <Field.EditFieldSection open={open} setOpen={setOpen} refKey={refKey} onSubmit={onSubmit} />
            default:
                return null;
        }
    }

    return EditField();

}