import React, { useState, useLayoutEffect, useEffect } from "react";
import { useNavigate } from 'react-router-dom';
import * as api from '../../../services/api'
import { APIOnboarding } from '../../../services/api'
import { useParams } from "react-router-dom"
import { useAuth } from "../../../hooks/useAuth"
import { useCore } from "../../../hooks/useCore";

import ProtectRoutesOnboarding from '../index'

import { choicesFields, optionsFields, optionsAttachments, optionSection } from '../ChoicesFields/choices';

import { Paper, Grid, Box, Typography, TextField, Button, InputAdornment, Tooltip } from "@mui/material";

import InfoIcon from "@mui/icons-material/Info";

import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft';

import { toast, ToastContainer } from "react-toastify";

type ResponseQuestion = {
    label: string;
    type?: string;
    options: string[];
    value: string[];
    required: boolean;
    position: number;
};

type SignatoryData = {
    id?: string;
    id_choice?: string;
    label: string;
    type: string;
    value?: string;
};

type ResultItem = {
    id: string;
    onboarding_id: string;
    layout: {
        name_model: string;
        title_onboarding: string;
    }
    data: SignatoryData[];
    questions: ResponseQuestion[];
    attachments: ResponseQuestion[];
    combinedItems: any;
}

type ApiResponse = {
    content: {
        results: ResultItem[];
        data: SignatoryData[];
        questions: ResponseQuestion[];
        attachments: ResponseQuestion[];
    };
};

type FieldData = 'nome' | 'cpf_cnpj' | 'email' | 'whatsapp' | 'numero_processo';

export default function EditSignatoryContact() {

    const { user } = useAuth()

    const navigate = useNavigate();

    const { setTitleBar, setPathTitleBar } = useCore();
    const { id_signatory } = useParams();

    const [resultSignatory, setResultSignatory] = useState<ApiResponse | null>(null);

    const [fieldValues, setFieldValues] = useState<{ [key: string]: string }>({
        'nome': '',
        'cpf_cnpj': '',
        'email': '',
        'whatsapp': '',
        'numero_processo': ''
    });

    const [editedFields, setEditedFields] = useState<{ [key: string]: boolean }>({
        'nome': false,
        'cpf_cnpj': false,
        'email': false,
        'whatsapp': false,
        'numero_processo': false
    });

    const [errorCpfCnpj, setErrorCpfCnpj] = useState<string>('');

    useLayoutEffect(() => {
        setTitleBar("Onboarding");
        setPathTitleBar("");
    }, [setPathTitleBar, setTitleBar]);


    useEffect(() => {
        const companyId = user?.corporate_id!

        const fetchData = async () => {
            const getToken = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any }
            const tokenOnboardingApi = getToken.content.token_onboarding;

            try {
                const response = await APIOnboarding.get(`onboarding/signatory/list/?id=${id_signatory}&token=${tokenOnboardingApi}`);

                if (response.status === 200) {

                    const orderdData = {
                        ...response.data,
                        content: {
                            ...response.data.content,
                            results: response.data.content.results.map((item: ResultItem) => {
    
                                const orderedResponseQuestions = item.questions.map(question => ({
                                    ...question,
                                    type_question: 'question',
                                    position: question.position
                                }));
    
                                const orderedResponseAttachments = item.attachments.map(attachment => ({
                                    ...attachment,
                                    type_question: 'attachment',
                                    position: attachment.position
                                }));
    
                                const combinedItems = [
                                    ...orderedResponseQuestions,
                                    ...orderedResponseAttachments
                                ].sort((a, b) => a.position - b.position);
    
                                return {
                                    ...item,
                                    combinedItems,
                                };
    
                            }),
                        }
                    };

                    setResultSignatory(orderdData);
                }

            } catch (error) {
                toast.error('Erro ao listar informações!!!');
            }
        };

        fetchData();
    }, [id_signatory]);

    if (!resultSignatory) return null

    const getNameModel = resultSignatory.content.results.map(item => (item.layout.name_model))
    const getTitleModel = resultSignatory.content.results.map(item => (item.layout.title_onboarding))

    const fieldsValuesLabel = resultSignatory.content.results.reduce((acc: Record<string, string>, item: ResultItem) => {
        item.data.forEach((field) => {
            acc[field.label] = field.value || "";
        });
        return acc;
    }, {});


    const checkCpfCnpj = (value: string) => {

        const regex = /^\d*$/;
        const verifyCpfCnpj = regex.test(value)

        if (!verifyCpfCnpj) {
            setErrorCpfCnpj('Digite somente números');
            return false;
        }

        setErrorCpfCnpj('')
        return true;
    };

    const handleChange = (field: string) => (event: React.ChangeEvent<HTMLInputElement>) => {

        if (field === 'cpf_cnpj' && !checkCpfCnpj(event.target.value)) return false

        setFieldValues({
            ...fieldValues,
            [field]: event.target.value,
        });

        setEditedFields({
            ...editedFields,
            [field]: true,
        });
    };

    const updateDataSignatory = async () => {

        if (fieldValues['cpf_cnpj'].length < 11) {
            setErrorCpfCnpj('CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos');
            return false;
        }

        if (fieldValues['cpf_cnpj'].length > 14) {
            setErrorCpfCnpj('CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos');
            return false;
        }

        const valuesData = (Object.keys(fieldValues) as FieldData[]).map((key) => ({
            label: key,
            type: key === 'nome' ? '1' : '2',
            value: fieldValues[key] == '' ? fieldsValuesLabel[key] : fieldValues[key],
            validations: [],
        }))

        const payload = JSON.stringify({
            data: valuesData
        })

        const formData = new FormData();
        formData.append('content', payload);

        try {
            const response = await APIOnboarding.put(`onboarding/data/signatory/${id_signatory}/`, formData);

            if (response.status === 200) {
                toast.success('Dados atualizados com sucesso!!');
            } else {
                toast.error('Ops.. Tivemos um problema, por favor tente novamente mais tarde!')
            }

        } catch (error) {
            toast.error('Ops.. Tivemos um problema, por favor tente novamente mais tarde!');
        }
    }

    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            <Grid container sx={{ width: '100%', gap: 2 }}>

                <Grid item xs={12}>
                    <Paper sx={{ display: 'flex', flexDirection: 'column', gap: 2, backgroundColor: '#FFFFFF' }}>
                        <Box sx={{ display: 'flex', alignItems: 'center', padding: '24px' }}>
                            <Grid container spacing={2} sx={{ alignItems: 'center', justifyContent: 'center' }}>

                                <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                    <Button
                                        onClick={() => navigate(-1)}
                                        sx={{
                                            padding: 0,
                                            minWidth: 'auto',
                                            height: '56px',
                                            width: '35px',
                                            marginRight: 2,
                                            '&:hover': {
                                                backgroundColor: 'transparent'
                                            }
                                        }}
                                    >
                                        <KeyboardArrowLeftIcon
                                            sx={{
                                                backgroundColor: '#FFFFFF',
                                                border: '1px solid #D6D6D6',
                                                borderRadius: '12px',
                                                padding: '7px',
                                                height: '56px',
                                                width: '35px',
                                                color: 'black'
                                            }}
                                        />
                                    </Button>
                                    <TextField
                                        fullWidth
                                        label='Nome'
                                        value={editedFields['nome'] ? fieldValues['nome'] : fieldsValuesLabel['nome']}
                                        variant="outlined"
                                        inputProps={{ maxLength: 40 }}
                                        onChange={handleChange('nome')}
                                    />
                                </Grid>

                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        fullWidth
                                        label='CPF/CNPJ'
                                        value={editedFields['cpf_cnpj'] ? fieldValues['cpf_cnpj'] : fieldsValuesLabel['cpf_cnpj']}
                                        variant="outlined"
                                        inputProps={{ maxLength: 40 }}
                                        onChange={handleChange('cpf_cnpj')}
                                        error={!!errorCpfCnpj}
                                        helperText={errorCpfCnpj}
                                    />
                                </Grid>
                            </Grid>
                        </Box>

                        <Grid container spacing={2} sx={{ padding: '0 24px', marginTop: '-19px' }}>
                            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    value={fieldsValuesLabel['email']}
                                    placeholder='Digite o email do usuário'
                                    disabled
                                    variant="outlined"
                                    onChange={handleChange('email')}
                                    sx={{ flexGrow: 1, color: 'red' }}
                                    inputProps={{ maxLength: 0 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Email não pode ser alterado!!" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>

                            <Grid item xs={12} sm={6} sx={{ display: 'flex', alignItems: 'center' }}>
                                <TextField
                                    fullWidth
                                    value={fieldsValuesLabel['whatsapp']}
                                    placeholder='Digite o Whatsapp do usuário'
                                    disabled
                                    variant="outlined"
                                    onChange={handleChange('whatsapp')}
                                    sx={{ color: 'red' }}
                                    inputProps={{ maxLength: 0 }}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <Tooltip title="Número não pode ser alterado!!" placement="right">
                                                    <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                                </Tooltip>
                                            </InputAdornment>
                                        ),
                                    }}
                                />
                            </Grid>
                        </Grid>


                        <Box sx={{
                            display: 'flex',
                            justifyContent: 'flex-end',
                            alignItems: 'center',
                            width: '100%',
                            height: '100px',
                            marginTop: 2,
                            backgroundColor: '#F9FAFB',
                            paddingRight: '24px'
                        }}>
                            <Button
                                sx={{
                                    textTransform: 'none',
                                    color: 'white',
                                    padding: '10px 32px',
                                    height: '48px',
                                    borderRadius: '4px',
                                    fontSize: '16px'
                                }}
                                variant="contained"
                                onClick={updateDataSignatory}
                            >
                                Salvar alterações
                            </Button>
                        </Box>
                    </Paper>
                </Grid>

                <Grid item xs={12}>
                    <Paper
                        sx={{
                            padding: '20px',
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'space-between',
                            height: '100%',
                            backgroundColor: '#FFFFFF',
                            overflowY: resultSignatory.content.results.map(item => item.questions.length > 5 ? 'auto' : 'hidden')
                        }}
                    >
                        <Grid container>
                            <Grid item sx={{ display: 'flex', flexDirection: 'column', gap: 5, width: '100%', height: '100%' }}>
                                <Box
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        justifyContent: 'center',
                                        flexDirection: 'column',
                                        height: '100%',
                                        marginBottom: '15px',

                                    }}
                                >
                                    <Box>
                                        <Typography
                                            variant="h5"
                                            component="h3"
                                            sx={{ color: "secondary.main", textAlign: "center" }}
                                        >
                                            {getNameModel}
                                        </Typography>
                                        <Typography
                                            variant="h6"
                                            gutterBottom
                                            sx={{ textAlign: "center" }}
                                        >
                                            {getTitleModel}
                                        </Typography>

                                    </Box>
                                    {resultSignatory && (
                                        <>
                                            {resultSignatory.content.results
                                            .flatMap(item => item.combinedItems)
                                            .map((combinedItem, index) => {

                                                const option = 
                                                optionsFields.find(opt => opt.type === combinedItem.type && combinedItem.type_question === 'question') ||
                                                optionsAttachments.find(opt => opt.type === combinedItem.type);

                                                const section = optionSection.type === combinedItem.type ? optionSection : null!;

                                                if (option || section) {
                                                    return (
                                                        <Box
                                                            key={index}
                                                            sx={{
                                                                width: "100%",
                                                                margin: "20px 0 0 15px"
                                                            }}
                                                        >
                                                            {
                                                                combinedItem.type === '18' ? (
                                                                    choicesFields[(section.id_choice as any)].value((combinedItem as any))
                                                                ) : (
                                                                    <Typography>
                                                                        {option && choicesFields[(option.id_choice as any)].value((combinedItem as any))}
                                                                    </Typography>
                                                                )
                                                            }

                                                        </Box>
                                                    )
                                                }
                                            })}
                                        </>
                                    )}
                                </Box>
                            </Grid>
                        </Grid>
                    </Paper>
                </Grid>

            </Grid>
        </ProtectRoutesOnboarding>
    );
}
