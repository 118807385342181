import { useFormik } from 'formik'
import * as api from '../../services/api'
import { useAuth } from "../../hooks/useAuth"
import { useCore } from "../../hooks/useCore"
import { useNavigate } from "react-router-dom"
import { LooseObject } from "../../types/core"
import CheckIcon from '@mui/icons-material/Check'
import ImageIcon from '@mui/icons-material/Image'
import { BalanceProps } from "../../types/balance"
import { LightMode } from "../../styles/lightMode"
import { systemMessages } from "../../utils/messages"
import useWindowSize from "../../hooks/useWindowSize"
import { formatMoney } from "../../utils/format-money"
import { toast, ToastContainer } from "react-toastify"
import { handleFileRead } from "../../utils/get-base64"
import BusinessIcon from '@mui/icons-material/Business'
import ArrowBackIcon from '@mui/icons-material/ArrowBack'
import FactCheckIcon from '@mui/icons-material/FactCheck'
import { LoadingPage } from "../../components/LoadingPage"
import UploadFileIcon from '@mui/icons-material/UploadFile'
import ArrowForwardIcon from '@mui/icons-material/ArrowForward'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar'
import AssignmentIndIcon from '@mui/icons-material/AssignmentInd'
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import { ListParameter, ParameterProps } from "../../types/parameter"
import { ListAllProductProps, ProductProps } from "../../types/product"
import { useCallback, useEffect, useLayoutEffect, useReducer, useState } from "react"
import { inputValueType, notTextOrNumber } from "../Api/CreateOrUpdateApi/value_type"
import { Alert, Button, Card, CardContent, Checkbox, Chip, FormControlLabel, FormGroup, Grid, Stack, TextField, Tooltip, Typography } from "@mui/material"

type initialStateProps = {
  originalParameters: ParameterProps[]
  parameters: ParameterProps[]
  parametersRequired: ParameterProps[]
  initialValues: LooseObject
}

const initialState: initialStateProps = {
  originalParameters: [],
  parameters: [],
  parametersRequired: [],
  initialValues: {
    rescue: false
  }
}

function reducer(state: any, action: any) {
  switch (action.type) {
    case 'SET_ORIGINAL_PARAMETERS':
      return {...state, originalParameters: action.payload};
    case 'SET_PARAMETERS':
      return {...state, parameters: action.payload};
    case 'SET_INITIAL_VALUES':
      return {...state, initialValues: action.payload};
    case 'SET_PARAMETERS_REQUIRED':
      return {...state, parametersRequired: action.payload};
    default:
      throw new Error('Invalid action type');
  }
}

export default function Dashboard(): JSX.Element {
  const { user } = useAuth()
  const navigate = useNavigate()
  const { width } = useWindowSize()
  const { setTitleBar, setPathTitleBar } = useCore()

  const [rescueVisibility, setRescueVisibility] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)
  const [balance, setBalance] = useState<BalanceProps>()
  const [awaiting, setAwaiting] = useState<boolean>(false)
  const [state, dispatch] = useReducer(reducer, initialState)
  const [chipsProduct, setChipsProduct] = useState<ProductProps[]>([])
	const [vehicleConsultation, setVehicleConsultation] = useState<ProductProps[]>([])
	const [validationConsultation, setValidationConsultation] = useState<ProductProps[]>([])
	const [cadastralConsultationPf, setCadastralConsultationPf] = useState<ProductProps[]>([])
	const [cadastralConsultationPj, setCadastralConsultationPj] = useState<ProductProps[]>([])

  const [tokenOnboarding, setTokenOnboarding] = useState<string>("")

  const { handleChange, handleSubmit, values, setFieldValue } =  useFormik({
    initialValues: state.initialValues,
    onSubmit: createCall
  })

  async function createCall() {
    setAwaiting(true)
    
    toast.loading("Aguarde...", {
			position: toast.POSITION.TOP_RIGHT,
		})

    const data = {
      "products": chipsProduct.map((chip) => {
        return chip.id
      }),
      "rescue": values.rescue ?? false,
      "data": {}
    }

    let _parameters: LooseObject = {}

    state.originalParameters.forEach((originalItem: ParameterProps) => {
      state.parameters.forEach((item: ParameterProps) => {
        if (item.description === originalItem.description) {
          _parameters[`${originalItem.name}|${originalItem.api_id}`] = {
            'value': !values[`${item.name}|${item.api_id}`] && item.value_type === "3" ? item.boolean_default : values[`${item.name}|${item.api_id}`] ?? '',
          }
        }
      })
    })

    data.data = _parameters

    const response = await api.post('call/create/', data) as {[key: string]: any}

    setAwaiting(false)
    toast.dismiss()

    if (response.status === 201) {
        toast.success("Sucesso 😃! Aguarde mais um pouco, estamos redirecionando para você...", {
          position: toast.POSITION.TOP_RIGHT
        })
        await getOverBalance().catch(console.error)
        setTimeout(() => {
          return navigate(`/query/${response.data.content.id}`)
        }, 3000)
    } else if (response.status === 200) {
      toast.success("Sua consulta será processada, em instantes consulte na página de consultas", {
        position: toast.POSITION.TOP_RIGHT
      })
      await getOverBalance().catch(console.error)
      handleChangeStep(false)
    } else {
      toast.error(systemMessages.error, {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  }

	const getProducts = async () => {
    const response = await api.get('product/list/all/') as ListAllProductProps

    let vehicle_consultation = []
    let cadastral_consultation_pf = []
    let validation_consultation = []
    let cadastral_consultation_pj = []

    if (response.content.length > 0) {
      for (let i=0; i< response.content.length; i++) {
        if (response.content[i].category === "1") {
          vehicle_consultation.push(response.content[i])
        } else if (response.content[i].category === "2") {
          cadastral_consultation_pf.push(response.content[i])
        } else if (response.content[i].category === "3") {
          validation_consultation.push(response.content[i])
        } else {
          cadastral_consultation_pj.push(response.content[i])
        }
      }

      setVehicleConsultation(vehicle_consultation)
      setCadastralConsultationPf(cadastral_consultation_pf)
      setCadastralConsultationPj(cadastral_consultation_pj)
      setValidationConsultation(validation_consultation)
    }
    setLoading(false)
  }

  const getParameters = async (data: object) => {
    setLoading(true)
    let parametersDescription: string[] = []
    let parametersDescriptionRequired: string[] = []

    const response = await api.patch('product/list/parameters/', data) as {[key:string]: any}
    const parametersResponse = response.data as ListParameter
    dispatch({type: 'SET_ORIGINAL_PARAMETERS', payload: parametersResponse.content})

    let _initialValues: LooseObject = {
      "rescue": false
    }

    let _parameters: ParameterProps[] = []

    setRescueVisibility(true)

    // eslint-disable-next-line array-callback-return
    parametersResponse.content.map((item: ParameterProps) => {
      if (["4", "5", "6"].indexOf(item.value_type) > -1) setRescueVisibility(false)
      _initialValues[`${item.name}|${item.api_id}`] = ''
      if (parametersDescription.indexOf(item.description) === -1) {
        parametersDescription.push(item.description)
        _parameters.push(item)
      }
      if (parametersDescriptionRequired.indexOf(item.description) === -1) {
        if (item.required) parametersDescriptionRequired.push(item.description)
      }
    })

    dispatch({type: 'SET_PARAMETERS', payload: _parameters})
    dispatch({type: 'SET_INITIAL_VALUES', payload: _initialValues})
    dispatch({type: 'SET_PARAMETERS_REQUIRED', payload: parametersDescriptionRequired})
    setLoading(false)
  }

  const getOverBalance = useCallback(async () => {
    if (user?.corporate_id) {
      let date = new Date()
      await api.get(`company/info_balance/${user?.corporate_id}?month=${date.getMonth() + 1}&year=${date.getFullYear()}`).then((response: any) => {
        setBalance(response.content)
      })
    }
  }, [user?.corporate_id])

  const [step, setStep] = useState<boolean>(false)

  const handleChangeStep = async (value: boolean) => {
    setStep(value)

    if (value) {
      let productsId = chipsProduct.map((chip) => chip.id)
      await getParameters({"products_id": productsId})
    } else {
      dispatch({type: 'SET_INITIAL_VALUES', payload: {}})
      dispatch({type: 'SET_PARAMETERS', payload: []})
      for (const property in values) {
        delete values[property]
      }
    }
  }

  const handleAddOrDeleteChip = (chip: ProductProps) => () => {
    if (balance && parseFloat(balance.total_value_balance) <= parseFloat(balance.total_value_queries) + parseFloat(balance.total_value_processes)) {
      return toast.error("Você já atingiu o seu limite monetário esse mês!", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    if (chipsProduct.filter((item) => item.id === chip.id).length > 0) {
      setChipsProduct((chips) => chips.filter((item) => item.id !== chip.id))
    } else {
      setChipsProduct([...chipsProduct, chip])
    }
  }

	useEffect(() => {
    const fetchData = async () => {
      if (user?.corporate_id) {
        const getToken = await api.get(`company/retrieve/${user?.corporate_id}/`) as { [key: string]: any };
        const tokenOnboardingApi = getToken.content.token_onboarding;

        setTokenOnboarding(tokenOnboardingApi)
      }
    };

    fetchData()

    getProducts().catch(console.error)
	}, [])

  useLayoutEffect(() => {
    setTitleBar("Dashboard")
    setPathTitleBar(undefined)
    getOverBalance().catch(console.error)
  }, [setPathTitleBar, setTitleBar, getOverBalance])

	return (
		<>
      <ToastContainer />
      {
        !loading
          ? vehicleConsultation.length === 0 && cadastralConsultationPf.length === 0 && validationConsultation.length === 0 && cadastralConsultationPj.length === 0
            ? <Alert color="error" severity="warning">
                <strong>
                  Ainda não existem produtos cadastrados na plataforma!
                </strong>
              </Alert>
            : <form 
                onSubmit={handleSubmit}
              >
                {
                  // @ts-ignore
                  balance && ["2", "3"].indexOf(user?.profile) > -1
                    ? <>
                        <Alert color="info" variant="outlined" icon={
                          width >= 600
                            ? <Tooltip 
                                title={`Os valores informados abaixo estão relacionado a empresa ${user?.corporate_name} ao qual você faz parte e NÃO individual por usuário.`}
                              >
                                <MonetizationOnIcon />
                              </Tooltip>
                            : <></>
                        }>
                          <Typography variant="subtitle1" align={width >= 1146 ? 'left' : 'center'}>
                            <strong>INFORMAÇÕES MONETÁRIA DESTE MÊS</strong>
                          </Typography>
                          <Stack 
                            direction={width < 600 ? 'column' : 'row'}
                            spacing={1}
                            sx={{
                              textAlign: "center",
                              display: width >= 1500 ? 'flex' : 'grid',
                              gap: 1,
                              gridTemplateColumns: 
                              width < 600 ? '1fr' :
                              width <= 1200 ? '1fr' : 'repeat(3, 1fr)',
                              '& .MuiChip-root': {
                                marginLeft: width >= 600 ? '0 !important' : undefined,
                              }
                            }}> 
                            <Chip 
                              label={
                                <>
                                  <strong>Limite mensal:</strong> <span style={{ fontSize: "1rem" }}>R$ {formatMoney(balance.total_value_balance)}</span>
                                </>
                              }
                              color="secondary"
                              size="small"
                            />
                            <Chip 
                              label={
                                <>
                                  <strong>Consumido em consultas:</strong> <span style={{ fontSize: "1rem" }}>R$ {formatMoney(balance.total_value_queries)}</span>
                                </>
                              }
                              color="secondary"
                              size="small"
                            />
                            <Chip 
                              label={
                                <>
                                  <strong>Consumido em processamentos:</strong> <span style={{ fontSize: "1rem" }}>R$ {formatMoney(balance.total_value_processes)}</span>
                                </>
                              }
                              color="secondary"
                              size="small"
                            />

                            {tokenOnboarding && (
                            <Chip 
                              label={
                                <>
                                  <strong>Consumido em onboardings:</strong> <span style={{ fontSize: "1rem" }}>R$ 0</span>
                                </>
                              }
                              color="secondary"
                              size="small"
                            />
                          )}
                          </Stack>
                        </Alert>
                        <br />
                      </>
                    : <></>
                }
                <Grid 
                  container 
                  spacing={2} 
                  alignItems="center"
                  justifyContent="center"
                  display={
                    !step
                    ? 'flex'
                    : 'none'
                  }
                >
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      <strong>
                        Abaixo pequisas disponíveis para consultas. Selecione todas que quiser e clique em avançar.
                      </strong>
                    </Alert>
                  </Grid>
                  {
                    vehicleConsultation.length > 0
                    ? <Grid item md={12} xs={12}>
                        <Card elevation={0} sx={{ border: '1px solid #d4c268' }}>
                          <CardContent>
                            <Typography variant="h5" component="div">
                              Consultas veiculares
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                              Faça pesquisas veiculares informando placa, chassi ou ambos de acordo com o produto escolhido
                            </Typography>
                            {
                              vehicleConsultation.map((product) => (
                                <Tooltip
                                  key={`vehicle-consultation-${product.id}`}
                                  title={product.description}
                                  arrow 
                                  placement="right"
                                  sx={{ margin: 1 }}
                                >
                                  <Chip 
                                    icon={<DirectionsCarIcon />}
                                    label={product.name}
                                    onClick={handleAddOrDeleteChip(product)}
                                    color={
                                      chipsProduct.filter((chip) => chip.id === product.id).length > 0
                                      ? "warning" : "default"
                                    }
                                  />
                                </Tooltip>
                              ))
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                    : <></>
                  }
                  {
                    cadastralConsultationPf.length > 0
                    ? <Grid item md={12} xs={12}>
                        <Card elevation={0} sx={{ border: '1px solid #9454a4' }}>
                          <CardContent>
                            <Typography variant="h5" component="div">
                              Consultas cadastrais PF
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                              Faça pesquisas cadastrais de pessoas físicas informando CPF ou diversos outros dados de acordo com o produto escolhido
                            </Typography>
                            {
                              cadastralConsultationPf.map((product) => (
                                <Tooltip 
                                  key={`cadastral-consultation-pf-${product.id}`}
                                  title={product.description} 
                                  arrow 
                                  placement="right"
                                  sx={{ margin: 1 }}
                                >
                                  <Chip 
                                    icon={<AssignmentIndIcon />}
                                    label={product.name}
                                    onClick={handleAddOrDeleteChip(product)}
                                    color={
                                      chipsProduct.filter((chip) => chip.id === product.id).length > 0
                                      ? "info" : "default"
                                    }
                                  />
                                </Tooltip>
                              ))
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                    : <></>
                  }
                  {
                    cadastralConsultationPj.length > 0
                    ? <Grid item md={12} xs={12}>
                        <Card elevation={0} sx={{ border: '1px solid #fca6f2' }}>
                          <CardContent>
                            <Typography variant="h5" component="div">
                              Consultas cadastrais PJ
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                              Faça pesquisas cadastrais de pessoa jurídica informando CNPJ ou diversos outros dados de acordo com o produto escolhido
                            </Typography>
                            {
                              cadastralConsultationPj.map((product) => (
                                <Tooltip 
                                  key={`cadastral-consultation-pj-${product.id}`}
                                  title={product.description} 
                                  arrow 
                                  placement="right"
                                  sx={{ margin: 1 }}
                                >
                                  <Chip 
                                    icon={<BusinessIcon />}
                                    label={product.name}
                                    onClick={handleAddOrDeleteChip(product)}
                                    color={
                                      chipsProduct.filter((chip) => chip.id === product.id).length > 0
                                      ? "success" : "default"
                                    }
                                  />
                                </Tooltip>
                              ))
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                    : <></>
                  }
                  {
                    validationConsultation.length > 0
                    ? <Grid item md={12} xs={12}>
                        <Card elevation={0} sx={{ border: '1px solid #ff5975' }}>
                          <CardContent>
                            <Typography variant="h5" component="div">
                              Validações
                            </Typography>
                            <Typography variant="body2" sx={{ mb: 1.5 }} color="text.secondary">
                              Faça validações documentais ou diversos outros de acordo com o produto escolhido
                            </Typography>
                            {
                              validationConsultation.map((product) => (
                                <Tooltip 
                                  key={`validation-consultation-${product.id}`}
                                  title={product.description} 
                                  arrow 
                                  placement="right"
                                  sx={{ margin: 1 }}
                                >
                                  <Chip 
                                    icon={<FactCheckIcon />}
                                    label={product.name}
                                    onClick={handleAddOrDeleteChip(product)}
                                    color={
                                      chipsProduct.filter((chip) => chip.id === product.id).length > 0
                                      ? "error" : "default"
                                    }
                                  />
                                </Tooltip>
                              ))
                            }
                          </CardContent>
                        </Card>
                      </Grid>
                    : <></>
                  }
                </Grid>
                <Grid 
                  container 
                  spacing={2} 
                  alignItems="center"
                  justifyContent="center"
                  display={step ? 'flex' : 'none'}
                >
                  <Grid item md={12} xs={12}>
                    <Alert severity="info">
                      <strong>Você está fazendo as seguintes consultas:</strong>
                      <Grid
                        container
                        spacing={1} 
                        alignItems="center"
                        justifyContent="flex-start"
                        marginTop={1}
                      >
                        {
                          chipsProduct.map((chip) => {
                            return (
                              <Grid item md={Math.ceil(12/chipsProduct.length)} key={`chip-${chip.id}`}>
                                <Tooltip title={chip.description && chip.description.length > 0 ? chip.description : chip.name}>
                                  <Chip 
                                    icon={
                                      chip.category === "1" 
                                        ? <DirectionsCarIcon />
                                        : chip.category === "2"
                                          ? <AssignmentIndIcon />
                                          : chip.category === "3"
                                            ? <FactCheckIcon />
                                            : <BusinessIcon />
                                    }
                                    label={chip.name} 
                                    color={
                                      chip.category === "1" 
                                        ? "warning"
                                        : chip.category === "2"
                                          ? "info"
                                          : chip.category === "3"
                                            ? "success"
                                            : "error"
                                    }
                                  />
                                </Tooltip>
                              </Grid>
                            )
                          })
                        }
                      </Grid>
                    </Alert>
                  </Grid>
                  <Grid
                    item
                    md={12} xs={12}
                  >
                    <p style={{ color: LightMode.error.main }}>
                      <strong>
                        Por favor, preencha os campos solicitados (campos marcados com * são obrigatórios)
                      </strong>
                    </p>
                  </Grid>
                  {
                    state.parameters.map((parameter: ParameterProps) => {
                      return (
                        <Grid
                          key={`parameter-${parameter.name}-${parameter.api_id}`}
                          item
                          md={12} xs={12}
                        >
                          {
                            notTextOrNumber.indexOf(parameter.value_type) === -1
                              ? <TextField 
                                  fullWidth
                                  label={parameter.description}
                                  type={inputValueType[parameter.value_type]} 
                                  required={state.parametersRequired.indexOf(parameter.description) > -1} 
                                  name={`${parameter.name}|${parameter.api_id}`} 
                                  onChange={handleChange}
                                />
                              : parameter.value_type === '3'
                                ? <FormGroup>
                                    <FormControlLabel 
                                      control={
                                        <Checkbox 
                                          name={`${parameter.name}|${parameter.api_id}`} 
                                          onChange={(e) => {
                                            if (e.target.checked) {
                                              setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_value)
                                            } else {
                                              setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_default)
                                            }
                                          }}
                                        />
                                      } 
                                      label={parameter.description} 
                                    />
                                  </FormGroup>
                                : <FormGroup>
                                    {
                                      parameter.value_type === '4'
                                        ? <label
                                            className={
                                              `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                            }
                                            htmlFor={`${parameter.name}|${parameter.api_id}`}
                                          >
                                            <ImageIcon /> {
                                              values[`${parameter.name}|${parameter.api_id}`]
                                                ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                : `Clique aqui e ${parameter.description} ${state.parametersRequired.indexOf(parameter.description) > -1 ? "*" : ""}`
                                            }
                                            <input 
                                              style={{ display: "none" }}
                                              id={`${parameter.name}|${parameter.api_id}`}
                                              type="file"
                                              name={parameter.name}
                                              onChange={async (e) => {
                                                if (e.target.files) {
                                                  let base64 = await handleFileRead(e)
                                                  setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                } else {
                                                  setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                }
                                              }}
                                              accept='image/*'
                                            />
                                          </label>
                                        : parameter.value_type === '5'
                                          ? <label
                                              className={
                                                `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                              }
                                              htmlFor={`${parameter.name}|${parameter.api_id}`}
                                            >
                                              <PictureAsPdfIcon /> {
                                                values[`${parameter.name}|${parameter.api_id}`]
                                                  ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                  : `Clique aqui e ${parameter.description} ${state.parametersRequired.indexOf(parameter.description) > -1 ? "*" : ""}`
                                              }
                                              <input 
                                                style={{ display: "none" }}
                                                id={`${parameter.name}|${parameter.api_id}`}
                                                type="file"
                                                name={parameter.name}
                                                onChange={async (e) => {
                                                  if (e.target.files) {
                                                    let base64 = await handleFileRead(e)
                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                  } else {
                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                  }
                                                }}
                                                accept='application/pdf'
                                              />
                                            </label>
                                          : <label
                                              className={
                                                `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                              }
                                              htmlFor={`${parameter.name}|${parameter.api_id}`}
                                            >
                                              <UploadFileIcon /> {
                                                values[`${parameter.name}|${parameter.api_id}`]
                                                  ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                  : `Clique aqui e ${parameter.description} ${state.parametersRequired.indexOf(parameter.description) > -1 ? "*" : ""}`
                                              }
                                              <input 
                                                style={{ display: "none" }}
                                                id={`${parameter.name}|${parameter.api_id}`}
                                                type="file"
                                                name={parameter.name}
                                                onChange={async (e) => {
                                                  if (e.target.files) {
                                                    let base64 = await handleFileRead(e)
                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                  } else {
                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                  }
                                                }}
                                              />
                                            </label>
                                    }
                                  </FormGroup>
                          }
                        </Grid>
                      )
                    })
                  }
                  {
                    rescueVisibility 
                    ? <Grid
                        item
                        md={12} xs={12}
                      >
                        <FormGroup>
                          <FormControlLabel
                            control={
                              <Checkbox 
                                name="rescue"
                                onChange={(e) => {
                                  if (e.target.checked) {
                                    setFieldValue("rescue", true)
                                  } else {
                                    setFieldValue("rescue", false)
                                  }
                                }}
                                checked={values.rescue}
                                value={values.rescue}
                              />
                            }
                            label="Caso houver realizado essa consulta nos últimos 30 dias e deseja resgatá-la, marque essa opção"
                          />
                        </FormGroup>
                      </Grid>
                    : <></>
                  }
                </Grid>
                <Grid 
                  container 
                  alignItems="center"
                  justifyContent="center"
                  textAlign="center"
                >
                  <Grid 
                      item 
                      md={12} xs={12}
                      marginTop={3}
                    >
                      <Button 
                        variant="contained"
                        onClick={() => {handleChangeStep(!step)}}
                        disabled={chipsProduct.length === 0}
                        color={step ? "secondary" : "primary"}
                        disableElevation
                        sx={{ marginRight: 2 }}
                      >
                        {
                          step
                          ? <ArrowBackIcon />
                          : chipsProduct.length === 0 
                            ? 'Selecione um produto primeiro' 
                            : 'Avançar'
                        }
                        {
                          step
                          ? 'Voltar'
                          : <ArrowForwardIcon />
                        }
                      </Button>
                      {
                        step
                        ? <Button 
                            variant="contained"  
                            disabled={chipsProduct.length === 0 || awaiting}
                            type="submit"
                            color="primary"
                            disableElevation
                          >
                            Consultar <CheckIcon />
                          </Button>
                        : <></>
                      }
                  </Grid>
                </Grid>
              </form>
          : <LoadingPage />
      }
		</>
	)
}