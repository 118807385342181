import React, { useState, useRef, useEffect } from 'react';
import { toast } from 'react-toastify';
import { useItemsStore } from "../../../contexts/zustand/onboarding"

import { v4 as uuidv4 } from "uuid";

import {
    Modal,
    Box,
    Typography,
    TextField,
    Button,
    Tooltip,
    InputAdornment,
    Grid,
    MenuItem,
    Menu,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    IconButton,
    //Card,
    //CardContent,
    //CardMedia
} from '@mui/material';

import MoreVertIcon from '@mui/icons-material/MoreVert';
import InfoIcon from "@mui/icons-material/Info";

import * as api from '../../../services/api';
import { APIOnboarding } from '../../../services/api';
import { useAuth } from "../../../hooks/useAuth";
import { DraggableItem } from "../../../types/onboarding";

//import CloudUploadIcon from '@mui/icons-material/CloudUpload';
//import CloseIcon from '@mui/icons-material/Close';
//import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from "react-router-dom"

type LayoutType = {
    name_model: string;
    title_onboarding: string;
    logo: string;
    primary_color: string;
    second_color: string;
    text_color: string;
    button_text_color: string;
    third_color: string;
    navbar_background_color: string;
    card_shadow_color: string;
    baseboard_color: string;
    baseboard_text_color: string;
    card_color: string;
    card_text_color: string;
    template_email: string;
}

type ApiResponse = {
    content: {
        corporate_name: string;
        token_onboarding: string;
        layout_onboarding: {
            layout: LayoutType;
        };
    };
}

type SendOnboardingProps = {
    open: boolean;
    setOpen: React.Dispatch<React.SetStateAction<boolean>>;
    nameModelOnboarding: string;
    titleOnboarding: string;
}

type QuestionsAndAttachments = {
    id: string;
    label: string;
    type: string;
    options?: string[];
    defaultValue?: string;
    value: string;
    position: number;
    key?:string;
    required?: boolean;
    validations?: string[];
    can_gallery?: boolean;
    id_choice?: string;
    length_value?: number;
}

type Signatory = {
    data: {
        label: string;
        type: string;
        value: string;
        validations: string[];
    }[];
    questions?: QuestionsAndAttachments[];
    attachments?: QuestionsAndAttachments[];
};

type RowTable = {
    id?: string;
    name_signatory: string;
    cpfCnpj: string;
    number_whats: string;
    email: string;
}

type MoreOptionsMenuProps = {
    id_signatory: string;
}

type JsonType = {
    shipping: {
        email: boolean;
        whatsapp: boolean;
    };
    name_model: string;
    layout: LayoutType;
    signatories?: Signatory[];
};

const jsonLayoutInitial: JsonType = {
    "shipping": {
        "email": false,
        "whatsapp": false
    },
    "name_model": "",
    "layout": {
        "name_model": "",
        "title_onboarding": "",
        "logo": "",
        "primary_color": "",
        "second_color": "",
        "text_color": "",
        "button_text_color": "",
        "third_color": "",
        "navbar_background_color": "",
        "card_shadow_color": "",
        "baseboard_color": "",
        "baseboard_text_color": "",
        "card_color": "",
        "card_text_color": "",
        "template_email": "",
    }
};

let json: JsonType = {
    "shipping": {
        "email": false,
        "whatsapp": false
    },
    "name_model": "",
    "layout": {
        "name_model": "",
        "title_onboarding": "",
        "logo": "",
        "primary_color": "",
        "second_color": "",
        "text_color": "",
        "button_text_color": "",
        "third_color": "",
        "navbar_background_color": "",
        "card_shadow_color": "",
        "baseboard_color": "",
        "baseboard_text_color": "",
        "card_color": "",
        "card_text_color": "",
        "template_email": "",
    }
};

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '90%',
    maxWidth: '800px',
    bgcolor: 'background.paper',
    boxShadow: 24,
    p: 4,
    borderRadius: '8px',
    maxHeight: '90vh',
    overflowY: 'auto'
};

const columns = ['Nome', 'CPF/CNPJ', 'Número', 'Email', 'Ações'];

export default function SendOnboarding({ open, setOpen, nameModelOnboarding, titleOnboarding }: SendOnboardingProps) {

    const { user } = useAuth()
    const modalRef = useRef<HTMLDivElement>(null);

    const { items, clearFormValues } = useItemsStore()

    const navigate = useNavigate()

    const [tokenOboarding, setTokenOnboarding] = useState<string>('')
    const [layoutOnboarding, setLayoutOnboarding] = useState<LayoutType | null>(null);

    const [nameCorporate, setNameCorporate] = useState<string>('')

    const [name, setName] = useState<string>('');
    const [cpfCnpj, setCpfCnpj] = useState<string>('');
    const [numberWhats, setNumberWhats] = useState<string>('');
    const [email, setEmail] = useState<string>('');

    const [errorName, setErrorName] = useState<string>('');
    const [errorCpfCnpj, setErrorCpfCnpj] = useState<string>('');
    const [errorNumber, setErrorNumber] = useState<string>('');
    const [errorEmail, setErrorEmail] = useState<boolean>(false);

    const [editingData, setEditingData] = useState<string | null>(null);
    const [file, setFile] = useState<File | null>(null);
    //const [fileName, setFileName] = useState<string>('');
    const [data, setData] = useState<RowTable[]>([]);

    useEffect(() => {
        const companyId = user?.corporate_id!;
        const fetchData = async () => {
            try {
                const response = await api.get(`company/retrieve/${companyId}/`) as ApiResponse;
                setNameCorporate(response.content.corporate_name)
                setTokenOnboarding(response.content.token_onboarding);
                setLayoutOnboarding(response.content.layout_onboarding.layout);
            } catch (error) {
                console.error('Error fetching data:', error);
            }
        };
        fetchData();
    }, [user?.corporate_id]);

    const resetStates = () => {
        setName('')
        setErrorName('')
        setCpfCnpj('')
        setErrorCpfCnpj('')
        setNumberWhats('')
        setErrorNumber('')
        setEmail('')
        setErrorEmail(false)
        setEditingData(null)
        setData([])
        setOpen(false)
        setFile(null)
        //setFileName('');
        json = jsonLayoutInitial
        json.signatories = []
    }

    const handleClose = () => resetStates()


    const handleCheckTypeName = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^[A-Za-zÀ-ÖØ-öø-ÿ\s]*$/;
        const verifyTypeName = regex.test(value)

        if (!verifyTypeName) {
            setErrorName('Digite somente letras!!')
            return
        } else {
            setErrorName('')
        }

        setName(value);
    };

    const handleCheckCpfCnpj = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^\d*$/;
        const verifyCpfCnpj = regex.test(value)

        if (!verifyCpfCnpj) {
            setErrorCpfCnpj('Digite somente números');
            return false;
        }

        setCpfCnpj(value);
        return true;
    };

    const handlecheckPhone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        const regex = /^\d*$/;
        const verifyTypeNumber = regex.test(value)

        if (!verifyTypeNumber) {
            setErrorNumber('Digite somente números')
            return false
        }

        if (value.length > 11) {
            setErrorNumber('O número não pode ter mais de 11 dígitos');
            return false;
        }

        setErrorNumber('')
        setNumberWhats(value);
    };

    const checkTypeEmail = (email: string) => {

        const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        const verifyTypeEmail = regex.test(email);

        if (!verifyTypeEmail) return false

        return true

    };

    const addSignatory = (e: React.MouseEvent<HTMLButtonElement>) => {

        if (!name) {
            setErrorName('Adicione um nome e número/email');
            return;
        }

        if (cpfCnpj && cpfCnpj.length < 11) {
            setErrorCpfCnpj('CPF deve ter 11 dígitos e CNPJ deve ter 14 dígitos');
            return false;
        }


        if (!numberWhats && !email) {
            toast.error('Adicione um número/email');
            return;
        }

        if (email && !checkTypeEmail(email)) {
            toast.error('Digite um email válido!!');
            setErrorEmail(true);
            return;
        }

        const newData: RowTable = {id: uuidv4(), name_signatory: name, cpfCnpj: cpfCnpj, number_whats: numberWhats, email };

        if (editingData) {
            const updatedData = data.map(item =>
                item.id === editingData ? newData : item
            );
            setData(updatedData);
            setEditingData(null);
        } else {
            setData([...data, newData]);
        }

        setName('');
        setErrorName('');
        setCpfCnpj('');
        setErrorCpfCnpj('');
        setNumberWhats('');
        setErrorNumber('');
        setEmail('');
        setErrorEmail(false);
    }

    const MoreOptionsMenu = ({ id_signatory }: MoreOptionsMenuProps) => {
        const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

        const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
            setAnchorEl(event.currentTarget);
        };

        const handleClose = () => {
            setAnchorEl(null);
        };

        const editDataSignatory = () => {
            const signatory = data.find(item => item.id === id_signatory)!;

            setName(signatory.name_signatory)
            setCpfCnpj(signatory.cpfCnpj)
            setNumberWhats(signatory.number_whats)
            setEmail(signatory.email)
            setEditingData(signatory.id!);

            if (modalRef.current) {
                modalRef.current.scrollTo(0, 0);
            }
        }

        const removeDataSignatory = () => {
            const filteredData = data.filter(item => item.id !== id_signatory);

            setData(filteredData);

            if (filteredData.length === 0) {
                setName('');
                setCpfCnpj('');
                setNumberWhats('');
                setEmail('');
                setEditingData(null);
            }
        };

        const menuItems = () => {
            return [
                <MenuItem key="editar" onClick={() => { editDataSignatory(); handleClose(); }}>
                    <Typography sx={{ cursor: 'pointer' }}>Editar</Typography>
                </MenuItem>,
                <MenuItem key="excluir" onClick={() => { removeDataSignatory(); handleClose(); }}>
                    <Typography sx={{ cursor: 'pointer', color: '#FFA890' }}>Excluir</Typography>
                </MenuItem>
            ];
        };

        return (
            <Grid>
                <IconButton onClick={handleClick}>
                    <MoreVertIcon />
                </IconButton>
                <Menu
                    anchorEl={anchorEl}
                    open={Boolean(anchorEl)}
                    onClose={handleClose}
                >
                    {menuItems()}
                </Menu>
            </Grid>
        );
    };

    //const handleUploadFile = async (e: React.ChangeEvent<HTMLInputElement>) => {
    //    const files = e.target.files;
    //    if (!files || files.length === 0) return;
//
    //    const selectedFile = files[0];
    //    setFile(selectedFile)
    //    setFileName(selectedFile.name);
    //};
//
    //const handleRemoveFile = () => {
    //    setFile(null);
    //    setFileName('');
    //};

    const createSignatory = () => {

        if (data.length === 0 && (name && (numberWhats || email))) {
            const newSignatory: Signatory = {
                data: [
                    { label: "nome", type: "1", value: name, validations: [] },
                    { label: "cpf_cnpj", type: "1", value: cpfCnpj, validations: [] },
                    { label: "whatsapp", type: "2", value: numberWhats, validations: [] },
                    { label: "email", type: "2", value: email, validations: [] },
                    { label: "nome_empresa", type: "1", value: nameCorporate, validations: [] },
                ],
            };

            json.shipping.email = json.shipping.email || !!email;
            json.shipping.whatsapp = json.shipping.whatsapp || !!numberWhats;
            json.signatories = json.signatories || [];
            json.signatories.push(newSignatory);
        }

        data.forEach(({ name_signatory, cpfCnpj, number_whats, email }: RowTable) => {

            const newSignatory: Signatory = {
                data: [
                    { label: "nome", type: "1", value: name_signatory, validations: [] },
                    { label: "cpf_cnpj", type: "1", value: cpfCnpj, validations: [] },
                    { label: "whatsapp", type: "2", value: number_whats, validations: [] },
                    { label: "email", type: "2", value: email, validations: [] },
                    { label: "nome_empresa", type: "1", value: nameCorporate, validations: [] },
                ],
            };

            json.shipping.email = json.shipping.email || !!email;
            json.shipping.whatsapp = json.shipping.whatsapp || !!number_whats;
            json.signatories = json.signatories || [];
            json.signatories.push(newSignatory);
        });

        return true

    };

    const createQuestionsAndAttachments = (questions: QuestionsAndAttachments[]) => {
        
        let position_question = 0;
        const usedPositions = new Set<number>();

        while (usedPositions.has(position_question)) {
            position_question++;
        }

        questions.forEach(question => {
            const newElement: QuestionsAndAttachments = {
                id: question.key!,
                position: position_question,
                label: question.label,
                type: question.type,
                options: question.options || [],
                value: question.defaultValue || "",
                required: question.required || false,
                validations: [],
                length_value: question.length_value,
            };

                usedPositions.add(position_question);
                position_question++;

            if (question.id_choice === '2') {
                newElement.can_gallery = true;
            }

            if (data.length === 0 && file) {
                if (!json.signatories || json.signatories.length === 0) {
                    json.signatories = [{
                        data: [],
                        questions: [],
                        attachments: []
                    }];
                }
                const key = ['1', '2'].includes(question.id_choice!) ? 'attachments' : 'questions';

                json.signatories[0][key] = json.signatories[0][key] || [];
                json.signatories[0][key]!.push(newElement);

                return
            }

            json.signatories = json.signatories?.map(signatory => {
                const key = ['1', '2'].includes(question.id_choice!) ? 'attachments' : 'questions';
                signatory[key] = signatory[key] || [];
                (signatory[key] ??= []).push(newElement);
                return signatory;
            });
        });
    };

    const sendOnboarding = async () => {

        toast.loading('Aguarde...Estamos preparando para o envio!!')

        const responseCreateSignatory = createSignatory()

        if (!responseCreateSignatory) return

        createQuestionsAndAttachments(items as QuestionsAndAttachments[])

        json.layout = layoutOnboarding as LayoutType
        json.layout.title_onboarding = titleOnboarding
        json.layout.name_model = nameModelOnboarding;

        if (file) {

            try {
                const formData = new FormData();
                formData.append('file', file);
                formData.append('json', JSON.stringify(json));

                const response = await APIOnboarding.post(`onboarding/create/?token=${tokenOboarding}`, formData, 
                    {
                    responseType: 'blob'
                });

                if (response.status === 201) {
                    toast.success('Onboarding enviado com Sucesso!!')
                    toast.success('Redirecionando para a página principal!!')
                    navigate('/onboarding')
                }

            } catch (error) {
                console.error("Error uploading file:", error);
            }

            resetStates()
        }

        try {
            const response = await APIOnboarding.post(`onboarding/create/?token=${tokenOboarding}`, json);

            if (response.status === 201) {

                toast.dismiss();
                toast.success('Onboarding enviado com Sucesso!!')
                toast.success('Redirecionando para a página principal!!')
                setTimeout(() => {
                    toast.dismiss();
                    navigate('/onboarding');
                }, 3000)
            }

        } catch (error) {
            toast.dismiss()
            toast.error('Erro ao enviar onboarding', {autoClose: 2000});
        }

        resetStates()
    }

    return (
        <Modal
            open={open}
            onClose={handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
            sx={{ overflowY: data.length > 1 ? 'auto' : 'hidden' }}
        >
            <Box ref={modalRef} sx={modalStyle}>

                <Typography
                    id="modal-modal-title"
                    variant="h6"
                    component="h2"
                    sx={{
                        fontSize: '24px',
                        textAlign: 'center',
                        color: '#808080',
                        marginBottom: '10px',
                        wordBreak: 'break-all'
                    }}>
                    {titleOnboarding}
                </Typography>

                <Typography
                    sx={{
                        fontSize: '24px',
                        color: '#FFA089',
                        marginBottom: '10px'
                    }}>
                    Dados para envio do formulário
                </Typography>

                <Typography
                    sx={{
                        color: 'text.secondary',
                        marginBottom: '10px'
                    }}>
                    Preencha com os dados de quem receberá o formulário
                </Typography>

                <Grid container spacing={2}>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%', marginBottom: '10px' }}>
                            <TextField
                                label="Nome"
                                value={name}
                                onChange={handleCheckTypeName}
                                sx={{
                                    borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                }}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                inputProps={{ maxLength: 40 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        Digite somente letras!!<br />
                                                        Limite máximo de 40 caracteres
                                                    </Box>
                                                }
                                                placement="right"
                                            >
                                                <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!errorName}
                                helperText={errorName}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%', marginBottom: '10px' }}>
                            <TextField
                                label="CPF/CNPJ"
                                value={cpfCnpj}
                                onChange={handleCheckCpfCnpj}
                                sx={{
                                    borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                }}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                inputProps={{ maxLength: 14 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        Digite somente números!!<br />
                                                        Limite máximo de 14 caracteres
                                                    </Box>
                                                }
                                                placement="right"
                                            >
                                                <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!errorCpfCnpj}
                                helperText={errorCpfCnpj}
                            />
                        </Box>
                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%', marginBottom: '10px' }}>
                            <TextField
                                label="Número do WhatsApp"
                                value={numberWhats}
                                placeholder="21999999999"
                                onChange={handlecheckPhone}
                                sx={{
                                    borderColor: '#1e1620', borderRadius: '5px', '&:hover': { border: 'none' }
                                }}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                inputProps={{ maxLength: 15 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        Digite somente números!!<br />
                                                        Limite máximo de 15 caracteres
                                                    </Box>
                                                }
                                                placement="right"
                                            >
                                                <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                error={!!errorNumber}
                                helperText={errorNumber}
                            />
                        </Box>

                    </Grid>

                    <Grid item xs={12} sm={6}>
                        <Box sx={{ width: '100%', marginBottom: '10px' }}>
                            <TextField
                                label="Email"
                                value={email}
                                type="email"
                                onChange={(e) => setEmail(e.target.value)}
                                sx={{
                                    borderColor: '#9454A4', borderRadius: '5px', '&:hover': { border: 'none' }
                                }}
                                fullWidth
                                variant="outlined"
                                margin="normal"
                                inputProps={{ maxLength: 40 }}
                                InputProps={{
                                    endAdornment: (
                                        <InputAdornment position="end">
                                            <Tooltip
                                                title={
                                                    <Box>
                                                        Digite um email válido!!<br />
                                                        Limite máximo de 40 caracteres
                                                    </Box>
                                                }
                                                placement="right"
                                            >
                                                <InfoIcon sx={{ color: 'gray', fontSize: 18 }} />
                                            </Tooltip>
                                        </InputAdornment>
                                    ),
                                }}
                                error={errorEmail}
                            />
                        </Box>
                    </Grid>

                </Grid>


                {data.length > 0 && (
                    <Paper sx={{ width: '100%', overflow: 'hidden' }}>
                        <TableContainer sx={{ maxHeight: '200px', overflowY: 'auto' }}>
                            <Table stickyHeader sx={{ minWidth: 650 }} aria-label="simple table">
                                <TableHead>
                                    <TableRow>
                                        {columns.map((item: string, index: number) => (
                                            <TableCell
                                                key={index}
                                                align='left'
                                                sx={{
                                                    color: '#432F48',
                                                    backgroundColor: '#FAFAFA',
                                                    fontWeight: '600',
                                                    wordWrap: 'break-word'
                                                }}
                                            >
                                                {item}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {data.map((row, rowIndex) => (
                                        <TableRow
                                            key={rowIndex}
                                            sx={{
                                                backgroundColor: '#FAFAFA'
                                            }}
                                        >
                                            <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.name_signatory}</TableCell>
                                            <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.cpfCnpj}</TableCell>
                                            <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.number_whats}</TableCell>
                                            <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>{row.email}</TableCell>
                                            <TableCell align="left" sx={{ backgroundColor: '#FAFAFA', wordWrap: 'break-word', maxWidth: '150px' }}>
                                                <MoreOptionsMenu id_signatory={row.id!} />
                                            </TableCell>

                                        </TableRow>
                                    ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Paper>
                )}

                <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'space-between', marginTop: '20px', gap: '10px', height: '50px' }}>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-end', gap: '10px' }}>
                        <Button
                            sx={{
                                display: 'flex',
                                justifyContent: { xs: 'flex', sm: '150px' },
                                backgroundColor: '#9454A4',
                                color: '#FFFFFF',
                                '&:hover': {
                                    color: '#FFFFFF',
                                    backgroundColor: '#9454A4',
                                },
                                borderRadius: '8px',
                                width: { xs: '100%', sm: '150px' },
                                height: 'auto',
                                marginBottom: { xs: '10px', sm: '0' }
                            }}
                            onClick={addSignatory}
                        >
                            {editingData === null ? 'Adicionar Destinatário' : 'Salvar'}
                        </Button>

                        {/*<Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
                            {!file ? (
                                <Button
                                    component="label"
                                    endIcon={<CloudUploadIcon />}
                                    sx={{
                                        display: 'flex',
                                        alignItems: 'center',
                                        fontSize: '18px',
                                        height: '50px',
                                        color: '#FFA089',
                                        backgroundColor: '#FFFFFF',
                                        textTransform: 'none',
                                        border: '1px solid #FFA089',
                                        borderRadius: '8px',
                                        '&:hover': {
                                            backgroundColor: '#FFFFFF',
                                            color: '#FFA089',
                                            border: '1px solid #FFA089',
                                        },
                                    }}
                                >
                                    Enviar Planilha
                                    <input
                                        type="file"
                                        accept=".csv,.xlsx"
                                        hidden
                                        onChange={handleUploadFile}
                                    />
                                </Button>
                            ) : (
                                <Box sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    width: '170px',
                                    border: '1px solid #ccc',
                                    borderRadius: '4px',
                                    padding: '8px',
                                    height: '100%'
                                }}>
                                    <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', width: '100%' }}>
                                        <Grid container spacing={1} alignItems="center">
                                            <Grid item xs={8}>
                                                <Typography variant="body2" sx={{ fontSize: '12px', wordBreak: 'break-all', textAlign: 'left' }}>
                                                    {fileName}
                                                </Typography>
                                                <Typography variant="caption" color="textSecondary">
                                                    {(file.size / 1024).toFixed(2)} KB
                                                </Typography>
                                            </Grid>
                                            <Grid item xs={4} sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                                <Button
                                                    onClick={handleRemoveFile}
                                                    sx={{
                                                        color: 'red',
                                                        fontSize: '12px',
                                                        textTransform: 'none',
                                                        display: 'flex',
                                                        alignItems: 'center'
                                                    }}
                                                >
                                                    <DeleteIcon fontSize="medium" />
                                                </Button>
                                            </Grid>
                                        </Grid>
                                    </Box>
                                </Box>

                            )}
                        </Box>*/}
                    </Box>

                    <Box sx={{ display: 'flex', flexDirection: { xs: 'column', sm: 'row' }, justifyContent: 'flex-end', gap: '10px' }}>
                        <Button
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                '&:hover': {
                                    backgroundColor: '#F5F5F5',
                                },
                                borderRadius: '8px',
                                border: '1px solid #D1D5DB',
                                width: { xs: '100%', sm: '150px' },
                                marginBottom: { xs: '10px', sm: '0' }
                            }}
                            onClick={handleClose}
                        >
                            Cancelar
                        </Button>

                        <Button
                            sx={{
                                backgroundColor: '#FFA890',
                                color: '#FFFFFF',
                                '&:hover': {
                                    color: 'white',
                                    backgroundColor: '#FFA890',
                                },
                                borderRadius: '8px',
                                width: { xs: '100%', sm: '150px' },
                            }}
                            disabled={file === null && (data.length === 0 && (name === '' || (numberWhats === '' && email === '')))}
                            onClick={sendOnboarding}
                        >
                            Enviar
                        </Button>
                    </Box>

                </Box>
            </Box>
        </Modal>
    );
};

