import { Pagination } from "@mui/material"

type PaginatorProps = {
  count: number
  pageNumber: number
  qtdResults?: number
  onChange: ((event: React.ChangeEvent<unknown>, page: number) => void) | undefined
}

export function Paginator({ count, pageNumber, qtdResults, onChange }: PaginatorProps) {
  return (
    <div
      style={
        {
          display: "flex",
          justifyContent: "center",
          marginTop: "30px"
        }
      }
    >
      <Pagination 
        showFirstButton 
        showLastButton 
        color="primary" 
        count={qtdResults! > 0 ? Math.ceil(count/qtdResults!) : Math.ceil(count/20) }
        page={pageNumber}
        onChange={onChange} 
        variant="outlined"
      />
    </div>
  )
}