import styled from "styled-components";

export const StyledForm = styled.form`
    gap: 20px;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    flex-direction: column;
    transition: all 300ms linear;

    strong {
        font-size: 1.1rem;
    }
`