import React, { useState, useEffect } from "react";
import axios from 'axios'
import * as api from '../../../services/api'
import { APIOnboarding } from '../../../services/api'
import { useAuth } from "../../../hooks/useAuth"
import { useParams, useNavigate } from "react-router-dom"
import { Button, Grid, Box } from "@mui/material"
import Paper from '@mui/material/Paper';

import ProtectRoutesOnboarding from "../index";

import { choicesFields, optionsFields, optionsAttachments, optionSection } from '../ChoicesFields/choices';

import CloudDownloadIcon from '@mui/icons-material/CloudDownload';

import Typography from "@mui/material/Typography";

import { toast, ToastContainer } from "react-toastify"

type ResponseQuestions = {
    id: string;
    label: string;
    created_at: Date;
    type?: string;
    options: string[];
    value: string;
    required: boolean;
    id_choice: string;
    position: number;
};

type ResponseAttachments = {
    id: string;
    created_at: Date;
    label: string;
    data: ResponseQuestions;
    file: string;
    position: number;
};

type DataSignatory = {
    label: string;
    type: string;
    value: string;
};

type ResultItem = {
    id: string;
    data: DataSignatory[];
    onboarding_id: string;
    questions: ResponseQuestions[],
    attachments: ResponseAttachments[]
    response_questions: ResponseQuestions[]
    response_attachments: ResponseAttachments[]
    combinedItems: any;
}

type ApiResponse = {

    content: {
        results: ResultItem[];
        data: DataSignatory[];
        questions: ResponseQuestions[];
        attachments: ResponseQuestions[];
    };
};

const mimeTypes: Record<string, string> = {
    // Documentos
    'application/pdf': 'pdf',
    'application/msword': 'doc',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
    'application/vnd.ms-excel': 'xls',
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
    'text/csv': 'csv',
    'text/plain': 'txt',

    // Imagens
    'image/jpeg': 'jpeg',
    'image/png': 'png',
    'image/gif': 'gif',
    'image/bmp': 'bmp',
    'image/tiff': 'tiff',

    // Arquivos compactados
    'application/zip': 'zip',
    'application/x-rar-compressed': 'rar',
    'application/x-7z-compressed': '7z',

    // Áudio
    'audio/mpeg': 'mp3',
    'audio/wav': 'wav',
    'audio/ogg': 'ogg',

    // Vídeo
    'video/mp4': 'mp4',
    'video/x-msvideo': 'avi',
    'video/mpeg': 'mpeg',

    // Outros formatos comuns
    'application/json': 'json',
    'application/xml': 'xml',
    'application/octet-stream': 'bin', 
};


const formattedLabels: { [key: string]: string } = {
    "nome": "Nome",
    "cpf_cnpj": "CPF/CNPJ",
    "whatsapp": "Número de telefone",
    "email": "Email",
    "numero_processo": "Número do processo"
};

export default function ViewResponseSignatory() {

    const { user } = useAuth()

    const { id_signatory } = useParams();

    const navigate = useNavigate();

    const [resultSignatory, setResultSignatory] = useState<ApiResponse | null>(null);

    useEffect(() => {
        const companyId = user?.corporate_id!

        const fetchData = async () => {
            const getToken = await api.get(`company/retrieve/${companyId}/`) as { [key: string]: any }
            const tokenOnboarding = getToken.content.token_onboarding

            try {
                const response = await APIOnboarding.get(`onboarding/signatory/list/?id=${id_signatory}&token=${tokenOnboarding}`);
                
                const orderdData = {
                    ...response.data,
                    content: {
                        ...response.data.content,
                        results: response.data.content.results.map((item: ResultItem) => {

                            const orderedResponseQuestions = item.response_questions.map(question => ({
                                ...question,
                                type_question: 'question',
                                position: question.position
                            }));

                            const orderedResponseAttachments = item.response_attachments.map(attachment => ({
                                ...attachment,
                                type_question: 'attachment',
                                position: attachment.data.position
                            }));

                            const combinedItems = [
                                ...orderedResponseQuestions,
                                ...orderedResponseAttachments
                            ].sort((a, b) => a.position - b.position);

                            return {
                                ...item,
                                combinedItems,
                            };

                        }),
                    }
                };

                setResultSignatory(orderdData);
            } catch (error) {
                toast.error('Erro ao trazer as respostas!', { autoClose: 2000 });
            }

        };

        fetchData();
    }, [id_signatory]);

    if (!resultSignatory) return null

    const formatResultData = (resultData: DataSignatory[]) => {
        const desiredOrder = ["numero_processo", "nome", "cpf_cnpj", "whatsapp", "email"];

        const dataMap: { [key: string]: string } = {};
        resultData.forEach(field => {
            dataMap[field.label] = field.value;
        });
        return desiredOrder.map(key => ({
            label: formattedLabels[key],
            value: dataMap[key]
        }));
    };

    const downloadFileFromS3 = async (fileUrl: string) => {
        try {
            const response = await axios.get(fileUrl, {
                responseType: 'blob',
            });

            const mimeType = response.headers['content-type'];
            const extension = mimeTypes[mimeType] || mimeType.split('/').pop() || 'file';

            const fileName = `documento.${extension}`;

            const url = window.URL.createObjectURL(new Blob([response.data], { type: mimeType }));
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', fileName);
            document.body.appendChild(link);
            link.click();

            link.remove();
            window.URL.revokeObjectURL(url);
        } catch (error) {
            toast.error('Erro ao baixar o arquivo');
        }
    };

    const visualizationPdf = async () => {
        const response = await api.post('onboarding/createPdf/', resultSignatory) as { [key: string]: any };

        const blob = new Blob([response.data], { type: 'text/html' });
        const url = window.URL.createObjectURL(blob);
        const newWindow = window.open(url, '_blank');
        if (newWindow) newWindow.focus();

    }

    return (
        <ProtectRoutesOnboarding>
            <ToastContainer />

            <Grid container spacing={2} sx={{ padding: 2 }}>
                <Grid item xs={12} md={8}>
                    <Paper elevation={3} sx={{ padding: 2, backgroundColor: '#FFFFFF' }}>
                        <Typography variant="h6" gutterBottom>Respostas do usuário</Typography>

                        {
                            resultSignatory && resultSignatory.content.results.map((result: ResultItem, index: number) => (
                                formatResultData(result.data).map((field, index) => (
                                    <Grid item xs={12} key={index} sx={{ mb: 1 }}>
                                        <Typography sx={{ fontWeight: 'bold' }}>
                                            {field.value && `${field.label}:`}
                                        </Typography>
                                        <Typography>
                                            {field.value}
                                        </Typography>
                                    </Grid>
                                ))
                            ))
                        }

                        <Grid container spacing={3} sx={{ marginTop: 2 }}>

                            {resultSignatory.content.results.map((item) => (
                                item.combinedItems.map((combinedItem: any) => {
                                    if (combinedItem.type_question === 'question') {
                                        const option = optionsFields.find(opt => opt.type === combinedItem.type)!;
                                        const section = optionSection.type === combinedItem.type ? optionSection : null!;

                                        if (option || section) {
                                            return (
                                                <Grid item xs={12} key={combinedItem.label}>
                                                    <Box>
                                                        {combinedItem.type === '18' ? (
                                                            choicesFields[section.id_choice as any].value(combinedItem)
                                                        ) : (
                                                            <Typography>
                                                                {choicesFields[option.id_choice as any].value(combinedItem)}
                                                            </Typography>
                                                        )}
                                                    </Box>
                                                </Grid>
                                            );
                                        }
                                    } else if (combinedItem.type_question === 'attachment') {
                                        const option = optionsAttachments.find(opt => opt.type === combinedItem.data.type);
                                        if (option) {
                                            return (
                                                <Grid item xs={12} key={combinedItem.data.label}>
                                                    <Box>
                                                        <Typography variant="body2">
                                                            {choicesFields[(option.id_choice as any)].value(combinedItem.data)}
                                                        </Typography>

                                                        <Button
                                                            sx={{
                                                                marginTop: 1,
                                                                backgroundColor: '#FFA890',
                                                                color: '#F5F5F5',
                                                                textTransform: 'none'
                                                            }}
                                                            variant="contained"
                                                            endIcon={<CloudDownloadIcon />}
                                                            onClick={() => downloadFileFromS3(combinedItem.file)}
                                                        >
                                                            Baixar Arquivo
                                                        </Button>
                                                    </Box>
                                                </Grid>
                                            );
                                        }
                                    }
                                    return null;
                                })
                            ))}

                        </Grid>
                    </Paper>
                </Grid>

                <Grid item xs={12} md={4}>
                    <Box
                        sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            alignItems: 'center',
                            justifyContent: 'center',
                            gap: 2,
                            padding: 2,
                            backgroundColor: '#F9FAFB',
                        }}
                    >
                        <Button
                            onClick={visualizationPdf}
                            variant="contained"
                            sx={{
                                backgroundColor: '#FFA890',
                                color: '#F5F5F5',
                                textTransform: 'none',
                                width: '70%'
                            }}
                            endIcon={<CloudDownloadIcon />}
                        >
                            Baixar PDF
                        </Button>
                        <Button
                            onClick={() => navigate(-1)}
                            sx={{
                                backgroundColor: '#FFFFFF',
                                color: '#000000',
                                border: '1px solid #D1D5DB',
                                width: '70%',
                                textTransform: 'none'
                            }}
                        >
                            Voltar
                        </Button>
                    </Box>
                </Grid>
            </Grid>

        </ProtectRoutesOnboarding>

    )
}