export function priceToString(value: number): string {
  if (value.toString().length === 0) return '0,00'
  let newValue =  value.toLocaleString('pt-BR')
  if (!newValue.includes(',')) {
    newValue = `${newValue},00`
  }
  return newValue
}

export function formatMoney(value: string): number {
  if (value.length === 0) return 0
  if (value.includes(',')) return parseFloat(value.replaceAll('.', '').replaceAll(',', '.'))
  return parseFloat(value)
}


// export function formatMoney(value: string): string {
//   const options = { minimumFractionDigits: 2 }
//   let percent = 100

//   if (value.length > 0) {
//     let newValue = value
    
//     if (newValue.includes(".")) {
//       if (newValue.split('.')[1].length === 1) {
//         percent = 10
//       }
//     } else if (!newValue.includes(",") && newValue.length > 1) {
//       percent = 1
//     }

//     newValue = value.replace('.', '').replace(',', '').replace(/\D/g, '')

//     const result = new Intl.NumberFormat('pt-BR', options).format(
//       parseFloat(newValue) / percent
//     )
//     return result
//   }
//   return ''
// }

// export function priceToString(value: string): string {
//   return (
//     parseFloat(
//       value.replaceAll('.', '').replaceAll(',', '')
//     )/100
//   ).toString()
// }