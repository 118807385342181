import Box from "@mui/material/Box"
import Grid from "@mui/material/Grid"
import React, { useState } from "react"
import Button from "@mui/material/Button"
import { useAuth } from "../../hooks/useAuth"
import TextField from "@mui/material/TextField"
import { useTheme } from '@material-ui/core/styles'
import { LightTheme } from "../../styles/lightMode"
import { toast, ToastContainer } from "react-toastify"
import Visibility from '@mui/icons-material/Visibility'
import useMediaQuery from '@material-ui/core/useMediaQuery'
import VisibilityOff from '@mui/icons-material/VisibilityOff'
import BackgroundSign from "../../components/BackgroundSignin"
import Typography from "@material-ui/core/Typography/Typography"
import { IconButton, InputAdornment, Stack, ThemeProvider } from "@mui/material"

const logo = require('../../images/logo.png')

export default function Login(): JSX.Element {
	const theme = useTheme()
	const matches = useMediaQuery(theme.breakpoints.up('md'))

	const { user, Login, Forgot, ChangePassword } = useAuth()

	const [formData, setFormData] = useState({
		email: "",
		password: "",
		current_password: "",
		new_password: "",
		new_password_again: ""
	})
	
	const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setFormData({...formData, [e.target.name]: e.target.value})
	}

	const [forgot, setForgot] = useState(false)
	function changeForgot() {
		setForgot(!forgot)
	}

	async function handleLogin() {
		await Login({
			username: formData.email,
			password: formData.password,
		})
	}

	async function handleForgot() {
		var response = await Forgot({
			email: formData.email
		})
		if (response) {
			toast.success(`Um e-mail com uma nova senha provisória foi enviado para ${formData.email}`, {
				position: toast.POSITION.TOP_RIGHT
			})
			changeForgot()
		} else {
			toast.error("Opss.. Por favor, tente novamente mais tarde!", {
				position: toast.POSITION.TOP_RIGHT
			})
		}
	}

	async function handleChangePassword() {
		await ChangePassword({
			current_password: formData.current_password,
			new_password: formData.new_password,
			new_password_again: formData.new_password_again
		})
	}
	
	return (
		<ThemeProvider theme={LightTheme}>
			<ToastContainer />
			<Grid container>
				{
					matches
						? <Grid item md={7}>
								<BackgroundSign />
							</Grid>
						: <></>
				}
				<Grid item lg={5} md={12} sm={12} xs={12} className="backgroundSign">
					<Box display="flex" justifyContent="center" alignItems="center" minHeight="100vh" sx={{ mx: 2 }}>
						<Stack spacing={2} width="100%" justifyContent="center">
							{
								!matches
									? <Stack alignItems="center" margin={5}>
											<img 
												src={logo} 
												alt="Logo" 
												width={300} height="auto"
											/>
										</Stack>
									:<></>
							}
							{
								!forgot && !user?.first_login
									? <AccessTemplate 
											handleChange={handleChange} 
											handleData={handleLogin} 
											changeForgot={changeForgot} 
										/>
									: user?.first_login
										? <FirstLoginTemplate 
												handleChange={handleChange} 
												handleData={handleChangePassword} 
												disabled={
													formData.new_password !== formData.new_password_again || 
													formData.new_password.length === 0 ||
													formData.new_password_again.length === 0
												}
											/>
										: <ForgotTemplate 
												handleChange={handleChange} 
												handleData={handleForgot} 
												changeForgot={changeForgot} 
											/>
							}
						</Stack>
					</Box>
				</Grid>
			</Grid>
		</ThemeProvider>
	)
}

interface AccessTemplateProps {
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleData: () => void
	changeForgot: () => void
}

function AccessTemplate({handleChange, handleData, changeForgot}: AccessTemplateProps): JSX.Element {
	const [showPassword, setShowPassword] = useState<boolean>(false)

	const handleClickShowPassword = () => {
    setShowPassword(!showPassword)
  }

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

	return (
		<>
			<Typography align="center" color="textSecondary" variant="h5">
				ACESSAR
			</Typography>
			<TextField label="Email" variant="outlined" type="email" name="email" onChange={handleChange} color="primary" onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} />
			<TextField 
				label="Senha" 
				type={showPassword ? "text" : "password"}
				name="password" 
				onChange={handleChange} 
				color="primary" 
				onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} 
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={handleClickShowPassword}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{showPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
			<Button variant="contained" color="primary" onClick={handleData}>ENTRAR</Button>
			<Button variant="text" onClick={changeForgot}>Esqueceu a senha? Clique aqui para recuperar</Button>
		</>
	)
}

interface ForgotTemplateProps {
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleData: () => void
	changeForgot: () => void
}

function ForgotTemplate({ handleChange, handleData, changeForgot }: ForgotTemplateProps): JSX.Element {
	return (
		<>
			<Typography align="center" color="textSecondary" variant="h5">
				RECUPERAR SENHA
			</Typography>
			<TextField label="Email" variant="outlined" type="email" name="email" onChange={handleChange} color="primary" onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} />
			<Button variant="contained" color="primary" onClick={handleData}>ENVIAR</Button>
			<Button variant="text" onClick={changeForgot}>Fazer login</Button>
		</>
	)
}

interface FirstLoginTemplateProps {
	handleChange: (e: React.ChangeEvent<HTMLInputElement>) => void
	handleData: () => void
	disabled: boolean
}

interface FirtsLoginPasswordProps {
	showCurrentPassword: boolean
	showNewPassword: boolean
	showRepeatNewPassword: boolean
}

function FirstLoginTemplate({handleChange, handleData, disabled }: FirstLoginTemplateProps): JSX.Element {
	const [values, setValues] = useState<FirtsLoginPasswordProps>({
		showCurrentPassword: false,
		showNewPassword: false,
		showRepeatNewPassword: false
	})

	const handleClickShowPassword = (v: number) => {
    if (v === 0) {
			setValues({
				...values,
				showCurrentPassword: !values.showCurrentPassword
			})
		} else if (v === 1) {
			setValues({
				...values,
				showNewPassword: !values.showNewPassword
			})
		} else {
			setValues({
				...values,
				showRepeatNewPassword: !values.showRepeatNewPassword
			})
		}
  }

	const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.preventDefault();
  }

	return (
		<>
			<Typography align="center" color="textSecondary" variant="h5">
				DEFINIR NOVA SENHA
			</Typography>
			<TextField 
				label="Senha atual" 
				type={values.showCurrentPassword ? "text" : "password"}
				id="current_password"
				name="current_password" 
				onChange={handleChange} 
				color="primary" 
				onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} 
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {handleClickShowPassword(0)}}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showCurrentPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>
			
			<TextField 
				label="Nova senha" 
				type={values.showNewPassword ? "text" : "password"}
				id="new_password"
				name="new_password" 
				onChange={handleChange} 
				color="primary" 
				onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} 
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {handleClickShowPassword(1)}}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showNewPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>

			<TextField 
				label="Repita a nova senha" 
				type={values.showRepeatNewPassword ? "text" : "password"}
				id="new_password_again"
				name="new_password_again" 
				onChange={handleChange} 
				color="primary" 
				onKeyPress={(e) => {if(e.key === 'Enter') {handleData()}}} 
				InputProps={{
					endAdornment: (
						<InputAdornment position="end">
							<IconButton
								aria-label="toggle password visibility"
								onClick={() => {handleClickShowPassword(2)}}
								onMouseDown={handleMouseDownPassword}
								edge="end"
							>
								{values.showRepeatNewPassword ? <VisibilityOff /> : <Visibility />}
							</IconButton>
						</InputAdornment>
					)
				}}
			/>

			<Button 
				variant="contained" 
				color="primary" 
				onClick={handleData}
				disabled={disabled}
			>
				ENVIAR
			</Button>


		</>
	)
}