import dayjs, { Dayjs } from 'dayjs'
import { LooseObject } from '../types/core'
import { QueryProps } from '../types/query'
import { Dispatch, SetStateAction, createContext, useEffect, useState } from 'react'

interface CoreContextData {
  titleBar?: string
  mobileOpen: boolean
  displayAlt?: string
  countQueries: number
  pathTitleBar?: string
  pathData?: LooseObject
  companyIdQueries: string
  pageNumberQueries: number
  queries: QueryProps[] | undefined
  finalDateQueries: dayjs.Dayjs | null
  initialDateQueries: dayjs.Dayjs | null
  queriesSearch: QueryProps[] | undefined
  setMobileOpen: Dispatch<SetStateAction<boolean>>
  setCountQueries: Dispatch<SetStateAction<number>>
  setCompanyIdQueries: Dispatch<SetStateAction<string>>
  setPageNumberQueries: Dispatch<SetStateAction<number>>
  setQueries: Dispatch<SetStateAction<QueryProps[] | undefined>>
  setTitleBar: Dispatch<React.SetStateAction<string | undefined>>
  setDisplayAlt: Dispatch<React.SetStateAction<string | undefined>>
  setFinalDateQueries: Dispatch<SetStateAction<dayjs.Dayjs | null>>
  setInitialDateQueries: Dispatch<SetStateAction<dayjs.Dayjs | null>>
  setPathTitleBar: Dispatch<React.SetStateAction<string | undefined>>
  setQueriesSearch: Dispatch<SetStateAction<QueryProps[] | undefined>>
  setPathData: Dispatch<React.SetStateAction<LooseObject | undefined>>
}

const CoreContext = createContext<CoreContextData>({} as CoreContextData)

export function CoreProvider({ children }: any) {

  const final_date = new Date()
  const initial_date = new Date()
  initial_date.setDate(initial_date.getDate() - 7)

  const [initialDateQueries, setInitialDateQueries] = useState<Dayjs | null>(
    dayjs(initial_date.toISOString().split('T')[0])
  )
  
  const [finalDateQueries, setFinalDateQueries] = useState<Dayjs | null>(
    dayjs(final_date.toISOString().split('T')[0])
  )
  
  const [mobileOpen, setMobileOpen] = useState(false)
  const [countQueries, setCountQueries] = useState<number>(0)
  const [titleBar, setTitleBar] = useState<string | undefined>()
  const [displayAlt, setDisplayAlt] = useState<string | undefined>()
  const [pathData, setPathData] = useState<LooseObject | undefined>()
  const [queries, setQueries] = useState<QueryProps[] | undefined>([])
  const [companyIdQueries, setCompanyIdQueries] = useState<string>("0")
  const [pageNumberQueries, setPageNumberQueries] = useState<number>(1)
  const [pathTitleBar, setPathTitleBar] = useState<string | undefined>()
  const [queriesSearch, setQueriesSearch] = useState<QueryProps[] | undefined>([])

  useEffect(() => {
  }, [])

  return (
    <CoreContext.Provider value={{
        queries,
        titleBar,
        pathData,
        finalDateQueries,
        setQueries,
        displayAlt,
        mobileOpen,
        setPathData,
        setTitleBar,
        countQueries,
        pathTitleBar,
        setDisplayAlt,
        setMobileOpen,
        queriesSearch,
        setPathTitleBar,
        setCountQueries,
        companyIdQueries,
        setQueriesSearch,
        pageNumberQueries,
        initialDateQueries,
        setFinalDateQueries,
        setCompanyIdQueries,
        setPageNumberQueries,
        setInitialDateQueries
      }}
    >
      {children}
    </CoreContext.Provider>
  )
}

export default CoreContext