import { ChangeEvent } from "react"

export type returnT = {
  file_data: string | ArrayBuffer | null;
  filename: string;
}

export const handleFileRead = async (event: ChangeEvent<HTMLInputElement>, mode = false): Promise< returnT | string | ArrayBuffer | null> => {
  const file = event.target.files![0]
  const base64 = await convertBase64(file)
  if (mode === false) {

    let data = {
      file_data: base64,
      filename: file.name
    }

    return data

  } else {
    return base64
  }
}

const convertBase64 = async (file: File): Promise<string | ArrayBuffer | null> => {
  return new Promise((resolve, reject) => {
    const fileReader = new FileReader();
    fileReader.readAsDataURL(file)
    fileReader.onload = () => {
      resolve(fileReader.result);
    }
    fileReader.onerror = (error) => {
      reject(error);
    }
  })
}