// @ts-nocheck
import { colors } from "@mui/material"
import styled from "styled-components"

export const DropArea = styled.div`
  cursor: pointer;
  display: flex;
  min-height: ${
    props => (
      props.isDropped
        ? `120px`
        : `280px`
    )
  };
  width: 100%;
  font-size: 16pt;
  padding: 20px;
  justify-content: center;
  align-items: center;
  border: ${
    props => (
      props.isDropped
        ? `none`
        : `5px dashed ${colors.grey[500]}`
    )
  };
  border-radius: ${
    props => (
      props.isDropped
        ? `8px`
        : `30px`
    )
  };
  transition: all .5s;
  background-color: ${
    props => (
      props.isDropped
        ? `${colors.green[200]}`
        : `${colors.grey[200]}`
    )
  };
  color: ${
    props => (
      props.isDragActive 
        ? props.isDragReject
          ? `${colors.red[800]}`
          : `${colors.green[800]}`
        : `${colors.grey[800]}`
    )
  };
`