import React from "react";
import { Box, Typography } from '@mui/material';

const logo = require('../../../images/logo.png');
const logoTopo = require('../../../images/logo_blocked_onboarding.png');

export default function Blocked() {
    return (
        <Box
            sx={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
                justifyContent: 'center',
                height: '100vh',
                width: '100%',
                backgroundColor: '#fff',
                border: '2px solid #FF7C70',
                padding: 1,
            }}
        >
            <Box sx={{ textAlign: 'center', marginBottom: 1 }}>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logoTopo} alt="logo" width={'100%'} height="auto" />
                </Box>
                <Typography variant="h5" sx={{ color: '#FF7C70', fontWeight: 'bold', marginTop: '20px' }}>
                    NOVA FUNCIONALIDADE CHEGANDO NA CHECKVIEW....
                </Typography>
                <Typography variant="h6" color="textSecondary">
                    😁🔥🙌🚀
                </Typography>
            </Box>

            <Box
                sx={{
                    padding: 4,
                    borderRadius: 2,
                    width: '100%',
                    textAlign: 'left',
                }}
            >
                <Typography variant="body1" color="textPrimary" gutterBottom>
                    Solicite de forma padronizada a documentação do seu cliente através do Onboarding Checkview.
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                    Configure em poucos cliques o que você deseja solicitar e envie o link via sms, e-mail ou whatsapp aos clientes. Ele recebe o link via webapp, realiza a juntada documental e finaliza o processo. Neste momento você recebe via PDF esta juntada documental, simples, fácil e rápido.
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                    Economize tempo e ofereça aos seus clientes uma jornada digital intuitiva e moderna, aumentando a conversão de negócios e a satisfação dos clientes.
                </Typography>
                <Typography variant="body1" color="textPrimary" gutterBottom>
                    Fale com a nossa área comercial e teste já a versão BETA.
                </Typography>
            </Box>
            <Box
                sx={{
                    textAlign: 'center',
                    marginTop: 4,
                }}
            >
                <Typography variant="h6" sx={{ color: '#FF7C70', fontWeight: 'bold' }}>
                    EM BREVE...
                </Typography>
                <Box sx={{ display: 'flex', justifyContent: 'center' }}>
                    <img src={logo} alt="logo" width={240} height="auto" />
                </Box>
            </Box>
        </Box>
    );
};
