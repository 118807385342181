import { Typography } from "@mui/material"
import { BackgroundSignStyle } from "./styles"

const logo = require('../../images/logo.png')

export default function BackgroundSign() {
  return (
    <BackgroundSignStyle>
      <div className="bg-black">
        <div className="div-card">
          <img src={logo} alt="Logo" width={500} height="auto" />
        </div>
        <Typography align="center" color="white" variant="h5">
          🪄 Informações num passe de mágica
        </Typography>
      </div>
    </BackgroundSignStyle>
  )
}