import 'dayjs/locale/pt-br'
import dayjs, { Dayjs } from 'dayjs'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'


interface InputDatePickerProps {
  label?: string
  value: Dayjs | null
  setValue: any
}

export default function InputDatePicker({ label, value, setValue }: InputDatePickerProps): JSX.Element {

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs} adapterLocale="pt-br">
      <DemoContainer components={['DatePicker']}>
        <DatePicker
          label={label}
          value={dayjs(value)}
          onChange={(newValue) => setValue(dayjs(newValue))}
          format="DD/MM/YYYY"
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}