/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import { Category } from './category'
import Grid from '@mui/material/Grid'
import { toast } from 'react-toastify'
import Button from "@mui/material/Button"
import * as api from '../../../services/api'
import { useParams } from "react-router-dom"
import { ToastContainer } from "react-toastify"
import TextField from '@mui/material/TextField'
import { useCore } from "../../../hooks/useCore"
import DeleteIcon from '@mui/icons-material/Delete'
import { formatMoney } from "../../../utils/format-money"
import { useEffect, useLayoutEffect, useState } from 'react'
import { RetrieveProductProps } from '../../../types/product'
import { LoadingPage } from "../../../components/LoadingPage"
import { ApiProps, ListAllApiProps } from "../../../types/api"
import ElectricalServicesIcon from '@mui/icons-material/ElectricalServices'
import { Avatar, Checkbox, FormControl, FormControlLabel, FormGroup, IconButton, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemButton, ListItemSecondaryAction, ListItemText, MenuItem, Select, SelectChangeEvent } from '@mui/material'

interface Values {
  name: string
  category: string
  description: string
  price: number
  match_price: number
  allow_processing: boolean
  background: boolean
}

interface DataProps extends Values {
  apis: string[]
}

const initialValues: Values = {
  name: '',
  category: '',
  description: '',
  price: 0,
  allow_processing: false,
  match_price: 0,
  background: false
}

export default function CreateOrUpdateProduct(): JSX.Element {
  let { id } = useParams()
  const { setTitleBar, setPathTitleBar } = useCore()

  const [category, setCategory] = useState<string>("1")
  const [apiSelected, setApiSelected] = useState<string>("")
  const [apiList, setApiList] = useState<ApiProps[]>([])
  const [itemList, setItemList] = useState<ApiProps[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const { handleChange, handleSubmit, values, errors, setFieldValue, touched } =  useFormik({
    initialValues: initialValues,
    onSubmit: createOrUpdateProduct,
  })

  const handleCategoryChange = (event: SelectChangeEvent) => {
    setCategory(event.target.value)
  }

  const handleApiSelectedChange = (event: SelectChangeEvent) => {
    setApiSelected(event.target.value)
  }

  async function createOrUpdateProduct() {
    if (itemList.length === 0) {
      return toast.error("Você precisa adicionar pelo menos uma API primeiro.", {
        position: toast.POSITION.TOP_RIGHT
      })
    }

    let data: DataProps = {...values, apis: []}
    data.price = formatMoney(values.price.toString())
    data.match_price = formatMoney(values.match_price.toString())

    if (id) {
      toast.loading("Atualizando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      data.category = category
      // eslint-disable-next-line array-callback-return
      itemList.forEach((item) => {
        data.apis.push(item.id)
      })
  
      var resUpdated = await api.put(`product/update/${id}/`, data) as {[key:string]: any}
      
      toast.dismiss()
      
      if (resUpdated.status === 200) {
        toast.success("Dados atualizados com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    } else {
      toast.loading("Cadastrando...", {
        position: toast.POSITION.TOP_RIGHT,
      })

      data.category = category
      // eslint-disable-next-line array-callback-return
      itemList.forEach((item) => {
        data.apis.push(item.id)
      })
  
      var resCreated = await api.post('product/create/', data) as {[key:string]: any}

      toast.dismiss()
  
      if (resCreated.status === 201) {
        toast.success("Produto cadastrado com sucesso!", {
          position: toast.POSITION.TOP_RIGHT
        })
      } else {
        toast.error("Ops.. Tivemos um problema, por favor tente novamente mais tarde!", {
          position: toast.POSITION.TOP_RIGHT
        })
      }
    }
  }

  function addApiToProduct() {
    const _api = apiList?.filter((item) => item.id.indexOf(apiSelected) > -1)

    if (itemList.filter((item) => item.id.indexOf(apiSelected) > -1).length > 0) {
      return toast.error("Você já adicionou essa API ao produto", {
        position: toast.POSITION.TOP_RIGHT
      })
    } else {
      setItemList([...itemList, _api[0]])
    }
  }

  function removeApiToProduct(id: string) {
    let items = itemList.filter((item) => item.id !== id)
    setItemList(itemList => items)
  }

  const getApis = async () => {
    const response = await api.get(`api/list/all/?category=1`) as ListAllApiProps
    setApiList(response.content)
    setApiSelected(response.content[0].id)
    if (!id) setLoading(false)
  }

  const getProduct = async () => {
    const response = await api.get(`product/retrieve/${id}/`) as RetrieveProductProps
    setCategory(response.content.category)
    setFieldValue('name', response.content.name)
    setFieldValue('category', response.content.category)
    setFieldValue('price', response.content.price__display)
    setFieldValue('background', response.content.background)
    setFieldValue('description', response.content.description)
    setFieldValue('match_price', response.content.match_price__display)
    setFieldValue('allow_processing', response.content.allow_processing)

    let a = []

    for (let i=0; i<apiList.length; i++) {
      if (response.content.apis.indexOf(apiList[i].id) > -1) {
        a.push(apiList[i])
      } 
    }

    if (a.length > 0) setItemList([...itemList, ...a])
    setLoading(false)
  }
  
  useEffect(() => {
    if (id) {
      if (values.name.length > 0) {
        setTitleBar(`Produto - ${values.name}`)
      }
      getProduct().catch(console.error)
    } else {
      setTitleBar('Novo produto')
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [apiList])

  useEffect(() => {
    getApis().catch(console.error)
	}, [])

  useLayoutEffect(() => {
    setPathTitleBar(undefined)
  }, [setPathTitleBar])

	return (
		<>
      <ToastContainer />
      {
        !loading
          ? <form onSubmit={handleSubmit}>
              <Grid 
                container 
                spacing={2} 
                alignItems="center"
                justifyContent="center"
              >
                <Grid 
                  item 
                  md={12} xs={12}
                >
                  <h3>Dados básicos</h3>
                </Grid>
                <Grid item md={6} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="name"
                    name="name"
                    label="Nome"
                    value={values.name}
                    onChange={handleChange}
                    error={touched.name && Boolean(errors.name)}
                    helperText={touched.name && errors.name}
                  />
                </Grid>
                <Grid item md={6} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="category-label">Categoria</InputLabel>
                    <Select
                      fullWidth
                      required={true}
                      labelId="category-label"
                      id="category"
                      name="category"
                      value={category}
                      label="Categoria"
                      onChange={handleCategoryChange}
                    >
                      {Category?.map((item) => (
                        <MenuItem key={`category-${item.id}-menu-item`} value={item.id}>{item.name}</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    multiline
                    rows={4}
                    id="description"
                    name="description"
                    label="Descrição"
                    value={values.description}
                    onChange={handleChange}
                    error={touched.description && Boolean(errors.description)}
                    helperText={touched.description && errors.description}
                  />
                </Grid>
                <Grid
                  item
                  md={12} xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          name="allow_processing"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("allow_processing", true)
                            } else {
                              setFieldValue("allow_processing", false)
                            }
                          }}
                          checked={values.allow_processing}
                          value={values.allow_processing}
                        />
                      }
                      label="Permitir que esser produto seja disponibilizado para processamento em lote"
                    />
                  </FormGroup>
                </Grid>
                <Grid 
                  item 
                  md={12} xs={12}
                >
                  <h3>APIs</h3>
                </Grid>
                <Grid item md={10} xs={12}>
                  <FormControl fullWidth>
                    <InputLabel id="api-label">API</InputLabel>
                    <Select
                      fullWidth
                      labelId="api-label"
                      id="api"
                      name="api"
                      value={apiSelected}
                      label="API"
                      onChange={handleApiSelectedChange}
                    >
                      {apiList?.map((item) => (
                        <MenuItem key={`api-list-${item.id}-menu-item`} value={item.id}>{item.name} ({item.provider_name})</MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </Grid>
                <Grid 
                  item 
                  md={2} xs={12}
                  alignItems="center" 
                  alignContent="center"
                >
                  <Button 
                    variant="contained" 
                    size="large" 
                    color="primary" 
                    type="button"
                    onClick={() => {addApiToProduct()}}
                    fullWidth
                  >
                    Adicionar
                  </Button>
                </Grid>
                <Grid 
                  item 
                  md={12} xs={12}
                >
                  <p>Abaixo é listado as APIs que fazem parte do seu produto (a ordem adicionada será a ordem de execução)</p>
                </Grid>
                <List dense disablePadding sx={{ width: '100%' }}>
                  {
                    itemList.length > 0 
                    ? itemList.map((item) => (
                        <ListItem 
                          key={`${item.id}-item-a`}
                          disablePadding 
                        >
                          <ListItemButton key={`${item.id}-item-b`}>
                            <ListItemAvatar key={`${item.id}-avatar`}>
                              <Avatar>
                                <ElectricalServicesIcon />
                              </Avatar>
                            </ListItemAvatar>
                            <ListItemText key={`${item.id}-text`} primary={`${item.name} (${item.provider_name})`} secondary={item.description} />
                          </ListItemButton>
                          <ListItemSecondaryAction key={`${item.id}-secondary`}>
                            <IconButton edge="end" aria-label="delete" onClick={() => {removeApiToProduct(item.id)}}>
                              <DeleteIcon />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    : <></>
                  }
                </List>
                <Grid
                  item
                  md={12} xs={12}
                >
                  <FormGroup>
                    <FormControlLabel
                      control={
                        <Checkbox 
                          name="background"
                          onChange={(e) => {
                            if (e.target.checked) {
                              setFieldValue("background", true)
                            } else {
                              setFieldValue("background", false)
                            }
                          }}
                          value={values.background}
                          checked={values.background}
                        />
                      }
                      label="Alguma API possui retorno por webhook?"
                    />
                  </FormGroup>
                </Grid>
                <Grid 
                  item 
                  md={12} xs={12}
                >
                  <h3>Preço</h3>
                  <p>Defina abaixo o preço padrão deste produto</p>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    required={true}
                    id="price"
                    name="price"
                    label="Preço"
                    value={values.price}
                    onChange={handleChange}
                    error={touched.price && Boolean(errors.price)}
                    helperText={touched.price && errors.price}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid 
                  item 
                  md={12} xs={12}
                >
                  <h3>Preço do match</h3>
                  <p>Defina abaixo o preço padrão do match deste produto</p>
                </Grid>
                <Grid item md={12} xs={12}>
                  <TextField
                    fullWidth
                    id="match_price"
                    label="Preço"
                    required={true}
                    name="match_price"
                    value={values.match_price}
                    onChange={handleChange}
                    error={touched.match_price && Boolean(errors.match_price)}
                    helperText={touched.match_price && errors.match_price}
                    InputLabelProps={{
                      shrink: true
                    }}
                    InputProps={{
                      startAdornment: <InputAdornment position="start">R$</InputAdornment>,
                    }}
                  />
                </Grid>
                <Grid 
                  item 
                  alignItems="center" 
                  alignContent="center"
                >
                  <Button variant="contained" size="large" color="success" type="submit">
                    { id ? 'Atualizar' : 'Cadastrar'}
                  </Button>
                </Grid>
              </Grid>
            </form>
          : <LoadingPage />
      }
		</>
	)
}
