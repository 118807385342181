type ValueTypeProps = {
  id: string
  name: string
}

export const ValuesTypes = [
  {
    "id": "1",
    "name": "Texto"
  },
  {
    "id": "2",
    "name": "Inteiro"
  },
  {
    "id": "3",
    "name": "Booleano"
  },
  {
    "id": "4",
    "name": "Imagem"
  },
  {
    "id": "5",
    "name": "PDF"
  },
  {
    "id": "6",
    "name": "Qualquer arquivo"
  },
] as ValueTypeProps[]

export const dysplayValueType = {
  '1': 'Texto',
  '2': 'Inteiro',
  '3': 'Booleano',
  '4': 'Imagem',
  '5': 'PDF',
  '6': 'Qualquer arquivo'
}

export const inputValueType = {
  '1': 'text',
  '2': 'number',
  '3': 'checkbox',
  '4': 'file',
  '5': 'file',
  '6': 'file'
}

export const notTextOrNumber = [
  '3', '4', '5', '6'
]