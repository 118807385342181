import './styles.css'
import { memo, useState } from "react";

interface propsI {
    done: string
	match: boolean
}

const Progress = ({done, match}: propsI ) => {
	const [style, setStyle] = useState({});
	
	setTimeout(() => {
		const newStyle = {
			opacity: 1,
			width: `${done}%`,
			background: match === true ? 'linear-gradient(to right, green, rgb(57 215 57))' : 'linear-gradient(to right, red, #f18181)'
		}
		
		setStyle(newStyle);
	}, 200);
	
	return (
		<div className="progress" style={{textAlign: 'center'}}>
			{
				parseInt(done) < 10
					?<div style={{height: '100%', width: '100%', position: 'absolute', display: 'flex', alignItems: 'center', justifyContent: 'center'}}>{done}%</div>
					: <></>
			}
			<div className="progress-done" style={style}>
				{parseInt(done) >= 10 ? `${done}%` : ''}
			</div>
		</div>
	)
}

const ProgressMemorized = memo(Progress)

export default ProgressMemorized