type CategoryProps = {
  id: string
  name: string
}

export const Categories = [
  {
    "id": "1",
    "name": "Comum"
  },
  {
    "id": "2",
    "name": "Token Dinâmico"
  },
] as CategoryProps[]