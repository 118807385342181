import { v4 as uuid } from 'uuid';
import JsonFormatter from "react-json-formatter";
import { Box, Button, Grid, Modal, Typography } from "@mui/material";

type jsonStyleT = {
    propertyStyle: {
        color: string;
    };
    stringStyle: {
        color: string;
    };
    numberStyle: {
        color: string;
    };
}

type jsonT =  {
    step: 3 | 2;
    data: {};
} | null

interface propsI {
    json: jsonT
    jsonStyle: jsonStyleT
    modalReturnOpened: boolean
    setModalReturnOpened: React.Dispatch<React.SetStateAction<boolean>>
}

export default function ModalReturn({modalReturnOpened, setModalReturnOpened, json, jsonStyle}:propsI) {
    return (
        <Modal
            open={modalReturnOpened}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={{
                position: 'absolute',
                top: '50%',
                left: '50%',
                transform: 'translate(-50%, -50%)',
                width: '90%',
                bgcolor: 'background.paper',
                boxShadow: 24,
                p: 4,
            }}>
                <div style={{overflow: 'scroll', maxHeight: '500px'}}>
                    <Typography id="modal-modal-title" variant="h5" component="h2" textAlign="center">
                        Resultado da ultima requisição:
                    </Typography>
                    <Typography key={uuid()} style={{ marginBottom: '5px', wordBreak: 'break-all' }}><JsonFormatter json={json?.data} jsonStyle={jsonStyle} /></Typography>
                </div>
                <Grid
                    gap={3}
                    container
                    marginTop={3}
                    alignItems="center"
                    justifyContent="center">
                    <Button 
                        variant="outlined" 
                        size="large" 
                        color="info" 
                        onClick={() => setModalReturnOpened(false)}
                    >
                        Fechar
                    </Button>
                </Grid>
            </Box>
        </Modal>
    )
}