import { IProducts } from "..";
import { useState } from "react";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import * as api from "../../../services/api"
import { LooseObject } from "../../../types/core";
import ImageIcon from '@mui/icons-material/Image';
import { LightMode } from "../../../styles/lightMode";
import { ParameterProps } from "../../../types/parameter";
import { handleFileRead } from "../../../utils/get-base64";
import UploadFileIcon from '@mui/icons-material/UploadFile';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import { inputValueType, notTextOrNumber } from "../../Api/CreateOrUpdateApi/value_type";
import { Box, Button, Checkbox, FormControlLabel, FormGroup, Grid, Modal, TextField, Typography } from "@mui/material";

interface propsI {
    step: any
    state: any
    ids: string[]
    modalOpened: boolean
    currentProducts: IProducts[]
    setIds: React.Dispatch<React.SetStateAction<string[]>>
    setModalOpened: React.Dispatch<React.SetStateAction<boolean>>
    setModalReturnOpened: React.Dispatch<React.SetStateAction<boolean>>
    setReturnRequisitionStep2: React.Dispatch<React.SetStateAction<{ step: 2; data: {}; } | null>>
}

const ModalRequisition = ({ modalOpened, state, setModalOpened, step, currentProducts, setReturnRequisitionStep2, setModalReturnOpened, ids, setIds }: propsI) => {
    const [buttonDisabled, setButtonDisabled] = useState(false)

    const submit = async (values: any) => {
        const data = {
            "products": currentProducts.map((product) => {
                return product.id
            }),
            "rescue": values.rescue ?? false,
            "data": {}
        }

        let _parameters: LooseObject = {}

        state.originalParameters.forEach((originalItem: ParameterProps) => {
            state.parameters.forEach((item: ParameterProps) => {
                if (item.description === originalItem.description) {
                    _parameters[`${originalItem.name}|${originalItem.api_id}`] = {
                        'value': !values[`${item.name}|${item.api_id}`] && item.value_type === "3" ? item.boolean_default : values[`${item.name}|${item.api_id}`] ?? '',
                    }
                }
            })
        })

        data.data = _parameters

        const response = await api.post('call/query/', data) as { [key: string]: any }

        if (response.status === 201) {
            toast.dismiss()
            toast.success('Requisição feita, segue os dados retornados.')
            setButtonDisabled(false)
            setReturnRequisitionStep2({ step: step.value, data: response.data })
            setModalOpened(false)
            setModalReturnOpened(true)
            setIds([...ids, response.data.content.id])
        } else {
            toast.dismiss()
            toast.error('Verifique os campos e tente novamente mais tarde')
            setButtonDisabled(false)
        }
    }

    const { handleChange, handleSubmit, values, setFieldValue, resetForm } = useFormik({
        initialValues: state.initialValues,
        onSubmit: submit
    })


    return (
        <Modal
            open={modalOpened}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box
                sx={
                    {
                        p: 4,
                        top: '50%',
                        left: '50%',
                        width: '70%',
                        boxShadow: 24,
                        position: 'absolute',
                        bgcolor: 'background.paper',
                        transform: 'translate(-50%, -50%)',
                    }
                }
            >
                <Typography marginBottom={3} id="modal-modal-title" variant="h5" component="h2" textAlign="center">
                    Preencha os dados solicitados - <strong style={{ color: LightMode.info.main }}>Campos com * são obrigatórios</strong>
                </Typography>
                <form
                    onSubmit={(e) => {
                        toast.loading('Aguardando resposta do servidor', {
                            toastId: 'loading-requisition'
                        })
                        setButtonDisabled(true)
                        handleSubmit(e)
                    }}
                >
                    <div
                        style={
                            {
                                gap: '10px',
                                width: '100%',
                                padding: '10px',
                                display: 'flex',
                                overflowY: 'auto',
                                maxHeight: '400px',
                                flexDirection: 'column'
                            }
                        }
                    >
                        {
                            state.parameters.map((parameter: ParameterProps) => {
                                return (
                                    <Grid
                                        key={`parameter-${parameter.name}-${parameter.api_id}`}
                                        item
                                        md={12} xs={12}
                                    >
                                        {
                                            notTextOrNumber.indexOf(parameter.value_type) === -1
                                                ? <TextField
                                                    fullWidth
                                                    label={parameter.description}
                                                    type={inputValueType[parameter.value_type]}
                                                    required={parameter.required}
                                                    name={`${parameter.name}|${parameter.api_id}`}
                                                    onChange={handleChange}
                                                />
                                                : parameter.value_type === '3'
                                                    ? <FormGroup>
                                                        <FormControlLabel
                                                            control={
                                                                <Checkbox
                                                                    name={`${parameter.name}|${parameter.api_id}`}
                                                                    onChange={(e) => {
                                                                        if (e.target.checked) {
                                                                            setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_value)
                                                                        } else {
                                                                            setFieldValue(`${parameter.name}|${parameter.api_id}`, parameter.boolean_default)
                                                                        }
                                                                    }}
                                                                />
                                                            }
                                                            label={parameter.description}
                                                        />
                                                    </FormGroup>
                                                    : <FormGroup>
                                                        {
                                                            parameter.value_type === '4'
                                                                ? <label
                                                                    className={
                                                                        `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                                    }
                                                                    htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                                >
                                                                    <ImageIcon /> {
                                                                        values[`${parameter.name}|${parameter.api_id}`]
                                                                            ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                                            : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                                    }
                                                                    <input
                                                                        style={{ display: "none" }}
                                                                        id={`${parameter.name}|${parameter.api_id}`}
                                                                        type="file"
                                                                        name={parameter.name}
                                                                        onChange={async (e) => {
                                                                            if (e.target.files) {
                                                                                let base64 = await handleFileRead(e)
                                                                                setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                                            } else {
                                                                                setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                            }
                                                                        }}
                                                                        accept='image/*'
                                                                    />
                                                                </label>
                                                                : parameter.value_type === '5'
                                                                    ? <label
                                                                        className={
                                                                            `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                                        }
                                                                        htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                                    >
                                                                        <PictureAsPdfIcon /> {
                                                                            values[`${parameter.name}|${parameter.api_id}`]
                                                                                ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                                                : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                                        }
                                                                        <input
                                                                            style={{ display: "none" }}
                                                                            id={`${parameter.name}|${parameter.api_id}`}
                                                                            type="file"
                                                                            name={parameter.name}
                                                                            onChange={async (e) => {
                                                                                if (e.target.files) {
                                                                                    let base64 = await handleFileRead(e)
                                                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                                                } else {
                                                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                                }
                                                                            }}
                                                                            accept='application/pdf'
                                                                        />
                                                                    </label>
                                                                    : <label
                                                                        className={
                                                                            `label-input-file ${values[`${parameter.name}|${parameter.api_id}`] ? "label-input-file-success" : "label-input-file-default"}`
                                                                        }
                                                                        htmlFor={`${parameter.name}|${parameter.api_id}`}
                                                                    >
                                                                        <UploadFileIcon /> {
                                                                            values[`${parameter.name}|${parameter.api_id}`]
                                                                                ? values[`${parameter.name}|${parameter.api_id}`]['filename']
                                                                                : `Clique aqui e ${parameter.description} ${parameter.required ? "*" : ""}`
                                                                        }
                                                                        <input
                                                                            style={{ display: "none" }}
                                                                            id={`${parameter.name}|${parameter.api_id}`}
                                                                            type="file"
                                                                            name={parameter.name}
                                                                            onChange={async (e) => {
                                                                                if (e.target.files) {
                                                                                    let base64 = await handleFileRead(e)
                                                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, base64)
                                                                                } else {
                                                                                    setFieldValue(`${parameter.name}|${parameter.api_id}`, '')
                                                                                }
                                                                            }}
                                                                        />
                                                                    </label>
                                                        }
                                                    </FormGroup>
                                        }
                                    </Grid>
                                )
                            })
                        }
                    </div>

                    <Grid
                        container
                        gap={3}
                        marginTop={3}
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Button
                            size="large"
                            color="info"
                            variant="outlined"
                            disabled={buttonDisabled}
                            onClick={() => { setModalOpened(!modalOpened); resetForm() }}
                        >
                            Fechar
                        </Button>
                        <Button
                            size="large"
                            color="info"
                            type="submit"
                            variant="contained"
                            disabled={buttonDisabled}
                        >
                            Testar
                        </Button>
                    </Grid>
                </form>
            </Box>
        </Modal>
    )
}

export default ModalRequisition 