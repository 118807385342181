import styled from "styled-components";

export const AsideStyled = styled.aside`

    font-weight: 300;
    position: relative;
    padding: 25px 40px;
    padding-left: 80px;
    margin: 10px 0;
    max-width: 100%;
    min-height: 170px;
    border-radius: 5px;
    display: flex;
    align-items: center;
    background: linear-gradient(to right, #f7cabd 0%, #f3b6a5 100%);

    & svg:not([class]) {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        opacity: 0.5;
    }

    .aside__icon {
        position: absolute;
        left: 20px;
    }

    .aside__content {
        position: relative;
        z-index: 1;
    }
    
    strong {
        font-size: 24px;
        font-weight: 600;
    }

    ul {
        margin: 1em 0;
    }
`