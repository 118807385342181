import styled from "styled-components";

export const ContainerStyled = styled.div`
    gap: 1rem;
    width: 100%;
    display: flex;
    height: max-content;
    flex-direction: column;

    strong{
        font-size: 1.1rem;
    }

    .w-tc-editor {
        background-color: #D2D3D5 !important;
    }
`