import * as api from '../../../services/api'
import { useCore } from "../../../hooks/useCore"
import SearchIcon from '@mui/icons-material/Search'
import MemoryIcon from '@mui/icons-material/Memory'
import { LightMode } from "../../../styles/lightMode"
import { toast, ToastContainer } from "react-toastify"
import MoreVertIcon from '@mui/icons-material/MoreVert'
import useWindowSize from "../../../hooks/useWindowSize"
import { Paginator } from "../../../components/Paginator"
import { ProcessingProps } from "../../../types/processing"
import DownloadingIcon from '@mui/icons-material/Downloading'
import { LoadingPage } from "../../../components/LoadingPage"
import CloudDownloadIcon from '@mui/icons-material/CloudDownload'
import { useEffect, useState, MouseEvent, useLayoutEffect } from "react"
import { Avatar, FormControl, IconButton, Input, InputAdornment, InputLabel, List, ListItem, ListItemAvatar, ListItemText, Menu, MenuItem } from '@mui/material'

export default function ListProcessing(): JSX.Element {
  const { width } = useWindowSize()
  const { setTitleBar, setPathTitleBar } = useCore()

  const [open, setOpen] = useState<string>()
  const [count, setCount] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [pageNumber, setPageNumber] = useState<number>(1)
  const [anchorEl, setAncjorEl] = useState<null | HTMLElement>(null)
  const [listProcessing, setListProcessing] = useState<ProcessingProps[]>()
  const [listProcessingSearch, setListProcessingSearch] = useState<ProcessingProps[]>()

  const handleClick = (event: MouseEvent<HTMLElement>, value?: string) => {
    setOpen(value)
    setAncjorEl(event.currentTarget)
  }
  const handleClose = () => {
    setOpen(undefined)
    setAncjorEl(null)
  }

  async function getListProcessing(url: string) {
    await api.get(url).then((response: any) => {
      setListProcessing(response.content.results)
      setListProcessingSearch(response.content.results)
      setCount(response.content.count)
      setLoading(false)
    }).catch((error) => {
      toast.error(
        "Infelizmente falhamos desta vez :( Eu realmente sinto muito. Mas você pode tantar novamente mais tarde se quiser :)",
        { position: toast.POSITION.TOP_RIGHT }
      )
    })
  }

  const handleChangePage = async (event: React.ChangeEvent<unknown>, value: number) => {
    setLoading(true)
    setPageNumber(value)
    await getListProcessing(`processing/list/?page=${value}`)
  }

  const handleChangeSearch = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = e.target.value
    search(value)
  }

  function search(value: string) {
    setListProcessingSearch(
      listProcessing?.filter(
        (item) => item.id.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.created_at.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.get_status_display.toLowerCase().indexOf(value.toLowerCase()) > -1 || item.product__name.toLowerCase().indexOf(value.toLowerCase()) > -1
      )
    )
  }

  async function downloadFinalFile(id: string) {
    await api.get(
      `processing/retrieve/${id}/`, 'blob'
    ).then((response: any) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `lote_${id}_processado.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  async function downloadInputFile(id: string) {
    await api.get(
      `processing/retrieve_input/${id}/`, 'blob'
    ).then((response: any) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `lote_${id}.csv`)
      document.body.appendChild(link)
      link.click()
      document.body.removeChild(link)
      URL.revokeObjectURL(href)
    })
  }

  useEffect(() => {
    getListProcessing('processing/list/')
  }, [])

  useLayoutEffect(() => {
    setTitleBar('Novo processamento')
    setPathTitleBar('/new-processing')
  }, [setPathTitleBar, setTitleBar])

  return (
    <>
      <ToastContainer />
      {
        !loading
          ? <>
            <List dense disablePadding sx={{ width: '100%' }}>
              <ListItem
                key='search'
                disablePadding
              >
                <FormControl fullWidth sx={{ m: 1 }} variant="standard">
                  <InputLabel htmlFor="standard-adornment-amount">Pesquisar</InputLabel>
                  <Input
                    id="input-search"
                    startAdornment={
                      <InputAdornment position="start">
                        <SearchIcon />
                      </InputAdornment>
                    }
                    onChange={handleChangeSearch}
                  />
                </FormControl>
              </ListItem>
              {listProcessingSearch?.map((item) => (
                <ListItem
                  key={`process-${item.id}`}
                  disablePadding
                  secondaryAction={
                    <div>
                      <IconButton
                        aria-controls={`basic-menu-${item.id}`}
                        aria-haspopup="true"
                        aria-expanded={open === item.id ? 'true' : undefined}
                        onClick={(e) => handleClick(e, item.id)}
                      >
                        <MoreVertIcon />
                      </IconButton>
                      <Menu
                        anchorEl={anchorEl}
                        open={open === item.id}
                        onClose={handleClose}
                      >
                        <MenuItem onClick={() => downloadInputFile(item.id)}>
                          <CloudDownloadIcon sx={{ marginRight: 1 }} />
                          Baixar arquivo original
                        </MenuItem>
                        <MenuItem disabled={item.status !== '3'} onClick={() => downloadFinalFile(item.id)}>
                          <DownloadingIcon sx={{ marginRight: 1 }} />
                          Baixar arquivo processado
                        </MenuItem>
                      </Menu>
                    </div>
                  }
                >
                  {
                    width > 720
                      ? <ListItemAvatar>
                        <Avatar>
                          <MemoryIcon />
                        </Avatar>
                      </ListItemAvatar>
                      : <></>
                  }
                  <ListItemText
                    primary={
                      <>
                        <strong>{item.product__name}</strong>
                        <br />
                        <strong style={{
                          color: `${item.status === "1"
                            ? LightMode.primary.main
                            : item.status === "2"
                              ? LightMode.warning.main
                              : item.status === "3"
                                ? LightMode.success.main
                                : LightMode.error.main}`
                        }}>
                          ({item.get_status_display})
                        </strong>
                      </>
                    }
                    secondary={
                      <>
                        Criado em {item.created_at}
                        <br />
                        Última atualização em {item.updated_at}
                      </>
                    }
                    sx={{ marginRight: 7 }}
                  />
                </ListItem>
              ))}
            </List>
            <Paginator count={count} pageNumber={pageNumber} onChange={handleChangePage} />
          </>
          : <LoadingPage />
      }
    </>
  )
}