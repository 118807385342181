type ProfileProps = {
  id: string
  name: string
}

export const Profiles: ProfileProps[] = [
  {
    "id": "1",
    "name": "Usuário comum"
  },
  {
    "id": "2",
    "name": "Representante da empresa"
  },
  {
    "id": "3",
    "name": "Administrador do sistema"
  },
]

export const dysplayProfile = {
  '1': 'Usuário comum',
  '2': 'Representante da empresa',
  '3': 'Administrador do sistema'
}