import { memo } from "react"
import { useCore } from "../../../hooks/useCore"
import { useAuth } from "../../../hooks/useAuth"
import { Link, useNavigate } from "react-router-dom"
import MonetizationOnIcon from '@mui/icons-material/MonetizationOn'
import ContentPasteSearchIcon from '@mui/icons-material/ContentPasteSearch'
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings'
import { dysplayProfile } from "../../../pages/User/CreateOrUpdateUser/profiles"
import { AdministrationMenu, FinancialMenu, QueriesMenu } from "../../../pages/Core/menu"
import { Box, Divider, Drawer, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Toolbar, Typography } from "@mui/material"

const logo = require('../../../images/logo.png')

interface SideBarProps {
  window?: () => Window
}

function SideBar({ window }: SideBarProps): JSX.Element {
  const drawerWidth = 300
  const navigate = useNavigate()
  const container = window !== undefined ? () => window().document.body : undefined

  const { user } = useAuth()
  const { mobileOpen, setMobileOpen } = useCore()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  const CustomDrawer = (
    <div>
      <Toolbar>
        <Link to={`/`}>
          <img src={logo} alt="logo" width={240} height="auto" />
        </Link>
      </Toolbar>
      <Divider />
      <Toolbar variant="dense">
        <Typography variant="caption">
          <strong>{user?.name}</strong>
          <br />
          - {user?.corporate_name?.toUpperCase()}
          <br />
          {user?.profile ? dysplayProfile[user?.profile] : ''}
        </Typography>
      </Toolbar>
      <Divider />
      <Toolbar>
        <ListItemIcon key="queries-icon">
          <ContentPasteSearchIcon />
        </ListItemIcon>
        Consultas
      </Toolbar>
      <List>
        {QueriesMenu.map((item) => (
          <ListItem key={`queries-${item.name.replaceAll(" ", "-").toLowerCase()}-item`} disablePadding style={{display: item.path === '/check-match' && !user?.checkmatch ? 'none' : 'block'}}>
            <Link to={item.path} className="w-100">
              <ListItemButton 
                key={`queries-${item.name.replaceAll(" ", "-").toLowerCase()}-button`}
              >
                <ListItemText 
                  key={`queries-${item.name.replaceAll(" ", "-").toLowerCase()}-text`} 
                  primary={item.name}
                />
              </ListItemButton>
            </Link>
          </ListItem>
        ))}
      </List>
      <Divider />
      {
        user?.profile === "3"
          ? <>
            <Toolbar>
              <ListItemIcon key="administration-icon">
                <AdminPanelSettingsIcon />
              </ListItemIcon>
              Administração
            </Toolbar>
            <List>
              {AdministrationMenu.map((item) => (
                <ListItem key={`administration-${item.name.replaceAll(" ", "-").toLowerCase()}-item`} disablePadding>
                  <Link to={item.path} className="w-100">
                    <ListItemButton key={`administration-${item.name.replaceAll(" ", "-").toLowerCase()}-button`}>
                      <ListItemText key={`administration-${item.name.replaceAll(" ", "-").toLowerCase()}-text`} primary={item.name} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
          : user?.profile === "2"
              ? <>
                  <Toolbar>
                    <ListItemIcon key="administration-icon">
                      <AdminPanelSettingsIcon />
                    </ListItemIcon>
                    Usuários
                  </Toolbar>
                  <List>
                  <ListItem disablePadding>
                    <ListItemButton
                      onClick={
                        () => {
                          navigate(
                            `/users/${user.corporate_id}`, 
                            {state: {fantasy_name: user.corporate_name}}
                          )
                        }
                      }
                      className="w-100"
                    >
                      <ListItemText primary="Usuários" />
                    </ListItemButton>
                  </ListItem>
                  </List>
                  <Divider />
                </>
              : <></>
      }
      {
        user?.profile === "3"
          ? <>
            <Toolbar>
              <ListItemIcon key="financial-icon">
                <MonetizationOnIcon />
              </ListItemIcon>
              Financeiro
            </Toolbar>
            <List>
              {FinancialMenu.map((item) => (
                <ListItem key={`financial-${item.name.replaceAll(" ", "-").toLowerCase()}-item`} disablePadding>
                  <Link to={item.path} className="w-100">
                    <ListItemButton key={`financial-${item.name.replaceAll(" ", "-").toLowerCase()}-button`}>
                      <ListItemText key={`financial-${item.name.replaceAll(" ", "-").toLowerCase()}-text`} primary={item.name} />
                    </ListItemButton>
                  </Link>
                </ListItem>
              ))}
            </List>
            <Divider />
          </>
          : <></>
      }
      {/* <Toolbar>
        <ListItemIcon key="theme-icon">
          {theme.palette.mode === 'dark' ? <Brightness7Icon /> : <Brightness4Icon />}
        </ListItemIcon>
        Tema
      </Toolbar>
      <ListItem key="theme-item" disablePadding>
        <ListItemButton key="theme-button" onClick={colorMode.toggleColorMode}>
          <ListItemText  key="theme-text" primary={`Ativar modo ${mode === 'light' ? 'dark' : 'light'}`} />
        </ListItemButton>
      </ListItem> */}
    </div>
  )

  return (
    <Box
      component="nav"
      sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
      aria-label="mailbox folders"
    >
      <Drawer
        container={container}
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: 'block', sm: 'none' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
      >
        {CustomDrawer}
      </Drawer>
      <Drawer
        variant="permanent"
        sx={{
          display: { xs: 'none', sm: 'block' },
          '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
        }}
        open
      >
        {CustomDrawer}
      </Drawer>
    </Box>
  )
}

export default memo(SideBar)