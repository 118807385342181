import { memo } from 'react'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import MenuIcon from '@mui/icons-material/Menu'
import { useAuth } from '../../../hooks/useAuth'
import { useCore } from "../../../hooks/useCore"
import LogoutIcon from '@mui/icons-material/Logout'
import { AppBar, Grid, IconButton, Toolbar, Typography } from "@mui/material"

const drawerWidth = 300

function NavBar(): JSX.Element {
  const { Logout } = useAuth()
  const navigate = useNavigate()
  const { titleBar, pathTitleBar, pathData, mobileOpen, setMobileOpen } = useCore()

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  }

  async function handleLogout() {
    await Logout()
    navigate('/')
  }

  return (
    <AppBar
      position="fixed"
      sx={{
        width: { sm: `calc(100% - ${drawerWidth}px)` },
        ml: { sm: `${drawerWidth}px` },
      }}
    >
      <Toolbar>
        <Grid container>
          <Grid item xs={2}>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2, display: { sm: 'none' } }}
            >
              <MenuIcon />
            </IconButton>
          </Grid>
          <Grid item md={11} xs={8}>
            <Typography variant="h6" noWrap component="div" sx={{ mt: .5 }}>
              {titleBar}
              {
                pathTitleBar
                  ? <IconButton
                    color="inherit"
                    aria-label="add"
                    edge="start"
                    sx={{ ml: 5 }}
                    onClick={
                      () => {
                        navigate(
                          `${pathTitleBar}`,
                          { state: pathData }
                        )
                      }
                    }
                  >
                    <AddIcon />
                  </IconButton>
                  : <></>
              }
            </Typography>
          </Grid>
          <Grid item md={1} xs={2}>
            <IconButton
              color="inherit"
              aria-label="logout"
              edge="start"
              onClick={handleLogout}
              sx={{ ml: 5 }}
            >
              <LogoutIcon />
            </IconButton>
          </Grid>
        </Grid>
      </Toolbar>
    </AppBar>
  )
}

export default memo(NavBar)