import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import Modal from '@mui/material/Modal'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import CircularProgress from '@mui/material/CircularProgress'

const style = {
  position: 'absolute' as 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '80',
  bgcolor: 'background.paper',
  boxShadow: 24,
  p: 4,
}

type ModalDeleteProps = {
  open: boolean
  textBody: string
  handleModalClose: (() => void)
  handleDelete: (() => void)
  loading: boolean
}

export default function ModalDelete({ open, textBody, handleModalClose, handleDelete, loading }: ModalDeleteProps) {
  return (
    <Modal
      open={open}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"  
    >
      <Box sx={style}>
        <Typography id="modal-modal-title" variant="h5" component="h2" textAlign="center">
          {
            loading ? "AGUARDE..." : "ATENÇÃO"
          }
        </Typography>
        <Typography id="modal-modal-description" sx={{ mt: 2 }} textAlign="center">
          {
            loading ? <CircularProgress color="secondary" /> : textBody
          }
        </Typography>
        {
          loading ? 
            <></> :
            <Grid 
              container 
              gap={3}
              marginTop={3}
              alignItems="center"
              justifyContent="center">
              <Button variant="contained" size="large" sx={{ color: 'white', backgroundColor: 'red'}} onClick={handleModalClose}>
                Não
              </Button>
              <Button variant="contained" size="large" sx={{ color: 'white', backgroundColor: 'green'}} onClick={handleDelete}>
                Sim
              </Button>
            </Grid> 
        }
      </Box>
    </Modal>
  )
}