export type MenuProps = {
  name: string
  path: string
}

export const FinancialMenu = [
  {
    name: 'Fatura',
    path: '/generate/invoice',
  }
] as MenuProps[]

export const QueriesMenu = [
  {
    name: 'Onboarding',
    path: '/onboarding'
  },
  {
    name: 'Nova consulta',
    path: '/',
  },
  {
    name: 'Histórico de Consultas',
    path: '/queries',
  },
  {
    name: 'Check Match',
    path: '/check-match'
  },
  {
    name: 'Processamentos',
    path: '/list-processing',
  },
  {
    name: 'Integração com API',
    path: '/documentation'
  }
  // {
  //   name: 'Relatórios',
  //   path: '/queries',
  // }
] as MenuProps[]

export const AdministrationMenu = [
  // {
  //   name: 'Usuários de sistema',
  //   path: '/users'
  // },
  {
    name: 'Produtos',
    path: '/products'
  },
  {
    name: 'Empresas',
    path: '/companies',
  },
  {
    name: 'Fornecedores',
    path: '/providers',
  },
] as MenuProps[]