import { Backdrop, CircularProgress, Grid, Skeleton, Typography } from "@mui/material"

export function LoadingPage(): JSX.Element {
  return (
    <>
      <Backdrop
        sx={{ color: '#fff', gap: 2, zIndex: (theme) => theme.zIndex.drawer + 1 }}
        open={true}
      >
        <CircularProgress color="inherit" /> <Typography variant="h5">AGUARDE...</Typography>
      </Backdrop>
      <Grid 
        container 
        spacing={2} 
        alignItems="center"
        justifyContent="center"
        alignContent="center"
        marginY={2}
      >
        <Grid item xl={12} md={12} sm={12} xs={12}>
          <Skeleton variant="text" height={100} animation="wave" />
          <Skeleton variant="text" height={100} animation="wave" />
        </Grid>
        <Grid item md={8} xs={12}>
          <Skeleton variant="text" height={80} animation="wave" />
          <Skeleton variant="text" height={80} animation="wave" />
          <Skeleton variant="text" height={80} animation="wave" />
        </Grid>
        <Grid item md={4} xs={12}>
          <Skeleton variant="rectangular" height={200} animation="wave" />
        </Grid>
        <Grid item md={12} xs={12}>
          <Skeleton variant="text" height={80} animation="wave" />
          <Skeleton variant="text" height={80} animation="wave" />
          <Skeleton variant="text" height={80} animation="wave" />
          <Skeleton variant="text" height={80} animation="wave" />
        </Grid>
      </Grid>
    </>
  )
}