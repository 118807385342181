/* eslint-disable react-hooks/exhaustive-deps */
import { useFormik } from 'formik'
import Grid from '@mui/material/Grid'
import { BiBlock } from "react-icons/bi"
import Button from "@mui/material/Button"
import * as api from '../../../services/api'
import { useDropzone } from 'react-dropzone'
import { FaPaperPlane } from "react-icons/fa"
import { useCore } from "../../../hooks/useCore"
import { SiMicrosoftexcel } from "react-icons/si"
import { toast, ToastContainer } from "react-toastify"
import { useEffect, useLayoutEffect, useState } from 'react'
import { LoadingPage } from "../../../components/LoadingPage"
import { DropArea } from "../../../components/DropArea/styles"
import { ListParameter, ParameterProps } from "../../../types/parameter"
import { dysplayValueType } from "../../Api/CreateOrUpdateApi/value_type"
import { ListAllProductProps, ProductProps } from '../../../types/product'
import { Alert, MenuItem, Paper, Select, SelectChangeEvent, Table, TableBody, TableCell, TableContainer, TableHead, TableRow } from '@mui/material'

export default function NewProcessing(): JSX.Element {
  const { setTitleBar, setPathTitleBar } = useCore()

  const [file, setFile] = useState<File>()
  const [loading, setLoading] = useState(true)
  const [click, setClick] = useState(false)
  const [isDropped, setIsDropped] = useState(false)
  const [products, setProducts] = useState<ProductProps[]>([])
  const [parameters, setParameters] = useState<ParameterProps[]>([])
  const { handleSubmit, values, setFieldValue } = useFormik({
    initialValues: {
      product: 'select-product',
    },
    onSubmit: createNewProcessing,
  })

  function setArchiveList(acceptedFile: any) {
    setFile(acceptedFile[0])
  }

  const { getRootProps, getInputProps, isDragReject, isDragActive } = useDropzone({
    accept: {
      'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': ['.xlsx'],
      'application/vnd.ms-excel': ['.xls']
    },
    maxFiles: 1,
    multiple: false,
    noKeyboard: click,
    onDropAccepted: acceptedFile => {
      setArchiveList(acceptedFile)
      setIsDropped(true)
      setClick(true)
    },
    onDropRejected: () => {
      toast.warning('Arquivo inválido', {
        position: toast.POSITION.TOP_RIGHT
      })
    }
  })

  const getProducts = async () => {
    const response = await api.get('product/list/all/?allow_processing=true') as ListAllProductProps
    setProducts(response.content)
    setLoading(false)
  }

  const getParameters = async (data: object) => {
    const response = await api.patch('product/list/parameters/', data) as { [key: string]: any }
    const parametersResponse = response.data as ListParameter
    setParameters(parametersResponse.content)
  }

  const handleChangeProduct = async (e: SelectChangeEvent<string>) => {
    const value = e.target.value
    setFieldValue('product', value)
    if (value === 'select-product') {
      setParameters([])
    } else {
      await getParameters({ "products_id": [value] })
    }
    setIsDropped(false)
    setFile(undefined)
  }

  async function createNewProcessing() {
    const formData = new FormData()
    formData.append("product", values.product)
    // @ts-ignore
    formData.append("file", file)
    await api.post(
      "processing/create/",
      formData,
      "multipart/form-data"
    ).then((response) => {
      setFieldValue('product', 'select-product')
      setParameters([])
      setIsDropped(false)
      setFile(undefined)
      toast.success(
        "Planilha adicionada para processamento!",
        { position: toast.POSITION.TOP_RIGHT }
      )
    })
  }

  useEffect(() => {
    getProducts()
  }, [])

  useLayoutEffect(() => {
    setTitleBar('Novo processamento')
    setPathTitleBar(undefined)
  }, [])

  return (
    <>
      <ToastContainer />
      {
        !loading
          ? <form onSubmit={handleSubmit}>
              <Grid
                container
                spacing={2}
                alignItems="center"
                justifyContent="center"
              >
                <Grid item md={12} xs={12}>
                  <p>Selecione um produto para começar</p>
                  <Select
                    fullWidth
                    required
                    id="product"
                    name="product"
                    value={values.product}
                    onChange={handleChangeProduct}
                  >
                    <MenuItem key='product-select-product-menu-item' value='select-product'>
                      Selecionar
                    </MenuItem>
                    {products?.map((item) => (
                      <MenuItem key={`product-${item.id}-menu-item`} value={item.id}>{item.name}</MenuItem>
                    ))}
                  </Select>
                </Grid>
                {
                  parameters?.length > 0
                    ? <>
                      <Grid item md={12} xs={12}>
                        <Alert variant="filled" severity="warning" sx={{ marginBottom: 2 }}>
                          <strong>
                            Sua planilha precisa conter no mínimo as colunas abaixo com os exatos nomes, respeitando inclusive caracteres maiusculos e minusculos
                          </strong>
                        </Alert>
                        <TableContainer component={Paper}>
                          <Table sx={{ minWidth: 650 }} aria-label="simple table">
                            <TableHead>
                              <TableRow>
                                {
                                  parameters.map((item) => <TableCell
                                    key={`example-column-${item.name}`}
                                    // sx={{ color: 'white' }}
                                  >
                                    <strong>{item.name}</strong>
                                  </TableCell>)
                                }
                              </TableRow>
                            </TableHead>
                            <TableBody>
                              <TableRow
                                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                              >
                                {parameters.map((item) => (
                                  <TableCell key={`example-value-${item.name}`}>
                                    A informação deve ser do tipo: {dysplayValueType[item.value_type]}
                                  </TableCell>
                                ))}
                              </TableRow>
                            </TableBody>
                          </Table>
                        </TableContainer>
                      </Grid>
                      <Grid item md={12} xs={12}>
                        <DropArea {...getRootProps({ isDragActive, isDragReject, isDropped })}>
                          <input {...getInputProps({})} />
                          {
                            isDragActive
                              ? isDragReject
                                ? <>
                                  <BiBlock /> &nbsp;
                                  <p>Apenas arquivos com extensão <strong>xlsx</strong> ou <strong>xls</strong> são permitidos.</p>
                                </>
                                : <>
                                  <SiMicrosoftexcel /> &nbsp;
                                  <p><strong>Arquivo permitido, pode soltar</strong></p>
                                </>
                              : isDropped
                                ? <>
                                  <SiMicrosoftexcel /> &nbsp;
                                  <p>
                                    {/* @ts-ignore */}
                                    Arquivo <strong>{file.name}</strong> adicionado
                                  </p>
                                </>
                                : <>
                                  <SiMicrosoftexcel /> &nbsp;
                                  <p>
                                    Adicione um arquivo <strong>xlsx</strong> ou <strong>xls</strong>
                                  </p>
                                </>
                          }
                        </DropArea>
                      </Grid>
                      <Grid
                        item
                        alignItems="center"
                        alignContent="center"
                      >
                        <Button
                          variant="contained"
                          size="large"
                          color="success"
                          disabled={values.product === 'select-product' || !file}
                          type="submit"
                        >
                          Criar &nbsp; <FaPaperPlane />
                        </Button>
                      </Grid>
                    </>
                    : <></>
                }
              </Grid>
            </form>
          : <LoadingPage />
      }
    </>
  )
}