import Box from '@mui/material/Box'
import { Outlet } from 'react-router-dom'
import Toolbar from '@mui/material/Toolbar'
import { useCore } from '../../hooks/useCore'
import { ToastContainer } from 'react-toastify'
import { cookies } from '../../hooks/useCookies'
import { DarkMode } from '../../styles/darkMode'
// import useWebSocket from 'react-use-websocket'
import NavBar from '../../components/Core/NavBar'
import { LightMode } from '../../styles/lightMode'
import CssBaseline from '@mui/material/CssBaseline'
import SideBar from '../../components/Core/SideBar'
import useWindowSize from '../../hooks/useWindowSize'
import { createContext, memo, useMemo, useState } from 'react'
import { createTheme, Grid, ThemeProvider, PaletteMode, Paper } from '@mui/material'

const drawerWidth = 300

interface Props {
  window?: () => Window
}

const getDesignMode = (mode: PaletteMode) => ({
  palette: mode === 'light' ? LightMode : DarkMode,
})

const ColorModeContext = createContext({ toggleColorMode: () => { } })

const refresh = () => {
  window.location.reload()
}

function ResponsiveDrawer(props: Props) {
  const { displayAlt } = useCore()
  const { width } = useWindowSize()

  const [mode, setMode] = useState<PaletteMode>(cookies.get('@CheckView:themeMode') ?? 'light')

  const theme = useMemo(() => createTheme(getDesignMode(mode)), [mode])
  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => (prevMode === 'light' ? 'dark' : 'light'))
        cookies.set('@CheckView:themeMode', mode === 'light' ? 'dark' : 'light')
        refresh()
      },
    }),
    [mode],
  )

  // const { lastJsonMessage } = useWebSocket(
  //   'wss://ntfy.sh/teste/ws', {
  //     onOpen: () => toast.success('Connected', {
  //       position: toast.POSITION.TOP_RIGHT
  //     }),
  //     onMessage: () => {
  //       if (lastJsonMessage) {
  //         const message = lastJsonMessage as {[key: string]: any}
  //         toast.info(message['message'], {
  //           position: toast.POSITION.TOP_RIGHT
  //         })
  //       }
  //     },
  //     onError: (event) => {
  //       toast.error(event.toString(), {
  //         position: toast.POSITION.TOP_RIGHT
  //       })
  //     },
  //     shouldReconnect: (closeEvent) => true,
  //     reconnectInterval: 5000
  //   }
  // )

  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Box sx={{ display: width >= 600 ? 'flex' : displayAlt }}>
          <CssBaseline />
          <NavBar />
          <SideBar />
          {/* Content */}
          <Box
            component="main"
            sx={{
              flexGrow: 1,
              p: 3,
              width: { sm: `calc(100% - ${drawerWidth}px)` },
            }}
          >
            <Toolbar />
            <Grid
              container
              spacing={0}
              direction="column"
              alignItems="center"
              justifyContent="center"
            >
              <Paper
                sx={
                  {
                    width: '100%', 
                    marginBottom: '20px', 
                    padding: '20px'
                  }
                }
                variant="outlined"
              >
                {<Outlet />}
              </Paper>
            </Grid>
          </Box>
        </Box>
      </ThemeProvider>
    </ColorModeContext.Provider>
  )
}

ResponsiveDrawer.defaultProps = {
  displayAlt: 'flex'
}

export default memo(ResponsiveDrawer)